import { inject, injectable } from '@/inversify';
import moment from 'moment';
import _ from 'lodash';

import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import MarketSettingsModel from '@/modules/markets/models/market-settings.model';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import MarketsStore from '@/modules/markets/store/markets.store';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import type StoreFacade from '@/modules/common/services/store-facade';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import type HelperService from '@/modules/common/services/helper.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type UserService from '@/modules/user/user.service';
import MarketsHistoryApiService, { MarketsHistoryApiServiceS } from './markets-history-api.service';

import MarketsHistoryStore from './store/markets-history.store';
import { ASSESSMENT_TYPES } from '../../constants';
import MarketsHistoryModel from './models/markets-history.model';
import { toTrendKey } from '../../filters/to-trend-key.filter';
import MarketsCommonService, { MarketsCommonServiceS } from '../markets/markets-common.service';

export const MarketsHistoryServiceS = Symbol.for('MarketsHistoryServiceS');
@injectable()
export default class MarketsHistoryService implements Stateable {
    @inject(MarketsHistoryApiServiceS) private MarketsHistoryApiService!: MarketsHistoryApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(MarketsApiServiceS) private marketsApiService!: MarketsApiService;
    @inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;

    readonly marketsStoreState: MarketsStore = this.storeFacade.getState('MarketsStore');
    readonly storeState: MarketsHistoryStore = this.storeFacade.getState('MarketsHistoryStore');

    private hotelId: number | null = null;
    private compsetId: string | null = null;
    private documentFilters: DocumentFiltersModel | null = null;
    private competitors: number[] | null = null;

    constructor() {
        this.storeFacade.watch(
            () => [
                this.storeState.documentDay,
            ],
            (n, o) => {
                const isParametersValid = n.some(Boolean);
                if (!isParametersValid || _.isEqual(n, o)) return;
                this.storeState.loading.reset();
            },
        );
    }

    async loadData(): Promise<boolean> {
        if (!this.documentDay || !this.marketsDocument) {
            return true;
        }

        const lastScanDate = this.marketsCommonService.getUpdateDate(this.documentDay, this.marketsDocument);

        if (!lastScanDate) {
            return true;
        }

        const trendKey = toTrendKey(lastScanDate);

        this.storeState.document = this.getInitialDocument(this.marketsDocument, this.documentDay, trendKey);
        this.storeState.lastScanDate = lastScanDate;

        const trendDoc = await this.MarketsHistoryApiService
            .getMarketsHistoryByDay(this.documentDay, this.marketsDocument.id);

        this.assignLastScanData(trendDoc, this.marketsDocument, trendKey);

        this.storeState.document = trendDoc;

        return true;
    }

    async loadMarketsDocument(): Promise<boolean> {
        if (this.documentFilters?.compsetId && this.marketSettings) {
            this.marketsDocument = await this.marketsApiService
                .getMarketsDocument(this.documentFilters, this.marketSettings);
        }
        return true;
    }

    private getInitialDocument(marketsDocument: MarketsDocumentModel, day: Day, lastScanDateKey: string) {
        const doc = Object.assign(new MarketsHistoryModel(), {
            requestDay: day,
            trendData: {
                [lastScanDateKey]: JSON.parse(JSON.stringify(marketsDocument.checkinDates[day] || {})),
            },
        });

        return doc;
    }

    private assignLastScanData(doc: MarketsHistoryModel | null, marketsDocument: MarketsDocumentModel, lastScanDateKey: string) {
        if (!doc || !this.documentDay || !doc.trendData || !doc.requestDay) return;

        const cd = this.marketsCommonService.getCheckinDate(doc.requestDay, marketsDocument);

        Object.assign(doc.trendData, {
            [lastScanDateKey]: JSON.parse(JSON.stringify(cd || {})),
        });
    }

    set marketsDocument(value: MarketsDocumentModel | null) {
        if (!value) {
            this.storeState.marketsDocument = null;
            return;
        }

        const docId = value instanceof MarketsCompsetMainModel ? (value as MarketsCompsetMainModel).documentId : value?.id;
        const doc = Object.assign(new MarketsDocumentModel(), JSON.parse(JSON.stringify(value)), { id: docId });

        this.storeState.marketsDocument = doc;
    }

    get documentDay() {
        return this.storeState.documentDay;
    }

    set documentDay(value: Day | null) {
        this.storeState.documentDay = value;
    }

    get marketsDocument() {
        return this.storeState.marketsDocument;
    }

    set marketSettings(value: MarketSettingsModel | null) {
        this.storeState.marketSettings = value;
    }

    get marketSettings() {
        return this.storeState.marketSettings;
    }

    get document() {
        return this.storeState.document;
    }

    get marketHistoryHotels() {
        let hotelIds: number[] = [];

        if (this.competitors && this.hotelId) {
            hotelIds = [...this.competitors, ...[this.hotelId]];
        }

        return hotelIds.length ? hotelIds : null;
    }

    get loading() {
        return this.storeState.loading;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get scanDate() {
        return this.marketsDocument ? `${moment(this.lastScanDate).format('DD/MM/YY')}` : '';
    }

    get currentScanDate() {
        const { document: doc } = this;

        if (!doc) return null;

        const trendDate = this.getDateByHistoryDay(this.dayIndex)!;
        const { [trendDate]: data } = doc.trendData;

        if (!data) return null;

        let date = data[this.userService.currentHotelId!]?.updateDate;

        if (!date) {
            date = Object.values(data).reduce((acc, item) => {
                if (!item.updateDate) {
                    return acc;
                }

                if (!acc) {
                    return item.updateDate;
                }

                return acc > item.updateDate ? acc : item.updateDate;
            }, undefined as Date | undefined);
        }

        return moment(date).format('DD.MM');
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get dayIndex() {
        return this.storeState.dayIndex;
    }

    setMarketsData(data: MarketsDocumentModel | MarketsCompsetMainModel | null, settings: MarketSettingsModel) {
        this.marketsDocument = data as MarketsDocumentModel;
        this.marketSettings = settings;
        this.storeState.loading.reset();
    }

    getMarketsHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null,
    ): { [hotelId: number]: MarketsDocumentItemModel }[] | null {
        this.hotelId = hotelId || this.userService.currentHotelId;
        this.compsetId = compsetId || null;
        this.competitors = this.compsetsService.getCompset(compsetId)?.competitors || [];
        this.documentFilters = documentFilters;
        this.documentDay = actualDay;

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this, !!compsetId));

        if (!this.marketsDocument || !this.marketsDocument.checkinDates) {
            return null;
        }

        return this.documentFiltersService.days
            .map(day => this.getSuitableMarketByDay(day - 1) || {} as {[hotelId: number]: MarketsDocumentItemModel});
    }

    getDateByHistoryDay(day: number) {
        if (!this.lastScanDate) return null;

        const dateInstance = new Date(this.lastScanDate);
        dateInstance.setDate(dateInstance.getDate() - day);

        return toTrendKey(dateInstance);
    }

    getSuitableMarketByDay(day: number) {
        const filteredHotels = {} as {[hotelId: number]: MarketsDocumentItemModel};

        if (!this.marketHistoryHotels || !this.document || !this.document.trendData) {
            return filteredHotels;
        }

        if (!this.lastScanDate) return filteredHotels;

        const trendDate = this.getDateByHistoryDay(day)!;
        const allHotels = this.document.trendData[trendDate];

        if (!allHotels) {
            return filteredHotels;
        }

        Object
            .entries(allHotels)
            .forEach(([hotelId, marketItem]) => {
                if (this.marketHistoryHotels && this.marketHistoryHotels.includes(+hotelId)) {
                    filteredHotels[Number(hotelId)] = marketItem;
                }
            });

        return filteredHotels;
    }

    getHotelColor(hotelId: number) {
        return this.hotelsService.getHotelsGraphColor(this.competitors?.map(String) || [])[String(hotelId)] || '#000000';
    }

    getMarketsHistoryHotelData(hotelId: number) {
        const history = this.documentDay
            ? this.getMarketsHistory(this.documentDay, this.hotelId, this.compsetId, this.documentFilters)
            : null;

        return history
            ? history
                .map((day: {[hotelId: number]: MarketsDocumentItemModel} | null) => {
                    if (!day || !Object.keys(day).length) {
                        return null;
                    }

                    const marketItem = day[Number(hotelId)];
                    if (!marketItem) return null;

                    return day[Number(hotelId)];
                })
            : [];
    }

    getMarketHistoryHotelPositions(hotelId: number) {
        const daysCount = this.documentFiltersService.days.length;
        const positions = [] as (number | null)[];

        for (let dayIndex = 0; dayIndex < daysCount; dayIndex++) {
            const marketData = this.getSuitableMarketByDay(dayIndex - 1)[hotelId];

            if (marketData) {
                positions.push(marketData.position);
            } else {
                positions.push(null);
            }
        }

        return positions.reverse();
    }

    getCardAssessment(hotelId: number) {
        const { marketHistoryHotels, dayIndex } = this;
        const hotelMarket = this.getSuitableMarketByDay(dayIndex);

        if (!hotelMarket || !marketHistoryHotels) {
            return [];
        }

        const mainPosition = hotelMarket[hotelId]?.position;

        if (!mainPosition) {
            return null;
        }

        const positions = marketHistoryHotels
            .map(hotel => (hotel !== hotelId ? hotelMarket[hotel]?.position : null))
            .filter(Boolean) as number[];

        const maxPosition = Math.max(...positions);
        const minPosition = Math.min(...positions);
        const middlePosition = minPosition + (maxPosition - minPosition) / 2;

        if (mainPosition <= minPosition) {
            return ASSESSMENT_TYPES.GOOD;
        }

        if (mainPosition <= middlePosition) {
            return ASSESSMENT_TYPES.NORMAL;
        }
        if (mainPosition <= maxPosition) {
            return ASSESSMENT_TYPES.FAIR;
        }

        return ASSESSMENT_TYPES.BAD;
    }

    async setTableDay(label?: string) {
        if (!this.marketsDocument || !this.marketsDocument.finishScanDate) {
            await this.marketsStoreState.loading.whenLoadingFinished();
        }

        if (!label) {
            this.storeState.dayIndex = 0;
            return;
        }

        const matches = label.match(/(\d+)/);
        const dayIndex = matches ? matches[0] : null;

        if (dayIndex !== null) {
            this.storeState.dayIndex = Number(dayIndex);
        }
    }

    get sortedDaysList() {
        if (!this.marketsDocument || !this.lastScanDate) {
            return [];
        }
        const scanDay = this.lastScanDate.getDate();
        const { month, year, days } = this.documentFiltersService;
        const maxDaysPrevMonth = new Date(year, month, 0).getDate();
        const currentMonthSegment = Array(scanDay).fill(null).map((_, i) => i + 1);
        const prevMonthSegment = days.length === scanDay
            ? []
            : Array(Math.abs(maxDaysPrevMonth - scanDay)).fill(null).map((_, i) => i + scanDay + 1);
        return [...prevMonthSegment, ...currentMonthSegment].reverse();
    }
}
