import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';

import AllProvidersModel from './models/all-providers.model';
import type { AllProvidersBEResponse } from './types';
import { i18n } from '../translations/translations.service';

@injectable()
export default class ProvidersApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getAllProviders() {
        const { data } = await this.apiService.get('supported/providers') as { data: AllProvidersBEResponse[] | null };

        const feProviders = {
            all: {
                label: i18n.tc('All Channels'),
                id: '-1',
                groupDocName: 'all',
                diName: 'all',
                maxScore: null,
                isScanDisabled: true,
            },
            cheapest: {
                label: i18n.tc('Cheapest Channel'),
                id: '-2',
                groupDocName: 'cheapest',
                diName: 'cheapest',
                maxScore: null,
                isScanDisabled: true,
            },
        } as { [name: string]: AllProvidersModel };

        if (!data) {
            return feProviders;
        }

        const beProviders = data.reduce((acc, item) => ({
            ...acc,
            [item.provider_name]: plainToClass(AllProvidersModel, <AllProvidersBEResponse> item, { excludeExtraneousValues: true }),
        }), {} as { [name: string]: AllProvidersModel });

        return {
            ...feProviders,
            ...beProviders,
        };
    }

    async getUserProviders(chainId: string, levelName: string | null) {
        const cluster = levelName ? `/${levelName}` : '';
        const { data } = await this.apiService.get(`supported/chain/provider/${chainId}${cluster}?filterType=clusters`);

        if (!data) {
            return null;
        }

        return {
            rates: data.rate_providers as string[],
            markets: data.market_providers as string[],
        };
    }
}
