
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import { ReportsServiceS } from '@/modules/reports/reports.service';
import type ReportsService from '@/modules/reports/reports.service';

import type NotificationItemModel from '../models/notification-item.model';
import type NotificationService from '../notification.service';

@Component
export default class Notification extends Vue {
    @inject(KEY.NotificationService) private notificationService!: NotificationService;
    @inject(ReportsServiceS) private reportsService!: ReportsService;

    @Prop({ type: Object })
    item!: NotificationItemModel;

    @Prop({ type: Boolean, default: false })
    archived!: boolean;

    isDownloading = false;

    get title() {
        if (!this.item.params.title) {
            return '';
        }

        return this.$te(this.item.params.title) ? this.$tc(this.item.params.title) : this.item.params.title;
    }

    get message() {
        if (!this.item.params.message) {
            return '';
        }

        return this.$te(this.item.params.message) ? this.$tc(this.item.params.message) : this.item.params.message;
    }

    handleClose() {
        this.notificationService.close(this.item.params.id);
    }

    async handleDownload() {
        if (!this.item.report) {
            return;
        }

        const { fileName, reportId } = this.item.report;
        this.isDownloading = true;
        const blobData = await this.reportsService.downloadReportBlobById(reportId);

        if (blobData === null) {
            this.isDownloading = false;
            return;
        }

        // It is needed to open download file window in browser
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.setAttribute('download', fileName);
        link.setAttribute('report-link', 'true');

        const el = this.$refs[`notification-${this.item.params.id}`] as HTMLElement | undefined;

        if (!el) {
            return;
        }

        el.appendChild(link);
        link.click();
        el.removeChild(link);

        if (!this.archived) {
            this.notificationService.close(this.item.params.id);
        }

        this.isDownloading = false;
    }
}
