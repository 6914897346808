import _ from 'lodash';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ASSESSMENT_TYPES from '../common/constants/assessments-types.constant';
import PAGES from '../common/constants/pages.constant';
import PromotionsDocumentModel, { ProgramData } from '../promotions/models/promotions-document.model';
import PromotionsApiService, { PromotionsApiServiceS } from '../promotions/promotions-api.service';
import type HelperService from '../common/services/helper.service';
import type StoreFacade from '../common/services/store-facade';
import type Day from '../common/types/day.type';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type PromotionsService from '../promotions/promotions.service';
import type ClusterService from './cluster.service';
import type ClusterPromotionsModel from './models/cluster-promotions.model';
import type ClusterStore from './store/cluster.store';
import type ProvidersService from '../providers/providers.service';

export const ClusterPromotionsServiceS = Symbol.for('ClusterPromotionsServiceS');

interface ClusterPromotionsPublicInterface {
    /** Current provider */
    provider: string | null;

    /** Hotel list with promotions data */
    readonly hotels: ClusterPromotionsModel[] | null;

    /** All possible promotions for current provider */
    readonly programs: string[];

    /** Settings of the service */
    readonly settings: ClusterStore['promotionsSettings'];

    /** Returns program data for specified `hotelId` and `day` */
    getProgramData(hotelId: number, day: Day): ProgramData | null;

    /** Returns assessment state for specified `day` and `mainHotelId` */
    getAssessment(day: Day, mainHotelId: number): ASSESSMENT_TYPES;
}

@injectable()
export default class ClusterPromotionsService implements ClusterPromotionsPublicInterface {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.PromotionsService) private promotionsService!: PromotionsService;
    @inject(PromotionsApiServiceS) private promotionsApiService!: PromotionsApiService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.clusterService.storeState.promotionsSettings.provider,
            this.clusterService.storeState.promotionsSettings.programView,
            this.clusterService.storeState.promotionsPrograms.list,
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
            this.documentFiltersService.storeState.settings.los,
        ], (o, n) => {
            if (_.isEqual(n, o)) {
                return;
            }

            this.clusterService.resetLoading();
        });

        this.storeFacade.watch(
            () => this.providersService.userProviders.promotionProviders,
            (n, o) => {
                if (_.isEqual(n, o)) {
                    return;
                }

                [this.storeState.promotionsSettings.provider] = this.providersService.userProviders.promotionProviders;
            },
        );
    }

    get provider() {
        return this.settings.provider;
    }

    set provider(value: string | null) {
        this.settings.provider = value;
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.PROMOTION_DETECTION),
        );

        return this.storeState.clusterHotels as ClusterPromotionsModel[] | null;
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.PROMOTION_DETECTION),
        );

        return this.storeState.totalCount as number;
    }

    /**
     * All possible promotions
     */
    get programs() {
        this.helperService.dynamicLoading(
            this.storeState.promotionsPrograms.loading,
            this.loadPrograms.bind(this),
        );

        return this.storeState.promotionsPrograms.list[this.provider!] || [];
    }

    get settings() {
        return this.storeState.promotionsSettings;
    }

    private async loadPrograms() {
        const { promotionsPrograms } = this.storeState;

        if (Object.keys(promotionsPrograms.list).length) return true;

        promotionsPrograms.list = await this.promotionsApiService.getProgramList();

        return true;
    }

    getProgramData(hotelId: number, day: Day) {
        const { provider, programView } = this.settings;
        const hotelData = this.clusterService.getHotelData<ClusterPromotionsModel>(hotelId);

        if (!hotelData?.compsetMain || !provider) return null;

        const { providerData } = hotelData.compsetMain;

        if (!providerData[provider]) {
            return null;
        }

        return providerData[provider][hotelId]?.[day]?.[programView] || null;
    }

    getAssessment(day: Day, mainHotelId: number): ASSESSMENT_TYPES {
        const { provider, programView } = this.settings;

        const doc = this.clusterService.getMainCompsetData<PromotionsDocumentModel>(mainHotelId);
        if (!doc || !provider) return ASSESSMENT_TYPES.NO_DATA;

        return this.promotionsService
            .getAssessment(day, mainHotelId, programView, provider, doc);
    }
}
