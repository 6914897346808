import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import VueI18n, { TranslateResult } from 'vue-i18n';
import moment from 'moment';
// @ts-ignore
import ElementLocale from 'element-ui/lib/locale';
import TranslationsApiService, { TranslationsApiServiceS } from './translations-api.service';
import TranslationsStore from './translations.store';
import TranslationsModel from './translations.model';
import type StoreFacade from '../common/services/store-facade';

interface TranslationsPublicInterface {
    /** Returns all translations from the store */
    translations: TranslationsModel | null;

    /** Is initial request or update request is pending */
    isLoading: boolean

    /** List of all available locales, only available after data received from BE */
    availableLocales: (keyof TranslationsModel)[];

    /** Send request to get translations from BE and put them into local storage */
    loadData: () => Promise<boolean>;

    /** Change locale localy
     * @param locale string which represent one of locales in Translations model
     */
    setLocale: (locale: keyof TranslationsModel) => void;

    /** Send request to change locale
     * @param locale string which represent one of locales in Translations model
     */
    updateLocale: (locale: keyof TranslationsModel) => Promise<boolean>;

    /** Get translations for provided locale, only available after data received from BE
     * @param locale string which represent one of locales in Translations model
     */
    getTranslations: (locale: keyof TranslationsModel) => VueI18n.LocaleMessageObject;
}

export const i18n = {
    t: (str: string, params?: (string | number)[]) => str as TranslateResult,
    tc: (str: string, paramNum?: number, params?: (string | number)[]) => str,
    te: (str: string, locale?: string) => !!str,
    locale: Object.keys(TranslationsModel)[0],
};

const TRANSLATIONS_EXPIRATION_TIME = 1 * 60 * 60 * 1000;

export const TranslationsServiceS = Symbol.for('TranslationsServiceS');
@injectable()
export default class TranslationsService implements TranslationsPublicInterface {
    @inject(TranslationsApiServiceS) private translationsApiService!: TranslationsApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;

    readonly storeState: TranslationsStore = this.storeFacade.getState('TranslationsStore');

    get translations() {
        return this.storeState.translations;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get availableLocales() {
        if (!this.storeState.translations) {
            throw new Error('No translations were found. Load translations first.');
        }

        return Object.keys(this.storeState.translations) as (keyof TranslationsModel)[];
    }

    async loadData() {
        this.storeState.loading.start();
        const translationsTimestamp = window.localStorage.getItem('translationsTimestamp');
        const passedTimeSinceLastUpdate = Date.now() - parseInt(translationsTimestamp || '0', 10);

        if (passedTimeSinceLastUpdate > TRANSLATIONS_EXPIRATION_TIME) {
            const translations = await this.translationsApiService.getTranslations();
            window.localStorage.setItem('translations', JSON.stringify(translations));
            window.localStorage.setItem('translationsTimestamp', String(Date.now()));
        }

        this.storeState.translations = JSON.parse(window.localStorage.getItem('translations')!) as TranslationsModel;
        this.storeState.loading.finish();
        return true;
    }

    setLocale(locale: keyof TranslationsModel) {
        document.querySelector('html')!.setAttribute('lang', locale);
        i18n.locale = locale;
        ElementLocale.use(this.getTranslations(locale));
        moment.locale(locale === 'cn' ? 'zh-cn' : locale);
    }

    async updateLocale(locale: keyof TranslationsModel) {
        this.storeState.loading.start();
        const res = await this.translationsApiService.changeLocale(locale as string);
        this.storeState.loading.finish();
        return res;
    }

    getTranslations(locale: keyof TranslationsModel) {
        if (!this.storeState.translations) {
            throw new Error('No translations were found. Load translations first.');
        }

        return this.storeState.translations[locale] || null;
    }
}
