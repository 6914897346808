export enum ColumnKey {
    SCREENSHOT = 'screenshotColumn',
    PRICE_TYPE = 'typeColumn',
    COLOR = 'colorColumn',
    NAME = 'nameColumn',
    RANK = 'rankColumn',
    PRICE = 'priceColumn',
    PRICE_COMPARE_1 = 'price1Column',
    PRICE_COMPARE_2 = 'price2Column',
    ROOM_NAME = 'roomNameColumn',
    PROVIDERS = 'providersColumn',
    DIFF = 'diffColumn',
    BASIC = 'basicColumn',
    PAX = 'paxColumn',
    INTRADAY = 'intradayColumn',
}

export const NUMBER_OF_CHART_DAYS = 30;
