import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ApiService from '@/modules/common/services/api.service';

export const MarketApiServiceS = Symbol.for('MarketApiServiceS');
@injectable()
export default class MarketApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getMarketById(marketId: number): Promise<string | null> {
        const url = `/market/market-name-by-id/${marketId}`;
        const { data } = await this.apiService.get(url);

        if (!data) {
            return null;
        }

        return data;
    }
}
