import { inject } from '@/inversify.inject';
import { injectable } from '@/inversify.injectable';
import { KEY } from '@/inversify.keys';
import { DEFAULT_GRAPH_COLORS } from '../common/constants/default-graph-colors.constant';
import type StoreFacade from '../common/services/store-facade';
import DefaultSettingsModel from '../user/models/default-settings.model';
import SpecialDateModel from '../user/models/special-date.model';
import { DefaultFilters } from '../user/types';
import { AlertsAndNotificationsGroups } from './types/alerts-and-notifications.interface';
import { IntradaySettingsModel } from './models/intraday-settings.model';
import { SubscriptionsSettingsModel } from './models/subscriptions-settings.model';
import SettingsApiService from './settings-api.service';
import SettingsStore from './settings.store';
import type UserService from '../user/user.service';

@injectable()
export class SettingsGeneralService {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.SettingsApiService) private settingsApiService!: SettingsApiService;
    @inject(KEY.UserService) private userService!: UserService;

    readonly storeState: SettingsStore = this.storeFacade.getState('SettingsStore');

    get displayCurrency() {
        return this.userService.user.settings.currencies.displayCurrency;
    }

    get chartColors() {
        return this.userService.user.settings.colors;
    }

    get isCurrenciesLoading() {
        return this.userService.currenciesLoading.isLoading()
            || this.storeState.scanProperties.loading.isInitialized
            && this.storeState.scanProperties.loading.isLoading();
    }

    get intradaySettings() {
        return this.userService.user.settings.intraday;
    }

    get intradaySpecialDates() {
        return this.userService.user.settings.intradaySpecialDates;
    }

    get alertsAndNotificationsByGroup() {
        const { user } = this.userService;
        return user!.settings.alertsAndNotificationsByGroup;
    }

    get alertsAndNotifications() {
        return this.userService.user.settings.alertsAndNotifications;
    }

    get subscriptions() {
        const { user } = this.userService;
        return user!.settings.subscriptions;
    }

    get defaultFilters() {
        return this.userService.user.settings.defaultFilters;
    }

    async updateDisplayCurrency(currency: string | null) {
        this.storeState.scanProperties.loading.start();

        await this.settingsApiService.updateDisplayCurrency(currency)
            .then(res => {
                if (!(res && res.settings)) return;
                this.userService.user.settings.currencies.displayCurrency = res.settings.currency || null;
            })
            .finally(() => this.storeState.scanProperties.loading.finish());
    }

    async saveIntradaySettings(newSettings: IntradaySettingsModel) {
        const newSettings_ = newSettings;
        const { myCompetitorsPercentRateChange } = newSettings;

        newSettings_.myCompetitorsPercentRateChange = +myCompetitorsPercentRateChange;

        await this.settingsApiService.updateSettings({ intraday: newSettings });
        this.userService.user.settings.intraday = newSettings;
    }

    async addSpecialDate(specialDate: SpecialDateModel) {
        const { user } = this.userService;
        if (!user) return;

        const updatedList = await this.settingsApiService.addSpecialDate(specialDate);

        user.settings.intradaySpecialDates = updatedList;
    }

    async deleteSpecialDate(specialDateId: string) {
        const { user } = this.userService;

        await this.settingsApiService.deleteSpecialDate(specialDateId);

        user.settings.intradaySpecialDates = user.settings.intradaySpecialDates
            .filter(date => date.id !== specialDateId);
    }

    async updateSpecialDate(specialDate: SpecialDateModel) {
        const { user } = this.userService;

        const updatedList = await this.settingsApiService.updateSpecialDate(specialDate);

        user.settings.intradaySpecialDates = updatedList;
    }

    async saveAlertsAndNotifications(newSettings: AlertsAndNotificationsGroups) {
        if (!this.userService.user.currentHotelId) {
            return;
        }

        const payload = JSON.parse(JSON.stringify(newSettings));

        Object.keys(payload).forEach(group => {
            delete payload[group].title;

            Object.keys(payload[group].alertsAndNotifications).forEach(param => {
                const params = payload[group]!.alertsAndNotifications![param];
                delete params.title;
                delete params.type;
                params.conditionsValue = params.conditionsValue || [];
            });
        });

        await this.settingsApiService.updateSettings({
            alertsAndNotificationsByGroup: payload,
        });

        const { alertsAndNotificationsByGroup } = this.userService.user.settings;
        Object.assign(alertsAndNotificationsByGroup, payload);
    }

    async saveSubscriptionsSettings(newSettings: SubscriptionsSettingsModel) {
        await this.settingsApiService.updateSubscriptions(newSettings);
        this.userService.user.settings.subscriptions = newSettings;
    }

    async updateChartColors(colors: string[]) {
        await this.settingsApiService.updateSettings({ colors });
        this.userService.user.settings.colors = colors;
    }

    async resetChartColors() {
        return this.updateChartColors(DEFAULT_GRAPH_COLORS);
    }

    async updateDefaultFilters(filters: DefaultFilters) {
        const res = await this.settingsApiService.updateDefaultFilters(filters);

        (Object.keys(filters) as (keyof DefaultSettingsModel)[]).forEach(key => {
            if (!filters[key]) { return; }

            // NOTE: ts wrong define type: DefaultSettingsModel[keyof DefaultSettingsModel]
            //       should be string | number, ts show never
            this.userService.user.settings.defaultFilters[key] = filters[key] as never;
        });

        return res;
    }
}
