import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import HotelModel from '../../models/hotel.model';

export const HotelCatalogApiServiceS = Symbol.for('HotelCatalogApiServiceS');
@injectable()
export default class HotelCatalogApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getHotelCatalog(query: string): Promise<HotelModel[]> {
        const defaultLimit = 10;

        const { data } = await this.apiService.post('hc/v3/hotels/search/hotel_names_for_autocomplete', {
            starting_string: query,
            max_hotel_count: defaultLimit,
        });

        const hotelCatalogDocument = plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });

        return hotelCatalogDocument;
    }

    async getHotelCatalogByUrl(query: string): Promise<HotelModel[]> {
        const defaultLimit = 1;

        const { data } = await this.apiService.post('hc/v2/injectdata', {
            rest_type: 'post',
            request_id: '5258531353158291623',
            security_token: '0987654321',
            request_name: 'injectdata',
            request_params: {
                count_of_similar_hotels: defaultLimit,
                items: [
                    {
                        providersData: [
                            {
                                hotelUrl: query,
                            },
                        ],
                    },
                ],
            },
        });

        if (!data || !data.data || !data.data.items) {
            return [] as HotelModel[];
        }

        return plainToClass(HotelModel, <HotelModel[]> data.data.items, { excludeExtraneousValues: true });
    }
}
