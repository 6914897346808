import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import HomeFiltersModel from '@/modules/home/models/home-filters.model';
import type CompsetsService from '@/modules/compsets/compsets.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HomeStore from '@/modules/home/store/home.store';
import type StoreFacade from '@/modules/common/services/store-facade';
import type ProvidersService from '../providers/providers.service';
import { FE_ONLY_PROVIDERS } from '../providers/constants';

export const HomeFiltersServiceS = Symbol.for('HomeFiltersServiceS');
@injectable()
export default class HomeFiltersService implements Stateable {
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    readonly storeState: HomeStore = this.storeFacade.getState('HomeStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.compsetsService.storeState.compsets,
                this.providersService.allProviders,
            ],
            this.reset.bind(this),
        );

        if (!this.providersService.allProviders) {
            return;
        }

        this.reset();
    }

    get ratesPos() {
        return this.storeState.settings.ratesPos;
    }

    set ratesPos(pos: string | null) {
        this.storeState.settings.ratesPos = pos;
    }

    get isCugOfMobileApp() {
        const { ratesProvider } = this.storeState.settings;
        if (ratesProvider) {
            const provider = ratesProvider.toLowerCase();
            return provider.includes('cug') || (provider.includes('mobile') && provider.includes('app'));
        }
        return false;
    }

    get ratesProvider() {
        return this.storeState.settings.ratesProvider;
    }
    set ratesProvider(provider: string | null) {
        this.storeState.settings.ratesProvider = provider;
        if (this.isCugOfMobileApp && this.compsetsService.compsets && provider) {
            const firstCompsetByProvider = this.compsetsService.compsets.find(x => x.rateProviders.includes(provider));
            this.storeState.settings.ratesPos = firstCompsetByProvider ? firstCompsetByProvider.mainPos || firstCompsetByProvider.pos[0] : null;
        }
    }

    get posItems() {
        return this.compsetsService.compsets
            ? [...new Set(
                ...this.compsetsService.compsets.map(compset => {
                    const providerPos = compset.providersPos && compset.providersPos.find(item => item.name === this.ratesProvider);
                    if (!providerPos) {
                        return [...compset.pos, compset.mainPos];
                    }

                    return providerPos.pos;
                }),
            )]
            : [];
    }

    reset() {
        this.resetCompset();
        this.setDefaultProvider();
        [this.ratesPos] = this.compsetsService.poses;
    }

    async resetCompset() {
        if (!this.compsetsService.compsets || !this.compsetsService.compsets.length) {
            return;
        }
        await this.updateCompset(this.compsetsService.compsets[0].id);
    }

    updateProvider(provider: string) {
        this.ratesProvider = provider;

        if (!this.posItems.includes(this.ratesPos as string)) {
            const [firstPos] = this.posItems;
            this.ratesPos = firstPos;
        }
    }

    async updateCompset(compsetId: string) {
        const compset = this.compsetsService.getCompset(compsetId);

        if (!compset) {
            return;
        }

        const settings = { ...this.storeState.settings };
        const { marketProviders } = compset;

        settings.visibilityCompsetId = compsetId;

        if (settings.visibilityProvider === null || !marketProviders.find(item => item === settings.visibilityProvider)) {
            settings.visibilityProvider = marketProviders && marketProviders.length ? marketProviders[0] : settings.visibilityProvider;
        }

        this.storeState.settings = { ...settings };
    }

    async saveFilters(settings: HomeFiltersModel): Promise<void> {
        this.storeState.settings = { ...settings };
    }

    setDefaultProvider() {
        if (!this.possibleProviders.length) {
            return;
        }

        const isBookingProvider = this.possibleProviders.includes('booking');

        if (isBookingProvider) {
            this.ratesProvider = 'booking';
            return;
        }

        [this.ratesProvider] = this.possibleProviders;
    }

    /** All possible providers that may be included in any compset */
    get possibleProviders(): string[] {
        const { allProviders } = this.providersService;
        const { compsets } = this.compsetsService.storeState;

        if (!allProviders || !compsets) {
            return [];
        }

        const possibleProviders = Array.from(new Set(
            compsets.map(compset => [...compset.rateProviders]).flat(),
        ));

        return possibleProviders.filter(name => !!allProviders[name] && !FE_ONLY_PROVIDERS.includes(name));
    }
}
