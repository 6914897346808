import { Expose } from 'class-transformer';

export class IntradaySettingsModel {
    @Expose()
    howManyCompetitorsRateChange!: number;

    @Expose()
    howManyCompetitorsRateChangeSpecialDates!: number;

    @Expose()
    myCompetitorsPercentRateChange!: number;

    @Expose()
    myCompetitorsPercentRateChangeSpecialDates!: number;

    @Expose()
    rateChange4SpecialDates!: number;

    @Expose()
    rateChangePercent2Alert!: number;

    @Expose()
    receiveAlert4CompetitorsRateChange!: boolean;

    @Expose()
    receiveAlert4HowManyCompetitorsRateChange!: boolean;

    @Expose()
    receiveAlert4MyRateChange!: boolean;

    @Expose()
    receiveEmail4MyRateChange!: boolean;
}
