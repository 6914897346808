import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import { RateTrendsStore, TrendsDocument, WithHotelIdTrendsStore } from '../types';

@Module
export default class ClusterRateTrendsStore extends VuexModule implements RateTrendsStore, WithHotelIdTrendsStore {
    trendsDocument: TrendsDocument | null = null;
    trendsLoading: LoadingModel = new LoadingModel();
    date: Date | null = null;
    trendIndex: number = 0;
    localPriceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
    initedHotelId: string | null = null;
}
