import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';
import type ApiService from '@/modules/common/services/api.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import DeepAnalysisItemModel from '@/modules/deep-analysis/models/deep-analysis-item.model';
import { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import COMPARE_TO from './constants/compare-to-filter.constant';
import DeepAnalysisStore from './store/deep-analysis.store';

export const DeepCompsetAnalysisApiServiceS = Symbol.for('DeepCompsetAnalysisApiServiceS');
@injectable()
export default class DeepCompsetAnalysisApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getDeepAnalisys(): Promise<DeepAnalysisItemModel[] | null> {
        const { data } = await this.apiService.get('deep_compset/deep_compset_analysis');

        if (!data) {
            return null;
        }
        const deepAnalysisItems = plainToClass(DeepAnalysisItemModel, <DeepAnalysisItemModel[]> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(deepAnalysisItems);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return deepAnalysisItems;
    }

    async getHotelExcel(settings: DeepAnalysisStore) {
        const url = '/insights/report/excel-deep_compset_analysis';
        const [scanType, timePeriod] = settings.showBy.split('.');
        const body = {
            provider_name: settings.provider,
            scanType: scanType === 'stay' ? 'next' : 'last',
            timePeriod: parseInt(timePeriod, 10),
            compateTo: settings.compareTo === COMPARE_TO.COMPSET_AVG ? 'comp_set' : 'last_year',
            numberOfdays: 30,
        };
        const config: AxiosRequestConfig = { responseType: 'blob' };

        const { data } = await this.apiService.post(url, body, config);

        return data as Blob;
    }
}
