import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import type AllProvidersModel from '../models/all-providers.model';

@Module
export default class ProvidersStore extends VuexModule {
    allProviders: { [name: string]: AllProvidersModel } = {};
    allProvidersLoading: LoadingModel = new LoadingModel();

    userRateProviders: string[] = [];
    userMarketProviders: string[] = [];
    userPromotionProviders: string[] = [];
    userProvidersLoading: LoadingModel = new LoadingModel();

    rateGroupedProviderNames: Record<string, string[]> = {};
    marketGroupedProviderNames: Record<string, string[]> = {};
    promotionGroupedProviderNames: Record<string, string[]> = {};
}
