/* eslint-disable camelcase */
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import ScheduledItemModel from './models/scheduled-item.model';
import { DATA_TYPE } from './constants';
import type { IForm, IRecipient } from './interfaces';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import ANY_MEAL_TYPE from '../meal-types/constants/any-meal-type.constant';
import { otelSchedule } from '../open-telemetry/decorators';
import SchedulerConfigPayloadModel from './models/scheduler-config.model';

export const ReportsApiServiceS = Symbol.for('ReportsApiServiceS');
@injectable()
export default class ReportsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;

    private mapDataType(dataType: DATA_TYPE) {
        switch (dataType) {
            case DATA_TYPE.RATES_CLUSTER:
            case DATA_TYPE.RATES: return 'rate';
            case DATA_TYPE.MARKETS_CLUSTER:
            case DATA_TYPE.MARKETS: return 'market';
            case DATA_TYPE.RANKING_CLUSTER:
            case DATA_TYPE.RANKING: return 'guestReviews';
            case DATA_TYPE.DI_LITE: return 'DILight';
            case DATA_TYPE.RATES_COMPARE: return 'ratesCompareMode';
            case DATA_TYPE.EVENTS: return 'events';
            default: throw new Error('Wrong data type!');
        }
    }

    // Is needed for BE
    private mapServiceType(dataType: DATA_TYPE) {
        switch (dataType) {
            case DATA_TYPE.DI_LITE:
            case DATA_TYPE.RATES_COMPARE:
            case DATA_TYPE.RATES_CLUSTER:
            case DATA_TYPE.RATES: return 'rate';
            case DATA_TYPE.MARKETS:
            case DATA_TYPE.MARKETS_CLUSTER:
            case DATA_TYPE.RANKING_CLUSTER:
            case DATA_TYPE.RANKING: return 'market';
            case DATA_TYPE.EVENTS: return 'events';
            default: throw new Error('Wrong data type!');
        }
    }

    mapSettings(form: IForm) {
        const camelToUnderscore = (key: string) => key.replace(/([A-Z])/g, '_$1').toLowerCase();

        type Values = (number | string) | (number | string)[] | Record<string, boolean>;

        const filters: Record<string, Values> = Object
            .fromEntries(
                Object.entries(form.filters)
                    .filter(([key, value]) => key !== 'compset' && value)
                    .map(([key, value]) => [camelToUnderscore(key), value] as [string, (string | number) | (string | number)[]]),
            );

        const columnsOptions = { ...form.customColumns };
        const configObject = plainToClass(SchedulerConfigPayloadModel, form.frequency, { excludeExtraneousValues: true }).clean();

        const body: { [key: string]: any } = {
            compset_id: form.filters.compset,
            type: this.mapDataType(form.properties.dataType as DATA_TYPE),
            service_type: this.mapServiceType(form.properties.dataType as DATA_TYPE),
            scheduler_config: configObject,
            subscribed_recipients_emails: Object.entries(form.recipients).map(r => (r[1] as IRecipient).value),
        };

        if (form.properties.dateRange) {
            body.number_of_days = form.properties.dateRange;
        }

        if (Object.keys(filters).length) {
            body.filters = JSON.parse(JSON.stringify(filters));

            if (body.type === 'ratesCompareMode') {
                body.filters.comparison_to = body.filters.comparison_to.value
                    .map((value: any) => ({
                        key: body.filters.comparison_to.key,
                        value,
                    }));

                body.filters.meal_type = body.filters.meal_type !== ANY_MEAL_TYPE.name
                    ? [body.filters.meal_type]
                    : this.mealTypesService.mealTypes
                        .map(m => m.name)
                        .filter(m => m !== ANY_MEAL_TYPE.name);
            }

            if (body.filters.requested_currency === '-') {
                delete body.filters.requested_currency;
            }

            if (Object.keys(columnsOptions).length) {
                // BE uses 2 fields for columnsOptions by some reason.
                // It is predifined and validated on BE.
                const extraColumnsKeys = [
                    'mealType',
                    'roomType',
                    'roomName',
                    'bookingBasic',
                    'multipleBestFlex',
                    'losResticted',
                ];

                const requestColumnsOptions = Object.fromEntries(Object.entries(columnsOptions).filter(c => !extraColumnsKeys.includes(c[0])));
                const requestExtraColumns = Object.fromEntries(Object.entries(columnsOptions).filter(c => extraColumnsKeys.includes(c[0])));

                body.filters.columnsOptions = requestColumnsOptions;

                if (Object.keys(requestExtraColumns).length) {
                    body.filters.extra_columns = requestExtraColumns;
                }
            }
        }

        return body;
    }

    getUrl(dataType: DATA_TYPE) {
        const isCluster = (() => dataType === DATA_TYPE.RATES_CLUSTER
            || dataType === DATA_TYPE.MARKETS_CLUSTER
            || dataType === DATA_TYPE.RANKING_CLUSTER
            || dataType === DATA_TYPE.CLUSTER_ALL
        )();

        return isCluster ? '/scheduler/cluster-excel-config' : '/scheduler/excel-config';
    }

    async getExcelReports(dataType: DATA_TYPE) {
        let url = this.getUrl(dataType);
        if (dataType !== DATA_TYPE.CLUSTER_ALL && dataType !== DATA_TYPE.HOTEL_ALL) {
            const typeQuery = this.mapDataType(dataType);
            url += `?type=${typeQuery}`;
        }
        const { data } = await this.apiService.get(url);

        const reports = plainToClass(ScheduledItemModel, <ScheduledItemModel[]>data, { excludeExtraneousValues: true });
        return reports
            .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime())
            .sort((a, b) => (a.type || '').localeCompare(b.type || ''));
    }

    @otelSchedule('report-update')
    async putExcelReport(id: string, form: IForm) {
        const body = this.mapSettings(form);
        delete body.compset_id;
        delete body.service_type;
        delete body.type;

        const res = await this.apiService.put(`${this.getUrl(form.properties.dataType as DATA_TYPE)}/${id}`, body);
        return res;
    }

    @otelSchedule('report-create')
    async postExcelReport(form: IForm) {
        const body = this.mapSettings(form);
        const res = await this.apiService.post(this.getUrl(form.properties.dataType as DATA_TYPE), body);
        return res;
    }

    @otelSchedule('report-remove')
    async deleteExcelReport(reportId: string) {
        const res = await this.apiService.delete(`/scheduler/excel-config/${reportId}`);
        return res;
    }

    async unsubscribeEmail(token: string) {
        const res = await this.apiService.get('/scheduler/unsubscribe-from-report', { token });
        return res;
    }

    async downloadReportBlobById(reportId: number) {
        const res = await this.apiService.get(
            `report/download/${reportId}`,
            {},
            { responseType: 'blob' },
        ).catch(() => null);

        if (!res || !res.data) {
            return null;
        }

        return res.data as Blob;
    }
}
