import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import _ from 'lodash';

import type HotelsApiService from '@/modules/hotels/hotels-api.service';
import type HelperService from '@/modules/common/services/helper.service';
import type Stateable from '@/modules/common/interfaces/stateable.interface';

import type HotelsStore from './store/hotels.store';
import type StoreFacade from '../common/services/store-facade';
import type { SettingsGeneralService } from '../settings/settings-general.service';

@injectable()
export default class HotelsService implements Stateable {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.HotelsApiService) private hotelsApiService!: HotelsApiService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    readonly storeState: HotelsStore = this.storeFacade.getState('HotelsStore');

    get hotelNames() {
        return this.storeState.hotelNames;
    }

    get hotelGeoLocations() {
        return this.storeState.hotelGeoLocations;
    }

    get userHotels() {
        this.helperService.dynamicLoading(this.userHotelsLoading, this.loadUserHotels.bind(this));
        return this.storeState.userHotelsList;
    }

    get userHotelsLoading() {
        return this.storeState.loading;
    }

    addHotelNames(hotelNames: Record<string, string>) {
        this.storeState.hotelNames = {
            ...this.storeState.hotelNames,
            ...hotelNames,
        };
    }

    addHotelGeoLocations(hotelGeoLocations: Record<string, { lat: number, lng: number }>) {
        this.storeState.hotelGeoLocations = {
            ...this.storeState.hotelGeoLocations,
            ...hotelGeoLocations,
        };
    }

    getHotelsGraphColor = _.memoize((entities: (string | number)[]): { [hotelId: string]: string } => {
        const { chartColors } = this.settingsGeneralService;
        return entities.reduce((acc, comp, index) => ({
            ...acc,
            [comp]: chartColors[index],
        }), {} as Record<string, string>);
    }, (...args) => args[0].join('') + this.settingsGeneralService.chartColors.join(''));

    private async loadUserHotels() {
        const myHotels = await this.hotelsApiService.getMyHotels();

        const { myList, names } = (myHotels || []).reduce((acc, hotel) => {
            acc.myList.push(String(hotel.id));
            acc.names[hotel.id] = hotel.name;
            return acc;
        }, { myList: [], names: {} } as { myList: string[], names: Record<string, string> });

        this.addHotelNames(names);
        this.storeState.userHotelsList = myList;

        return true;
    }
}
