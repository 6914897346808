import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import type HelperService from '@/modules/common/services/helper.service';
import MarketApiService, { MarketApiServiceS } from './market-api.service';
import MarketStore from './store/market.store';
import type StoreFacade from '../common/services/store-facade';

export const MarketServiceS = Symbol.for('MarketServiceS');
@injectable()
export default class MarketService implements Stateable {
    @inject(MarketApiServiceS) private marketApiService!: MarketApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;

    readonly storeState: MarketStore = this.storeFacade.getState('MarketStore');

    async loadData(marketId: number) {
        const name = await this.marketApiService.getMarketById(marketId);
        this.storeState.marketNames[marketId] = name;
        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    marketNameById(marketId: number) {
        const name = this.storeState.marketNames[marketId];

        if (name === undefined && !this.storeState.loading.isLoading()) {
            this.storeState.loading.reset();
        }

        this.helperService.dynamicLoading(this.storeState.loading, async () => {
            const loaded = await this.loadData(marketId);
            return loaded;
        });

        return name;
    }
}
