import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import UserModel from '@/modules/user/models/user.model';
import LoadingModel from '@/modules/common/models/loading.model';
import CurrencyListModel from '../models/currency-list.model';

@Module
export default class UserStore extends VuexModule {
    user: UserModel | null = null;
    loading: LoadingModel = new LoadingModel();

    userCurrencies = {
        loading: new LoadingModel(),
        list: null as CurrencyListModel | null,
    };
}
