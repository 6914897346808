import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import { container } from '@/inversify.container';
import { KEY } from '@/inversify.keys';
import { RouteConfig } from 'vue-router';
import type PaginationMiddleware from '@/router/middlewares/pagination.middleware';
import { CommonRoutes } from '../common.routes';

export default function DeepAnalysisClusterLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(KEY.PaginationMiddleware)]),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/cluster/pages/deep-analysis-cluster.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                ],
            },
        ],
    };
}
