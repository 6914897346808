import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ApiService from '@/modules/common/services/api.service';
import IChainDictionary, { IChainDictionaryFilters } from './interfaces/chain-dictionary.interface';

export const ChainApiServiceS = Symbol.for('ChainApiServiceS');

@injectable()
export default class ChainApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getFilterDictionary(params: Partial<IChainDictionaryFilters>) {
        const entries = Object
            .entries(params)
            .map((entry, i) => {
                if (!entry[0] || !entry[1]) return [['a', undefined]];
                return [
                    [`filters[${i}]`, entry[0]],
                    [`filtersValue[${i}]`, entry[1]],
                ];
            })
            .flat();

        const filters = Object.fromEntries(entries);
        const { data } = await this.apiService.get('users/chain-rate-page-filters', filters);

        return data as IChainDictionary;
    }
}
