import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import NotificationStore from '@/modules/notifications/store/notification.store';
import NotificationItemModel from '@/modules/notifications/models/notification-item.model';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type { NotificationItemParams, NotificationItemReport, NotificationPublicInterface } from './types';

import { NOTIFICATION_DURATION, NOTIFICATION_TYPE } from './constants';
import DownloadExcelModel from '../rates/models/download-excel.model';

@injectable()
export default class NotificationService implements NotificationPublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(SocketServiceS) private socketService!: SocketService;

    readonly storeState: NotificationStore = this.storeFacade.getState('NotificationStore');

    constructor() {
        this.socketService.onUpdateProgressExcel(this.updateReportProgress.bind(this));
    }

    get activeNotifications() {
        return this.storeState.activeItems;
    }

    get archivedNotifications() {
        return this.storeState.historyItems;
    }

    error(params: Omit<NotificationItemParams, 'id' | 'type'> & { error?: Error }) {
        const message = params.message || params.error?.message;
        const title = params.title || params.error?.name;

        this.addActiveNotification(
            new NotificationItemModel({
                title,
                message,
                type: NOTIFICATION_TYPE.ERROR,
                duration: params.duration || NOTIFICATION_DURATION,
            }),
        );
    }

    warning(params: Omit<NotificationItemParams, 'id' | 'type'> & { error?: Error }) {
        const message = params.message || params.error?.message;
        const title = params.title || params.error?.name;

        this.addActiveNotification(
            new NotificationItemModel({
                title,
                message,
                type: NOTIFICATION_TYPE.WARNING,
                duration: params.duration || NOTIFICATION_DURATION,
            }),
        );
    }

    info(params: Omit<NotificationItemParams, 'id' | 'type'>) {
        this.addActiveNotification(
            new NotificationItemModel({
                duration: NOTIFICATION_DURATION,
                ...params,
                type: NOTIFICATION_TYPE.INFO,
            }),
        );
    }

    success(params: Omit<NotificationItemParams, 'id' | 'type'>) {
        this.addActiveNotification(
            new NotificationItemModel({
                duration: NOTIFICATION_DURATION,
                ...params,
                type: NOTIFICATION_TYPE.SUCCESS,
            }),
        );
    }

    excel(params: Omit<NotificationItemParams, 'id' | 'type'>, report: NotificationItemReport) {
        this.addActiveNotification(
            new NotificationItemModel({
                ...params,
                type: NOTIFICATION_TYPE.EXCEL,
            }, report),
        );
    }

    close(id: string) {
        const index = this.activeNotifications.findIndex(item => item.params.id === id);

        if (index === -1) {
            return;
        }

        const notification = this.activeNotifications.splice(index, 1);

        this.storeState.historyItems = [
            ...this.archivedNotifications,
            ...notification,
        ];
    }

    private addActiveNotification(notification: NotificationItemModel) {
        this.storeState.activeItems = [
            ...this.activeNotifications,
            notification,
        ];
    }

    private updateReportProgress(data: DownloadExcelModel) {
        const indexOfActive = this.storeState.activeItems.findIndex(item => item.report?.reportId === Number(data.reportId));
        const indexOfArchived = this.storeState.historyItems.findIndex(item => item.report?.reportId === Number(data.reportId));

        if (indexOfActive === -1 && indexOfArchived === -1) {
            return;
        }

        const index = indexOfActive !== -1 ? indexOfActive : indexOfArchived;
        const collection = indexOfActive !== -1 ? 'activeItems' : 'historyItems';

        this.storeState[collection][index] = {
            ...this.storeState[collection][index],
            report: {
                ...this.storeState[collection][index].report!,
                progress: data.progress,
                isReady: !!data.fileName,
            },
        };

        this.storeState[collection] = [
            ...this.storeState[collection],
        ];
    }
}
