/* eslint-disable camelcase */
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import UserModel from '@/modules/user/models/user.model';

import type ApiService from '@/modules/common/services/api.service';
import type ConfigService from '../config/config.service';
import CurrencyListModel from './models/currency-list.model';

export const UserApiServiceS = Symbol.for('UserApiServiceS');
@injectable()
export default class UserApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(KEY.ConfigService) private configService!: ConfigService;

    async getUser(token: string): Promise<UserModel> {
        const fornovaAppPostfix = this.configService.fornovaApp ? `/${this.configService.fornovaApp}` : '';

        const { data } = await this.apiService.post(
            `users/sso${fornovaAppPostfix}`,
            { token },
            { headers: { 'Content-Type': 'application/json' } },
        );

        return plainToClass(UserModel, <UserModel> data, { excludeExtraneousValues: true });
    }

    async getAllUserCurrencies() {
        const { data } = await this.apiService.get('users/currencies');
        return plainToClass(CurrencyListModel, <CurrencyListModel> data, { excludeExtraneousValues: true });
    }
}
