import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';

export const MealTypesApiServiceS = Symbol.for('MealTypesApiServiceS');
@injectable()
export default class MealTypesApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getMealTypes(): Promise<MealTypeModel[] | null> {
        const { data } = await this.apiService.get('/supported/meal-types');

        if (!data) {
            return null;
        }

        const mealTypesDocument = plainToClass(MealTypeModel, <MealTypeModel[]> data, { excludeExtraneousValues: true });

        return mealTypesDocument;
    }
}
