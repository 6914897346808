import { snakeCase } from 'lodash';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';

import Percent from '@/modules/common/types/percent.type';
import type ApiService from '@/modules/common/services/api.service';
import CompsetModel from '@/modules/compsets/models/compset.model';

import { CompsetCreationBody } from './interfaces/compset-creation-body.interface';
import { NetCalculationSettings } from './interfaces';
import HotelModel from '../hotels/models/hotel.model';
import type ProvidersService from '../providers/providers.service';

@injectable()
export default class CompsetsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    async getCompsets(fornovaId: number): Promise<{ compsets: CompsetModel[] | null, hotels: HotelModel[] | null }> {
        const { data } = await this.apiService.get(`users/compsets/${fornovaId}`);

        if (!data) {
            return {
                compsets: null,
                hotels: null,
            };
        }

        const compsets = plainToClass(
            CompsetModel,
            <CompsetModel[]> data.compSet,
            { excludeExtraneousValues: true },
        );

        const filteredCompsets = compsets.map(compset => ({
            ...compset,
            rateProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.rateProviders),
            marketProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.marketProviders),
            promotionProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.promotionProviders),
        })) as CompsetModel[];

        const hotels = plainToClass(
            HotelModel,
            <HotelModel[]> data.hotels,
            { excludeExtraneousValues: true },
        );

        return { compsets: filteredCompsets, hotels };
    }

    async updateCompsets(updatedCompsets: CompsetModel[]): Promise<CompsetModel[] | null> {
        const body = updatedCompsets.map(compset => ({
            _id: compset.id,
            name: compset.name,
            type: compset.type,
            competitors: compset.competitors,
        }));

        const { data } = await this.apiService.put('users/compsets', body);

        if (!data) {
            return null;
        }

        const compsets = plainToClass(
            CompsetModel,
            <CompsetModel[]> data,
            { excludeExtraneousValues: true },
        );

        const filteredCompsets = compsets.map(compset => ({
            ...compset,
            rateProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.rateProviders),
            marketProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.marketProviders),
            promotionProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.promotionProviders),
        })) as CompsetModel[];

        return filteredCompsets;
    }

    async updateCompsetLos(updatedCompset: CompsetModel): Promise<CompsetModel[] | null> {
        const body = [{
            _id: updatedCompset.id,
            name: updatedCompset.name,
            type: updatedCompset.type,
            competitors: updatedCompset.competitors,
            los: updatedCompset.los,
        }];

        const { data } = await this.apiService.put('users/compsets', body);

        if (!data) {
            return null;
        }

        const compsets = plainToClass(
            CompsetModel,
            <CompsetModel[]> data,
            { excludeExtraneousValues: true },
        );

        const filteredCompsets = compsets.map(compset => ({
            ...compset,
            rateProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.rateProviders),
            marketProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.marketProviders),
            promotionProviders: this.providersService.filterOutProvidersWithSameGroupDocName(compset.promotionProviders),
        })) as CompsetModel[];

        return filteredCompsets;
    }

    async updateThresholds(compsetId: string, minThreshold: Percent, maxThreshold: Percent) {
        await this.apiService.put(`/compsets/${compsetId}/thresholds/`, {
            min_threshold: Math.floor(minThreshold * 100),
            max_threshold: Math.floor(maxThreshold * 100),
        });
    }

    async createCompset(compsetData: CompsetCreationBody) {
        const snakeCasedEntries = Object
            .entries(compsetData)
            .map(([key, value]) => [snakeCase(key), value]);

        const compsetBody = Object.fromEntries(snakeCasedEntries);
        await this.apiService.post('/compsets', compsetBody);
    }

    async updateNetCalculationSettings(hotelId: number, compsetId: string, payload: NetCalculationSettings) {
        const { data } = await this.apiService
            .put(`users/compsets-settings/${hotelId}/${compsetId}`, {
                settings: {
                    netRateVat: payload,
                },
            });

        return data;
    }
}
