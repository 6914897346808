import { container } from '@/inversify.container';
import use from '../../use';

import ClusterGuard, { ClusterGuardS } from '../../guards/cluster.guard';
import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '../../middlewares/homepage-redirect.middleware';

import { CommonRoutes } from '../common.routes';
import DeepAnalysisClusterLevelRoutes from './deep-analysis.routes';
import MarketsClusterLevelRoutes from './markets.routes';
import RankingClusterLevelRoutes from './ranking.routes';
import RatesClusterLevelRoutes from './rates.routes';
import EventsManagerRoutes from '../event-manager.routes';
import PromotionsClusterLevelRoutes from './promotions.routes';
import DiLiteClusterRoutes from './di-lite.routes';
import InsightsClusterRoutes from './insights.routes';
import RvsClusterLevelRoutes from './rvs.routes';
import SettingsPageRoutes from '../settings.routes';

export default function ClusterBranch() {
    return {
        path: 'cluster',
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([
            container.get<ClusterGuard>(ClusterGuardS),
        ]),
        children: [
            {
                name: 'cluster.home',
                path: '',
                component: () => import('@/modules/cluster/pages/home.page.vue'),
                beforeEnter: use([
                    container.get<HomepageRedirectMiddleware>(HomepageRedirectMiddlewareS),
                ]),
                children: [
                    ...CommonRoutes('cluster.home'),
                ],
            },

            InsightsClusterRoutes('insights', 'cluster.insights'),
            RatesClusterLevelRoutes('rates', 'cluster.rates'),
            MarketsClusterLevelRoutes('markets', 'cluster.markets'),
            RankingClusterLevelRoutes('ranking', 'cluster.ranking'),
            DiLiteClusterRoutes('di-lite', 'cluster.di-lite'),
            PromotionsClusterLevelRoutes('promotions', 'cluster.promotions'),
            DeepAnalysisClusterLevelRoutes('compset-benchmark', 'cluster.compset-benchmark'),
            EventsManagerRoutes('events-manager', 'cluster.events-manager'),
            RvsClusterLevelRoutes('rvs', 'cluster.rvs'),
            SettingsPageRoutes('cluster'),
        ],
    };
}
