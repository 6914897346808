import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type UserService from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const ClusterGuardS = Symbol.for('ClusterGuardS');
@injectable()
export default class ClusterGuard implements IGuard {
    @inject(KEY.UserService) private userService!: UserService;

    async canActivate() {
        return this.userService.isClusterUser || false;
    }
}
