import { plainToClass } from 'class-transformer';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { routerData } from '@/router/routerData';
import type HelperService from '@/modules/common/services/helper.service';

import { RatesDocToTrendsModel, PlainDocument } from './models/rates-doc-to-trends.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import RatesDocumentModel from '../rates/models/rates-document.model';
import type ProvidersService from '../providers/providers.service';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { HotelRooms } from '../common/interfaces';
import { calculateDiff } from '../common/utils/calculate-diff';
import RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import { PriceHistoryToTrendsModel } from './models/price-history-to-trends.model';
import { PRICE_SHOWN } from '../rates/constants';
import LoadingModel from '../common/models/loading.model';
import type {
    CompsetCompareData,
    RateTrendsService,
    TrendsWithRatesDocument,
    TrendsDocument,
    TrendsStatistics,
} from './types';
import type Day from '../common/types/day.type';
import type ClusterRatesService from '../cluster/cluster-rates.service';
import type ClusterService from '../cluster/cluster.service';
import type ClusterRateTrendsStore from './store/cluster-rate-trends.store';
import type StoreFacade from '../common/services/store-facade';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type HotelsService from '../hotels/hotels.service';
import type ClusterHotelsRatesModel from '../cluster/models/cluster-rates.model';
import PAGES from '../common/constants/pages.constant';

@injectable()
export default class ClusterRateTrendsService implements RateTrendsService, TrendsWithRatesDocument {
    /** New version of price history service. This one only for rates cluster level popup. Not for DI lite */

    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.ClusterRatesService) private clusterRatesService!: ClusterRatesService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    private readonly storeState: ClusterRateTrendsStore = this.storeFacade.getState('ClusterRateTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.storeState.initedHotelId,
                this.ratesDocument,
                this.date,
            ],
            async (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || !this.date || this.ratesDocument === null) {
                    return;
                }

                // Avoid extra requests if document not loaded yet.
                if (this.ratesLoading.isLoading()) {
                    return;
                }

                if (this.ratesDocument?.providerName === 'all') {
                    return;
                }

                this.storeState.trendsLoading.reset();
            },
        );
    }

    get compset() {
        if (!this.hotelId || !this.ratesDocument) {
            return null;
        }

        return this.clusterService.getCompset(this.ratesDocument.compsetId, this.hotelId);
    }

    get ratesDocument() {
        if (this.clusterService.hotelsMode !== PAGES.RATES) {
            return null;
        }

        // Have to use storeState here to avoid extra requests
        const hotels = this.clusterRatesService.storeState.clusterHotels as ClusterHotelsRatesModel[];

        if (!hotels || !hotels.length) {
            return null;
        }

        const { hotelId } = routerData.router.currentRoute.params;
        const currentHotel = hotels.find(h => String(h.hotelId) === hotelId);

        if (!currentHotel || !currentHotel.compsetMain) {
            return null;
        }

        const document = {
            ...currentHotel.compsetMain,
            id: currentHotel.compsetMain.documentId,
            compsetId: currentHotel.compsetMain.id,
            hotelNames: currentHotel.hotelNames,
            exchangeRate: 1,
            numberOfGuestsOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        } as RatesDocumentModel;

        return document;
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get filters() {
        const { los } = this.documentFiltersService.settings;
        const { competitors, mainPos } = (this.compset || {});

        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.clusterRatesService.settings;

        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.allProviders[provider].label : '';

        if (los === null
            || !mainPos
            || !competitors
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || numberOfGuests === null
            || !priceType
            || !this.compset
        ) {
            return null;
        }

        return {
            los,
            pos: mainPos,
            entities: competitors.map(String).concat([String(this.compset.ownerHotelId)]),
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compsetName: this.compset.name,
            compsetType: this.compset.type,
        };
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get trendsLoading() {
        return this.storeState.trendsLoading;
    }

    get ratesLoading() {
        if (!this.hotelId || this.clusterService.isLoading || !this.clusterService.hotelsLoading[this.hotelId]) {
            const loading = new LoadingModel();
            loading.start();
            return loading;
        }

        return this.clusterService.hotelsLoading[this.hotelId];
    }

    get statistics() {
        const trends = (this.trendsDocument?.trendData || []) as TrendsDocument['trendData'];
        const stats = trends.map(dayTrend => {
            if (!dayTrend || !this.compset || !trends.length) {
                return null;
            }

            const { competitors } = (this.compset || {});
            const compRooms = competitors.reduce((acc, hotelId) => {
                const hotelData = dayTrend.data[hotelId];

                if (!hotelData || !hotelData.rooms) {
                    return acc;
                }

                return {
                    ...acc,
                    [hotelId]: hotelData.rooms[0] || null,
                };
            }, {} as HotelRooms);

            const mainRooms = dayTrend.data[String(this.compset.ownerHotelId)]?.rooms;
            const mainRate = (mainRooms && mainRooms[0]?.price)
                ? mainRooms[0].price[`${this.localPriceShown}Price` as keyof RatesDocumentItemModel['price']]
                : null;

            const compRate = this.ratesCommonService.getCompsetPrice(compRooms, this.compset.type, this.localPriceShown);
            const diff = (compRate && mainRate) ? calculateDiff(mainRate, compRate, 0) : null;
            const assessment = diff !== null
                ? this.ratesCommonService.getCardAssessment(diff / 100, this.compset, this.clusterRatesService.colorThresholds)
                : null;

            const compset = {
                compRates: [compRate],
                mainRate,
                diff,
                type: this.compset.type,
                assessment,
            } as CompsetCompareData;

            return {
                compset,
                occupancy: dayTrend.occupancy,
                demand: dayTrend.demand,
                currency: this.trendsDocument!.currency,
            } as TrendsStatistics;
        });

        return stats;
    }

    private get hotelId() {
        return routerData.router.currentRoute.params.hotelId;
    }

    init(day: Day) {
        this.localPriceShown = this.documentFiltersService.priceShown;
        this.update(day);

        if (this.storeState.initedHotelId !== routerData.router.currentRoute.params.hotelId) {
            this.storeState.initedHotelId = routerData.router.currentRoute.params.hotelId;
        }
    }

    update(day: Day) {
        const { year, month } = this.documentFiltersService;
        this.date = new Date(year, month, day);
    }

    /** Loads trends data and use ratesDocument as its 1st item */
    private async loadData() {
        if (!this.ratesDocument?.id || !this.date) {
            return false;
        }

        this.storeState.trendsDocument = this.generateRatesDocumentIntoTrends();

        if (!this.trendsDocument) {
            return false;
        }

        const lastUpdate = this.ratesDocument.checkinDates ? this.ratesDocument.checkinDates[this.date.getDate()].updateDate : null;

        // At this moment rates loading is completed, but if no data for the rates document, trends can't be requested.
        if (!lastUpdate) {
            return true;
        }

        const day = this.date.getDate();
        const docId = this.ratesDocument.id;
        const { currency } = this.ratesDocument;

        const priceHistoryData = await this.ratesPriceHistoryApiService
            .getRatesPriceHistoryByDay(day as Day, docId, this.clusterRatesService.settings, currency || null);

        const historyTrendsDocument = plainToClass(
            PriceHistoryToTrendsModel,
            { ...priceHistoryData, lastUpdate },
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        // Set price history data to trends document, except 0 element.
        // 0 element is a group doc.
        this.storeState.trendsDocument = {
            ...this.trendsDocument,
            trendData: [
                this.trendsDocument.trendData[0],
                ...historyTrendsDocument.trendData.slice(1, historyTrendsDocument.trendData.length),
            ],
        };

        return true;
    }

    /** Generates trends document with ratesDocument as `0` element. */
    private generateRatesDocumentIntoTrends() {
        if (!this.ratesDocument || !this.date || !this.compset) {
            return null;
        }

        const { competitors, ownerHotelId } = this.compset;
        const names = [...competitors, ownerHotelId].reduce((acc, id) => ({
            ...acc,
            [id]: this.hotelsService.hotelNames[id],
        }), {} as Record<string, string>);

        // this.storeState.trendsDocument = plainToClass(
        const trendsDocument = plainToClass(
            RatesDocToTrendsModel,
            { ...this.ratesDocument, date: this.date, names } as PlainDocument,
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        return trendsDocument;
    }
}
