import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import type StoreFacade from '@/modules/common/services/store-facade';
import type HelperService from '@/modules/common/services/helper.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type UserService from '@/modules/user/user.service';
import RankingDocumentItemModel from '@/modules/ranking/models/ranking-document-item.model';
import RankingHistoryApiService, { RankingHistoryApiServiceS } from './ranking-history-api.service';
import RankingHistoryStore from './store/ranking-history.store';
import IHistoryItem from './interfaces/history-item.interface';

const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

interface RankingHistoryPublicInterface {
    /** Is ranking history document loading */
    readonly isLoading: boolean;

    /** Last date, closest to today which has some data */
    readonly updateDate: string | null;

    /** Day to be shown in the ranking history table */
    tableDay: Day | null;

    /**
     * Implements dynamic loading to ranking history document,
     * triggers loading, if it wasn't triggered yet, transform BE data and return result.
     * @param provider name of source
     * @param compsetId optional, if not provided takes currentCompsetId (hotel level), have to be provided on cluster
     * @returns transformed to IHistoryItem[][] | null ranking history document
     */
    rankingHistory: (provider: string, compsetId?: string) => IHistoryItem[][] | null;

    /**
     * Reset ranking history document loading
     */
    resetLoading: () => void;
}

@injectable()
export default class RankingHistoryService implements Stateable, RankingHistoryPublicInterface {
    @inject(RankingHistoryApiServiceS) private rankingHistoryApiService!: RankingHistoryApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    readonly storeState: RankingHistoryStore = this.storeFacade.getState('RankingHistoryStore');

    private compsetId: string | null = null;
    private provider: string | null = null;
    private readonly chartLabelNumber = 5;

    constructor() {
        // TODO watch for compset id and reset on change
        this.storeState.loading.reset();
    }

    private async loadData(): Promise<boolean> {
        this.resetData();

        if (!this.compsetId) {
            return true;
        }

        this.storeState.document = await this.rankingHistoryApiService.getRankingHistory(
            this.compsetId,
        );

        this.mapHistoryToTrend();

        return true;
    }

    private get trendDates() {
        return this.storeState.trendDates;
    }

    private set trendDates(value: IHistoryItem[][] | null) {
        this.storeState.trendDates = value;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get tableDay() {
        return this.storeState.tableDay;
    }

    set tableDay(day: Day | null) {
        this.storeState.tableDay = day;
    }

    private get lastTooltipPos() {
        return this.storeState.lastTooltipPos;
    }

    private set lastTooltipPos(tooltipPos: string | null) {
        this.storeState.lastTooltipPos = tooltipPos;
    }

    get updateDate() {
        return this.storeState.updateDate;
    }

    rankingHistory(
        provider: string,
        compsetId?: string,
    ): IHistoryItem[][] | null {
        this.provider = provider;
        this.compsetId = compsetId || (this.compsetsService.currentCompset && this.compsetsService.currentCompset.id);

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        return this.trendDates;
    }

    // Only for ranking history document
    private getNameById(hotelId: number) {
        const { document } = this.storeState;
        let name = '';

        if (!document || !document.trendData) {
            return name;
        }

        const dates = Object.keys(document.trendData[hotelId]!);
        dates.find(date => {
            const providers = Object.keys(document.trendData[hotelId]![date]);
            return !!providers.find(provider => {
                const { hotelName } = document.trendData[hotelId]![date][provider];
                if (!hotelName) {
                    return false;
                }

                name = hotelName;
                return true;
            });
        });

        return name;
    }

    private mapHistoryToTrend() {
        if (!this.storeState.document || !this.provider) {
            return;
        }

        const originalData = this.storeState.document.trendData;

        if (!originalData) {
            return;
        }

        const hotelIds = Object.keys(originalData);

        if (!this.userService.currentHotelId) {
            return;
        }

        const currentHotel = originalData[this.userService.currentHotelId];

        if (!currentHotel) {
            return;
        }

        // eslint-disable-next-line prefer-destructuring
        this.storeState.updateDate = Object.keys(currentHotel)[0];
        const updateMonth = Number(this.storeState.updateDate.split('-')[1]);
        const updateMonthIndex = updateMonth - 1;

        const months: number[] = [updateMonthIndex];
        for (let i = 0; i < this.chartLabelNumber - 1; i += 1) {
            const month = updateMonthIndex - (i + 1);
            months.push(month >= 0 ? month : MONTH_NAMES.length + month);
        }

        this.trendDates = months.map((monthIndex, index) => (
            hotelIds.map(hotelId => {
                let hotel: { [provider: string]: RankingDocumentItemModel } | null = null;

                if (index === 0) {
                    const dates = Object.keys(originalData[Number(hotelId)]!);
                    const firstDateKey = dates[0];
                    hotel = originalData[Number(hotelId)]![firstDateKey];
                } else {
                    hotel = originalData[Number(hotelId)]![MONTH_NAMES[monthIndex]];
                }

                if (!hotel || !this.provider || !hotel[this.provider]) {
                    return {
                        id: Number(hotelId),
                        name: this.getNameById(Number(hotelId)),
                        primary: null,
                        secondary: null,
                    } as IHistoryItem;
                }

                return {
                    id: Number(hotelId),
                    name: hotel[this.provider].hotelName,
                    primary: hotel[this.provider].rating || null,
                    secondary: hotel[this.provider].reviews || null,
                } as IHistoryItem;
            })
        )).reverse();
    }

    private resetData() {
        this.storeState.document = null;
        this.storeState.trendDates = null;
    }

    resetLoading() {
        this.storeState.loading.reset();
    }
}
