import { inject } from '@/inversify.inject';
import { injectable } from '@/inversify.injectable';
import { KEY } from '@/inversify.keys';

import type StoreFacade from '../common/services/store-facade';
import type HelperService from '../common/services/helper.service';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type UserService from '../user/user.service';

import RateValueScoreApiService from './rate-value-score-api.service';
import RVSStore from './store/rvs-hotel.store';
import { RVSHotelData } from './models/rvs-document.model';

@injectable()
export default class RVSDetailsService {
    @inject(KEY.RateValueScoreApiService) private rateValueScoreApiService!: RateValueScoreApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;

    private readonly rvsStore: RVSStore = this.storeFacade.getState('RVSStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.year,
            this.documentFiltersService.month,
            this.userService.currentHotelId,
            this.rvsStore.settings.provider,
        ], () => {
            this.storeState.loading.reset();
        });
    }

    get storeState() {
        return this.rvsStore.details;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        return this.storeState.data;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    set data(value: RVSHotelData | null) {
        this.storeState.data = value;
    }

    private get settings() {
        return this.rvsStore.settings;
    }

    private async loadData() {
        const { currentHotelId } = this.userService;
        const { year, month } = this.documentFiltersService;
        const { provider } = this.settings;

        if (!currentHotelId) {
            return false;
        }

        this.data = await this.rateValueScoreApiService.getDetailsData(currentHotelId, year, month, provider);
        return true;
    }
}
