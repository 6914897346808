import { injectable } from '@/inversify';
import { inject } from '@/inversify.inject';
import { LOGTYPE } from '../open-telemetry/constants';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';

const MAX_ATTEMPTS = 4;

@injectable()
export default class JiraService {
    private widget: HTMLElement | null = null;

    @inject(OpenTelemetryServiceS)
    private otelService!: OpenTelemetryService;

    async initWidget() {
        await this.loadScript();
    }

    toggleChat() {
        if (!this.widget) {
            return;
        }

        (this.widget.shadowRoot!.querySelector('.chat-icon-button')! as HTMLButtonElement).click();
    }

    get knowledgeBaseLink() {
        // [TODO] move config to env when dev/prod will be splited
        return 'https://fornova-support.atlassian.net/servicedesk/customer/portals ';
    }

    get contactUsFormLink() {
        // [TODO] move config to env when dev/prod will be splited
        return 'https://fornova-support.atlassian.net/servicedesk/customer/portal/2';
    }

    private attachScript(attempt = 0) {
        // [TODO] move config to env when dev/prod will be splited
        const scriptElement = document.createElement('script');
        scriptElement.id = 'jsm-snippet';
        scriptElement.async = true;
        scriptElement.src = 'https://cdn.chat.appfire.app/app/widget-prod/get-chat.min.js';
        const spanName = { prefix: LOGTYPE.LOADING, name: 'jira-widget' };

        if (!attempt) {
            this.otelService.startSpan(spanName);
        }

        const onScriptError = () => {
            if (attempt < MAX_ATTEMPTS) {
                scriptElement.parentNode!.removeChild(scriptElement);
                this.attachScript(attempt + 1);
                return;
            }

            const err = new Error('Failed to load chat widget script');
            this.otelService.endSpan(spanName, { err, sendLogs: true });
        };

        scriptElement.addEventListener('error', onScriptError, { once: true });

        scriptElement.addEventListener('load', () => {
            this.otelService.endSpan(spanName, { sendLogs: true });
            scriptElement.removeEventListener('error', onScriptError);
        }, { once: true });

        document.head.appendChild(scriptElement);
    }

    private loadScript() {
        this.attachScript();

        const widgetElement = document.createElement('chat-widget');
        widgetElement.id = 'jsm-widget';
        widgetElement.setAttribute('jira-id', 'bcd4f201-4696-31db-867a-9e56f545489a');
        widgetElement.setAttribute('service-desk-id', '2');
        widgetElement.setAttribute('auto-show', 'true');

        this.widget = widgetElement;

        this.hideWidget();

        document.body.appendChild(widgetElement);

        return new Promise<void>(resolve => {
            widgetElement.addEventListener('load', () => {
                // We can't directly controll widget, need timeouts to wait til it mounted and close animation completes
                setTimeout(() => {
                    this.initWidgetStyles();
                    // this.toggleChat();
                    setTimeout(this.showWidget.bind(this), 500);
                });

                resolve();
            });
        });
    }

    private initWidgetStyles() {
        if (!this.widget) {
            return;
        }

        const wrapper = this.widget.shadowRoot!.querySelector('.chat-widget-wrapper') as HTMLElement;

        wrapper.style.bottom = '-70px';
        wrapper.style.right = '70px';
    }

    private hideWidget() {
        if (!this.widget) {
            return;
        }

        this.widget.style.opacity = '0';
        this.widget.style.pointerEvents = 'none';
        this.widget.style.zIndex = '-1';
    }

    private showWidget() {
        if (!this.widget) {
            return;
        }

        if (!this.widget) {
            return;
        }

        this.widget.style.opacity = '1';
        this.widget.style.pointerEvents = 'all';
        this.widget.style.zIndex = '999999';
    }
}
