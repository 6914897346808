import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type UserService from '@/modules/user/user.service';
import {
    DOORS_ANY, MILEAGE_ANY, TRANSMISSION_ANY, PAYMENT_TERMS_ANY, CAR_CLASS_ANY, COUNTRIES_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import type HelperService from '@/modules/common/services/helper.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import UserStore from '@/modules/user/store/user.store';
import restrictCarsFilters from '@/modules/cars/utils/restrict-cars-filters.util';
import CarsFiltersStore from '../../store/cars-filters.store';
import CarsFilterApiService, { CarsFilterApiServiceS } from '../../cars-filter-api.service';

export const ParityFiltersServiceS = Symbol.for('ParityFiltersServiceS');
@injectable()
export default class ParityFiltersService {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @inject(ParityServiceS) private parityService!: ParityService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(CarsFilterApiServiceS) private carsFilterApiService!: CarsFilterApiService;
    @inject(CarsServiceS) private carsService!: CarsService;

    readonly storeState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');
    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    private tempHideLocations = [
        'Lyft Los Angeles 3',
        'Lyft Los Angeles 4',
        'Lyft Los Angeles 5',
        'Lyft Los Angeles 6',
        'Lyft Los Angeles 11',
        'Lyft Los Angeles 8',
        'Lyft Los Angeles 10',
        'Lyft Los Angeles 9',
        'Lyft Los Angeles 7',
        'Lyft Los Angeles 13',
        'Lyft Los Angeles 12',
        'Lyft Los Angeles 2',
        'Lyft San Francisco 1',
        'Lyft San Francisco 2',
        'Lyft Los Angeles 1',
        'Lyft New York 1',
        'Lyft New York 2',
        'Lyft New York 4',
        'Lyft Atlanta 1',
        'Lyft Chicago 3',
        'Lyft Chicago 2',
        'Lyft Chicago 1',
        'Lyft Atlanta 2',
    ];

    constructor() {
        this.loadFilters();
        // this.storeFacade.watch(() => this.settings, this.initCurrentFilterValues.bind(this));
        // this.storeFacade.watch(() => this.paritySettingsService.storeState.settings, async () => {
        //     this.initDoorsFilter();
        //     this.initTransmissionFilter();
        //     this.initMileageFilter();
        //     this.initCarClasses();
        //     this.initPaymentTermsFilter();
        // });
    }

    async loadFilters() {
        const chain = this.carsService.currentChain;

        let filters = await this.carsFilterApiService.getCarFilters(chain);

        if (!filters) {
            return false;
        }

        if (filters.locations) {
            filters.locations = filters.locations.filter(
                item => this.tempHideLocations.findIndex(hideItem => hideItem.toLowerCase() === item.locationName.toLowerCase()) === -1,
            );
        }

        const userInfo = this.userStoreState.user || { countries: [], locations: [] };
        filters = restrictCarsFilters(filters, userInfo);

        this.storeState.settings = filters;

        if (!this.paritySettingsService.country) {
            this.paritySettingsService.country = COUNTRIES_ANY;

            if (this.storeState.settings.locations && !this.paritySettingsService.pickUpCities) {
                this.paritySettingsService.pickUpCities = this.storeState.settings.locations.map(loc => loc.locationId);
            }
        }

        if (this.storeState.settings.lor) {
            let { lor } = this.storeState.settings;
            if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars') {
                const lorsToInclude = [3, 7, 10, 14];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Hertz') {
                const lorsToInclude = [1, 2, 3, 4, 5, 7, 28];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Lyft') {
                const lorsToInclude = [1, 2, 5, 7, 14, 28];
                lor = lor.filter(item => lorsToInclude.includes(item));
            } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Avis') {
                const lorsToInclude = [1, 7];
                lor = lor.filter(item => lorsToInclude.includes(item));
            }
            this.paritySettingsService.lor = lor;
        }

        // eslint-disable-next-line prefer-destructuring
        if (this.storeState.settings.pos) {
            let { pos } = this.storeState.settings;
            if (this.userService && this.userService.user && this.userService.user.chainName !== 'Rentalcars') {
                let posToInclude = ['GB', 'FR', 'DE', 'IT', 'ES', 'NL', 'US', 'BE'];
                if (this.userService.user.chainName === 'Lyft') {
                    posToInclude = ['US'];
                }
                if (this.userService.user.chainName === 'Hertz') {
                    posToInclude.push(...['PT', 'IE', 'AE']);
                }
                if (this.userService.user.chainName === 'Avis') {
                    posToInclude = ['US', 'MX'];
                }

                pos = pos.filter(item => posToInclude.includes(item));
            }

            this.paritySettingsService.pos = pos;
        }

        if (this.storeState.settings.mileage) this.paritySettingsService.mileage = this.storeState.settings.mileage;

        if (this.storeState.settings.transmission) this.paritySettingsService.transmission = this.storeState.settings.transmission;

        if (this.storeState.settings.paymentTerms) this.paritySettingsService.paymentTerms = this.storeState.settings.paymentTerms;

        if (this.storeState.settings.carClass) {
            this.paritySettingsService.carClasses = this.storeState.settings.carClass
                .filter(car => car !== 'Commercial');
        }

        this.paritySettingsService.bml = ['B', 'M', 'L', 'A'];

        return true;
    }

    get settings() {
        // this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings;
    }

    // async initCurrentFilterValues() {
    //     await this.initCountries();
    //     await this.initPickUpCity();
    //     // await this.initLor();
    //     // await this.initPos();
    // }

    get countriesFilter(): string[] {
        const { countries } = this.storeState.settings;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    getCityByCode(code: string) {
        const { locations } = this.storeState.settings;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationId === code);
        return defaultLocation ? defaultLocation.locationName : null;
    }

    // async initCountries() {
    //     this.paritySettingsService.country = COUNTRIES_ANY;
    // }

    // async initPickUpCity() {
    //     const { locations } = this.settings;

    //     if (locations && locations.length) {
    //         const [defaultLocation] = locations;
    //         this.paritySettingsService.pickUpCities = [defaultLocation.locationId];
    //     }
    // }

    filterPickUpCitiesByCountry(country: string) {
        const {
            locations, countries, routes, availability,
        } = this.storeState.settings;

        if (!locations || !routes || !availability) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availability.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }
}
