import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import type HelperService from '@/modules/common/services/helper.service';
import type UserService from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type StoreFacade from '../common/services/store-facade';
import CarsRateAlertApiService, { CarsRateAlertApiServiceS } from './car.rate-alert-api.service';
import CarsRateAlertStore from './store/cars.rate-alert.store';
import CarsRateAlertConfigurationModel from './models/cars-rate-alert-configuration.model';
import { ICarRateAlertConfig } from './modules/settings/interfaces/alert-interfaces';

export const CarsRateAlertServiceS = Symbol.for('CarsRateAlertServiceS');

export enum notificationMessages {
    ADD_SUCCESS = 'The new configuration has been added successfully!',
    UPDATE_SUCCESS = 'The configuration has been updated successfully!',
    FAILED = 'Please fill all the required fields',
    REMOVED_SUCCESS = 'The configuration has been Deleted successfully!',
}
@injectable()
export default class CarsRateAlertService implements Stateable {
    @inject(KEY.UserService) protected userService!: UserService;
    @inject(CarsRateAlertApiServiceS) protected carsAlertApiService!: CarsRateAlertApiService;
    @inject(AuthServiceS) protected authService!: AuthService;
    @inject(KEY.StoreFacade) protected storeFacade!: StoreFacade;
    @inject(KEY.HelperService) protected helperService!: HelperService;

    readonly storeState: CarsRateAlertStore = this.storeFacade.getState('CarsRateAlertStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    resetLoadings() {
        this.storeState.loading.reset();
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }
        const grouped = await this.carsAlertApiService.getRateAlertsConfiguration();

        this.storeState.document = grouped as CarsRateAlertConfigurationModel[];

        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get loading() {
        return this.storeState.loading;
    }

    async saveRateAlertConfiguration(configuration: ICarRateAlertConfig) {
        return this.carsAlertApiService.saveRateAlertConfiguration(configuration);
    }

    async updateRateAlertConfiguration(configuration: ICarRateAlertConfig, id: string) {
        return this.carsAlertApiService.updateRateAlertConfiguration(configuration, id);
    }

    async updateRateAlertConfigurationStatus(configId: string, status: boolean) {
        return this.carsAlertApiService.updateRateAlertConfigurationStatus(configId, status);
    }

    async deleteRateAlertConfiguration(id: string) {
        return this.carsAlertApiService.deleteRateAlertConfiguration(id);
    }
}
