import { AxiosRequestConfig } from 'axios';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import TokenSessionStorageService, { TokenSessionStorageServiceS } from '@/modules/auth/token-session-storage.service';
import type UserService from '@/modules/user/user.service';
import type ConfigService from '@/modules/config/config.service';
import type ApiService from '@/modules/common/services/api.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import AuthStore from './store/auth.store';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';

export const AuthServiceS = Symbol.for('AuthServiceS');
@injectable()
export default class AuthService {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(TokenSessionStorageServiceS) private tokenSessionStorageService!: TokenSessionStorageService;
    @inject(KEY.ConfigService) private configService!: ConfigService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(SocketServiceS) private socketService!: SocketService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    readonly storeState: AuthStore = this.storeFacade.getState('AuthStore');

    constructor() {
        this.apiService.setRequestInterceptor(this.setTokenToHeaders.bind(this));
        this.socketService.onConnect(this.socketHandshake.bind(this));
    }

    setTokenToHeaders(req: AxiosRequestConfig) {
        if (!this.storeState.auth.token) {
            return req;
        }

        if (!req.headers) {
            // eslint-disable-next-line no-param-reassign
            req.headers = {};
        }

        // eslint-disable-next-line no-param-reassign
        req.headers.Authorization = `Bearer ${this.storeState.auth.token}`;
        return req;
    }

    socketHandshake() {
        if (!this.storeState.auth.token || !this.userService.isUserLoaded) {
            return;
        }

        if (!this.userService.currentHotelId) {
            // [TODO] remove when it will be fixed on BE.
            // Sends first fornovaId from the user to handshake on cluster page.
            this.socketService.sendHandshake(this.userService.user.hotelIds[0], this.storeState.auth.token);
            return;
        }

        this.socketService.sendHandshake(this.userService.currentHotelId, this.storeState.auth.token);
    }

    async authentication(token: string | null = null) {
        if (token !== null) {
            this.tokenSessionStorageService.setToken(token);
        }

        if (this.tokenSessionStorageService.token !== null) {
            this.storeState.auth.token = this.tokenSessionStorageService.token;
            await this.userService.initUser(this.storeState.auth.token);
            this.socketHandshake();
        }

        await this.verifyAuth();
    }

    async isLogin() {
        if (this.userService.isLoadingAndInitialized) {
            return true;
        }

        if (!this.userService.isUserLoaded) {
            await this.authentication();
            return this.userService.isUserLoaded;
        }
        return true;
    }

    logout(err?: Error) {
        if (this.userService.isUserLoaded) {
            this.otelService.instantSpan(
                { name: 'logout' },
                { sendLogs: true, payload: { 'cx.user.token': err && this.token }, err },
            );
        }

        this.tokenSessionStorageService.removeToken();
        localStorage.removeItem('currentChain');

        window.location.href = `${this.configService.ssoUrl}?logOut=true`;
    }

    async verifyAuth() {
        await this.apiService.get('/users/verification');
        return true;
    }

    get loginUrl() {
        const query = `?appName=Fornova${this.configService.fornovaApp || 'CI'}&redirectUrl=${window.location.origin}/auth`;
        return this.configService.ssoUrl + encodeURI(query);
    }

    get token() {
        return this.storeState.token;
    }
}
