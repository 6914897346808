import { Module, VuexModule } from 'vuex-module-decorators';
import HotelModel from '@/modules/hotels/models/hotel.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class HotelsStore extends VuexModule {
    loading = new LoadingModel();
    userHotelsList: string[] = [];
    hotelNames: Record<string, string> = {};
    hotelGeoLocations: Record<string, {
        lat: number,
        lng: number,
    }> = {};
}
