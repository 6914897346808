import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import AlertModel from '@/modules/alerts/models/alert.model';

export const AlertsApiServiceS = Symbol.for('AlertsApiServiceS');
@injectable()
export default class AlertsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getHotelAlerts(): Promise<AlertModel[] | null> {
        const res = await this.apiService.get('/insights/user-alert');

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(AlertModel, <AlertModel[]> res.data, { excludeExtraneousValues: true });
    }

    async getClusterAlerts(): Promise<AlertModel[] | null> {
        const res = await this.apiService.get('/insights/cluster-alerts');

        if (!res || !res.data) {
            return null;
        }

        return plainToClass(AlertModel, <AlertModel[]> res.data, { excludeExtraneousValues: true });
    }

    async markAlertAsRead(id: string) {
        const { data } = await this.apiService
            .get(`insights/mark-as-read/${id}`);

        return data;
    }

    async markAlertsAsRead(ids: string[]) {
        const { data } = await this.apiService.post('insights/mark-as-read', { ids: ids.map(String) });

        return data;
    }

    async markAlertAsUnread(id: string) {
        const { data } = await this.apiService.get(`insights/mark-as-unread/${id}`);

        return data;
    }

    async deleteAlert(id: string) {
        const { data } = await this.apiService
            .delete(`insights/delete-alert/${id}`);

        return data;
    }
}
