import 'reflect-metadata';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import VueProgressBar from 'vue-progressbar';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueObserveVisibility from 'vue-observe-visibility';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import { i18n } from '@/modules/translations/translations.service';
import './modules/common/assets/css/icon-element-ui.css';
import 'leaflet/dist/leaflet.css';

import { bindSyncModules, loadAsyncModules } from '@/inversify.config';

import App from './app.vue';
import './registerServiceWorker';
import { initRouter } from './router';
import TranslationsModel from './modules/translations/translations.model';
import { CoralogixPlugin } from './plugins/coralogix.plugin';
import TooltipDirective from './directives/tooltip';

class AppBootstrap {
    private router!: VueRouter;
    async init() {
        await loadAsyncModules();
        bindSyncModules();
        this.router = initRouter();
        this.configApp();
        this.loadVueApp();
    }

    private configApp(): void {
        /**
         * Here is no Vue.use(Vuex) cause Vuex is workable only with the Vue instance.
         * Vuex depends largely on Vue for its reactivity inner workings.
         */
        Vue.config.productionTip = false;
        Vue.use(VueRouter);
        Vue.use(VueI18n);
        Vue.use(VueProgressBar, {
            color: '#A1A2FF', // TODO get these colors from scss
            failedColor: 'red',
            height: '5px',
        });
        Vue.use(PerfectScrollbar);
        // User id is set in user.service
        Vue.use(VueGtag, {
            config: {
                id: 'G-VX7L6WZFM8',
            },
        });
        Vue.use(VueObserveVisibility);
        Vue.directive('tooltip', TooltipDirective);
        Vue.use(CoralogixPlugin);
    }

    private loadVueApp(): void {
        new Vue({
            router: this.router,
            i18n: new VueI18n({ locale: Object.keys(TranslationsModel)[0] }),
            render: h => h(App),
            mounted() {
                i18n.t = this.$t.bind(this);
                i18n.tc = this.$tc.bind(this);
                i18n.te = this.$te.bind(this);
                Object.defineProperty(i18n, 'locale', {
                    get: () => this.$i18n.locale as keyof TranslationsModel,
                    set: (loc: keyof TranslationsModel) => { this.$i18n.locale = loc; },
                });
            },
        }).$mount('#app');
    }
}

(async () => {
    const app = new AppBootstrap();
    await app.init();
})();
