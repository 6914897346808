import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import type Day from '@/modules/common/types/day.type';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type ConfigService from '@/modules/config/config.service';

interface TriggerScanOptions {
    compsetId: string | null;
    provider: string | null;
    los: number | null;
    pos: string | null;
    hotelId?: number;
}

export const MarketsCommonServiceS = Symbol.for('MarketsCommonServiceS');
@injectable()
export default class MarketsCommonService {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(MarketsApiServiceS) private marketsApiService!: MarketsApiService;
    @inject(KEY.ConfigService) private configService!: ConfigService;

    getCheckinDate(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const { checkinDates } = document;

        if (checkinDates && checkinDates[day] && Object.keys(checkinDates[day]).length) {
            return checkinDates[day];
        }

        return null;
    }

    getUpdateDate(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const date = document.updateDates[day];

        if (!date) return null;

        return new Date(date);
    }

    getHotelCheckingDate(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const checkinDate = this.getCheckinDate(day, document);

        if (!checkinDate || !checkinDate[hotelId]) {
            return null;
        }

        return checkinDate[hotelId];
    }

    getPosition(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);

        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.position || null;
    }

    getOldPosition(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);

        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.oldPosition;
    }

    getLink(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);

        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.link || null;
    }

    getPage(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel) {
        const hotelCheckingDate = this.getHotelCheckingDate(day, hotelId, document);
        if (!hotelCheckingDate) return null;

        return hotelCheckingDate.page || null;
    }

    getNumberOfHotels(day: Day, hotelId: number, document: MarketsDocumentModel) {
        const checkinDate = this.getCheckinDate(day, document);

        if (!checkinDate || !checkinDate[hotelId] || !checkinDate[hotelId].numberOfHotels) {
            return null;
        }

        return checkinDate[hotelId].numberOfHotels;
    }

    private calculateThresholdsByDay(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel, excludeHotelId?: number)
        : [number, number, number] | null {
        const positions: number[] = this.dayPositions(day, document, excludeHotelId) || [];

        return this.calculateThresholds(positions);
    }

    calculateThresholds(positions: number[] | null): [number, number, number] | null {
        if (positions === null) {
            return null;
        }

        const maxPosition = Math.max(...positions);
        const minPosition = Math.min(...positions);

        const midPosition = minPosition + (maxPosition - minPosition) / 2;
        return [minPosition, midPosition, maxPosition];
    }

    dayPositions(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel, excludeHotelId?: number) {
        const checkinDate = this.getCheckinDate(day, document);

        if (!checkinDate) {
            return null;
        }

        const positions: number[] = Object
            .entries(checkinDate)
            .reduce((acc: number[], [hotelId, hotel]) => {
                if (hotelId === 'updateDate') return acc;
                if (excludeHotelId && Number(hotelId) === excludeHotelId) {
                    return acc;
                }
                return acc.concat([(hotel as MarketsDocumentItemModel).position]);
            }, []);

        return positions;
    }

    getCardAssessment(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel): ASSESSMENT_TYPES | null {
        const comparedPosition = this.getPosition(day, hotelId, document);
        const thresholds = this.calculateThresholdsByDay(day, document, hotelId);

        if (!comparedPosition || !thresholds) {
            return null;
        }

        const [minPosition, midPosition, maxPosition] = thresholds;

        if (comparedPosition <= minPosition) {
            return ASSESSMENT_TYPES.GOOD;
        }
        if (comparedPosition <= midPosition) {
            return ASSESSMENT_TYPES.NORMAL;
        }
        if (comparedPosition <= maxPosition) {
            return ASSESSMENT_TYPES.FAIR;
        }
        return ASSESSMENT_TYPES.BAD;
    }

    getTableAssessment(day: Day, hotelId: number, comparedHotelId: number, document: MarketsDocumentModel): ASSESSMENT_TYPES | null {
        const comparedPosition = this.getPosition(day, comparedHotelId, document);
        const checkinDate = this.getCheckinDate(day, document);

        if (!comparedPosition
            || !checkinDate
            || !checkinDate[hotelId]
            || !checkinDate[hotelId].position
        ) {
            return null;
        }

        if (comparedPosition > checkinDate[hotelId].position) {
            return ASSESSMENT_TYPES.GOOD;
        }
        return ASSESSMENT_TYPES.BAD;
    }

    getDemand(day: Day, doc: MarketsDocumentModel | null): ASSESSMENT_TYPES | null {
        return doc?.dayStatistics?.[day]?.demand || null;
    }

    getOccupancy(day: Day, doc: MarketsDocumentModel | null): ASSESSMENT_TYPES | null {
        return doc?.dayStatistics?.[day]?.occupancy || null;
    }

    minMaxPositions(document: MarketsDocumentModel, excludeHotelId?: number): { min: number[], max: number[] } | null {
        const min: number[] = [];
        const max: number[] = [];

        this.documentFiltersService.days.forEach(day => {
            const dayPositions = this.dayPositions(day, document, excludeHotelId);
            if (dayPositions) {
                min.push(Math.min(...dayPositions));
                max.push(Math.max(...dayPositions));
            } else {
                min.push(Math.min(...min));
                max.push(Math.max(...max));
            }
        });
        return { min, max };
    }

    isOutOfRange(document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        if (document && document.scanStatus === SCAN_STATUS.IN_PROGRESS) {
            return false;
        }

        return !document || !document.checkinDates || !Object.keys(document.checkinDates).length;
    }

    isNoData(day: Day, document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        if (!document) {
            return false;
        }

        return !this.getCheckinDate(day, document);
    }

    isNA(day: Day, hotelId: number, document: MarketsDocumentModel | MarketsCompsetMainModel | undefined | null) {
        if (!document) {
            return false;
        }

        if (this.isNoData(day, document)) {
            return false;
        }

        const dataByDay = this.getCheckinDate(day, document) as {[p: number]: MarketsDocumentItemModel};

        const hotelData = dataByDay[hotelId];

        return !hotelData;
    }

    isSoldOut(day: Day, hotelId: number, document: MarketsDocumentModel | undefined | null) {
        if (!document) {
            return false;
        }

        return !this.getPosition(day, hotelId, document);
    }

    isScanAvailable(day?: Day): boolean {
        return day ? !this.documentFiltersService.isPreviousDay(day) : !this.documentFiltersService.isPreviousMonth;
    }

    dayUpdateDate(day: Day, document: MarketsDocumentModel | null) {
        if (!document || !document.updateDates) return null;

        return document.updateDates[day];
    }

    /**
     * Base method, don't use it directly
     * Trigger scan via markets or cluster markets service
     * @param settings trigger options
     * @param day if not passed, whole month will be triggered
     * @returns null or documentId if scan launched
     */
    async triggerScan(options: TriggerScanOptions, day?: Day) {
        const { compsetId, provider } = options;
        const { los, pos } = options;
        const { month, year } = this.documentFiltersService.settings;
        const isNotAbleToScan = !this.isScanAvailable(day)
            || compsetId === null
            || pos === null
            || los === null
            || provider === null;

        if (isNotAbleToScan) {
            return null;
        }

        const startDate = new Date(year, month, day || 1);
        const today = new Date();

        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        if (currentMonth === month && currentYear === year && !day) {
            startDate.setDate(new Date().getDate());
        }

        const endDate = day ? new Date(startDate.getFullYear(), startDate.getMonth(), day + 1) : undefined;

        const scanData = await this.marketsApiService.triggerScan(compsetId!, los!, pos!, provider!, startDate, endDate);
        if (scanData === null) {
            return null;
        }

        return scanData.documentId;
    }

    resendScheduledReport(userLevel: 'hotel' | 'cluster', schedulerId: number) {
        return this.marketsApiService
            .resendScheduledReport(userLevel, schedulerId);
    }

    getProgramLogo(program: string) {
        return `${this.configService.apiUrl}/promotion-detections/types/image?name=${program}`;
    }
}
