import Stateable from '@/modules/common/interfaces/stateable.interface';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import { ASSESSMENT_TYPES } from '../common/constants';
import PAGES from '../common/constants/pages.constant';
import PRICE from '../common/modules/rates/constants/price.enum';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import RatesDocumentModel from '../rates/models/rates-document.model';
import { DayData } from './interfaces';
import ClusterHotelsRatesModel from './models/cluster-rates.model';
import RatesCompsetMainModel from './models/rates-compset-main.model';
import ClusterStore from './store/cluster.store';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import type Day from '../common/types/day.type';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type ClusterService from './cluster.service';
import type HelperService from '../common/services/helper.service';
import type StoreFacade from '../common/services/store-facade';
import type { SettingsGeneralService } from '../settings/settings-general.service';
import type RatesHotelModel from '../rates/models/rates-hotel.model';

@injectable()
export default class ClusterDiLiteService implements Stateable {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(MealTypesServiceS) private mealTypeService!: MealTypesService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        // NOTE Applying default filters
        const { settings } = this.storeState;

        this.storeFacade.watch(
            () => [
                this.settingsGeneralService.defaultFilters.price,
                this.settingsGeneralService.defaultFilters.numberOfGuests,
                this.settingsGeneralService.defaultFilters.mealType,
            ],
            () => {
                const { defaultFilters } = this.settingsGeneralService;

                settings.priceType = defaultFilters.price;
                settings.numberOfGuests = defaultFilters.numberOfGuests;

                const defaultMealType = this.mealTypeService.getMealType(defaultFilters.mealType);
                settings.mealTypeId = defaultMealType ? defaultMealType.id : -1;
            },
            { immediate: true },
        );

        this.storeFacade.watch(
            () => this.mealTypeService.mealTypes,
            () => {
                const { mealTypes } = this.mealTypeService;
                const { defaultFilters } = this.settingsGeneralService;

                if (mealTypes.length <= 1 && settings.mealTypeId !== -1) { return; }

                const neededMealType = this.mealTypeService.getMealType(defaultFilters.mealType)!;
                settings.mealTypeId = neededMealType ? neededMealType.id : -1;
            },
        );

        // Watcher for filters
        this.storeFacade.watch(() => [
            this.storeState.settings.numberOfGuests,
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.priceType,

            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.los,

            // TODO Restored it once the chain level be added
            // this.chainService.settings.country,
            // this.chainService.settings.region,
            // this.chainService.settings.city,
            // this.chainService.settings.brand,
        ], (n, o) => {
            if (JSON.stringify(n) === JSON.stringify(o)) {
                return;
            }

            this.clusterService.resetLoading();
        });

        // Separate watcher for sorting

        // Separate watcher for chain
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.DILITE),
        );

        if (!this.isFiltersValid()) {
            return null;
        }

        return this.storeState.clusterHotels as ClusterHotelsRatesModel[] | null;
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.DILITE),
        );

        if (!this.isFiltersValid()) {
            return null;
        }

        return this.storeState.totalCount as number;
    }

    get documentHash() {
        return [
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.los,
        ].join('-');
    }

    get settings() {
        return {
            ...this.storeState.settings,
            provider: 'all',
        };
    }

    private isFiltersValid() {
        const { settings } = this.storeState;

        if (!settings.numberOfGuests) {
            settings.numberOfGuests = 2;
            return false;
        }

        if (!settings.priceType) {
            settings.priceType = PRICE_TYPE.LOWEST;
            return false;
        }

        return true;
    }

    isNoData(day: Day, hotelId: number) {
        const doc = this.clusterService.getHotelData(hotelId)
            ?.compsetMain as unknown as RatesDocumentModel;

        if (!doc || !doc.checkinDates) {
            return true;
        }

        const allRooms = this.ratesCommonService.getCheckinDay(day, doc) || { hotels: {} };
        const isNoRooms = !Object.keys(allRooms.hotels).length;

        if (isNoRooms) {
            return true;
        }

        return !doc.checkinDates[day];
    }

    /**
     * Diff between provider's price and average
     * @param value price to be compared with
     * @param average average price between all providers
     * @returns rounded 2 digits after decimal point
     */
    getDiff(value: number, average: number) {
        const diff = (value - average) / average * 100;
        return Math.round(diff * 100) / 100;
    }

    /**
     * Day assessment for all channels (is used on di lite cluster)
     * @param highest highest price of all providers
     * @param average average price between all providers
     */
    getAssessment(hotelId: number, highest: number, average: number): ASSESSMENT_TYPES {
        if (average === 0) {
            return ASSESSMENT_TYPES.SOLD_OUT;
        }

        const diff = this.getDiff(highest, average) / 100;
        const compset = this.clusterService.getMainCompset(hotelId);

        if (!compset) return ASSESSMENT_TYPES.NO_DATA;

        return this.ratesCommonService.getCardAssessment(diff, compset, this.colorThresholds)
            || ASSESSMENT_TYPES.NO_DATA;
    }

    /**
     * Is used to get day-chain component data
     *
     * @returns sorted by price in desc order rooms, average, highest, lowest prices
     */
    getDayData(day: Day, doc: RatesCompsetMainModel, priceShown?: PRICE_SHOWN) {
        const { checkinDates } = doc;

        if (!checkinDates || !checkinDates[day]) {
            return null;
        }

        const allProviders = checkinDates[day] as Record<string, RatesHotelModel>;
        const allProviderKeys = Object.keys(allProviders);

        if (!allProviderKeys.length) {
            return null;
        }

        let average = 0;
        let highest = 0;
        let lowest = 0;
        let numValidRooms = 0;

        const priceShownType = `${priceShown
            ? priceShown.toLowerCase()
            : this.documentFiltersService.priceShown.toLowerCase()}Price` as keyof
        {
            totalPrice: number;
            netPrice: number;
            shownPrice: number;
            lowestPrice: number;
        };

        const data = allProviderKeys.map(provider => {
            const { rooms, link, losRestriction } = allProviders[provider];
            // BE should send only one room
            const room = Object.values(rooms)[0]?.[0];
            const { priceType } = room;
            let isNetCalc = false;

            const price = room.price ? room.price[priceShownType] : null;

            if (price !== null && price !== PRICE.SOLD_OUT && price !== PRICE.NA) {
                if (priceShown === PRICE_SHOWN.NET && room.isNetCalc) {
                    isNetCalc = true;
                }

                numValidRooms += 1;
                average += price;

                if (price > highest) {
                    highest = price;
                }

                if (price < lowest || lowest === 0) {
                    lowest = price;
                }
            }

            return {
                isNetCalc,
                price,
                priceType,
                provider,
                link,
                losRestriction,
            };
        }).sort((a, b) => (b.price || 0) - (a.price || 0));

        if (numValidRooms === 0) {
            const firstProvider = allProviders[allProviderKeys[0]];
            const { rooms } = firstProvider;
            const firstProviderRoom = Object.values(rooms)[0]?.[0];
            average = firstProviderRoom.price ? firstProviderRoom.price[priceShownType] : PRICE.NA;
        } else {
            average /= numValidRooms;
            average = Math.round(average * 100) / 100;
        }

        const dayData = {
            data,
            average,
            highest,
            lowest,
            currency: doc.currency || '',
        };

        return dayData as DayData;
    }

    get colorThresholds() {
        return this.storeState.diLiteColorThresholds;
    }

    set colorThresholds(value: [number, number]) {
        this.storeState.diLiteColorThresholds = value;
    }
}
