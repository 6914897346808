import { Expose, plainToClass, Transform } from 'class-transformer';
import type {
    InsightGroupBE, Insight, RoomsInsight, Rooms, MarketLeaderInsight,
} from '../types';
import { InsightType } from '../constants';

enum SubTypes {
    new = 0,
    L = 0,
    missing = 1,
    H = 1,
    percent = 1
}

export default class InsightGroupModel<T extends MarketLeaderInsight | RoomsInsight | Insight> {
    @Expose()
    @Transform((_, plain: InsightGroupBE) => String(plain._id))
    groupId!: string;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => plain.mainFornovaId)
    mainFornovaId!: number;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => plain.type as unknown as InsightType)
    type!: InsightType;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => (plain.subType ? SubTypes[plain.subType as unknown as SubTypes] : 0))
    subType!: number;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => plain.date)
    date!: string;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => plain.read)
    read!: boolean;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => !!plain.deleted)
    deleted!: boolean;

    @Expose()
    @Transform((_, plain: InsightGroupBE) => plain.range)
    range!: number;

    @Expose()
    @Transform((val, plain: InsightGroupBE) => {
        const insights = plain.meta!.grouped.reduce((acc, ins) => {
            const { _id, ...rest } = ins as Record<string, any>;

            const value = {
                fornovaId: rest.fornovaId,
                provider: rest.provider,
            } as T;

            // Rooms insight specific fields
            if (rest.rooms) {
                (value as RoomsInsight).rooms = rest.rooms;
            }

            // Market leader insight specific fields
            if (rest.leader_fornova_id) {
                value.fornovaId = rest.leader_fornova_id;
                value.hotelName = rest.leader_hotel_name;
                (value as MarketLeaderInsight).leaderAllPercent = rest.leader_info.leader_all?.percent || null;
                (value as MarketLeaderInsight).leaderIncPercent = rest.leader_info.leader_increase?.percent || null;
                (value as MarketLeaderInsight).leaderDecPercent = rest.leader_info.leader_decrease?.percent || null;
            }

            // Other insight fields
            if (rest.los || rest.pos) {
                (value as Insight).los = rest.los;
                (value as Insight).pos = rest.pos;
                (value as Insight).compsetId = rest.compsetId;
            }

            return {
                ...acc,
                [ins._id]: value as T,
            };
        }, {} as Record<string, T>);

        return insights;
    })
    insights!: Record<string, T>;
}
