import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import type UserService from '@/modules/user/user.service';

const TARGET_PATH = '/onboarding';
export const OnboardingMiddlewareS = Symbol.for('OnboardingMiddlewareS');

@injectable()
export default class OnboardingMiddleware implements IMiddleware {
    @inject(KEY.UserService) private userService!: UserService;

    async canActivate() {
        await this.userService.storeState
            .loading.whenLoadingFinished();

        const { pathname } = window.location;
        const { isNewUser } = this.userService;
        const isOnboardingPage = pathname.includes(TARGET_PATH);

        if (isNewUser && !isOnboardingPage) {
            window.location.href = TARGET_PATH;
            return true;
        }

        if (!isNewUser && isOnboardingPage) {
            window.location.href = '/';
        }

        return true;
    }
}
