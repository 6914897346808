import { NotificationItemParams, NotificationItemReport } from '../types';

export default class NotificationItemModel {
    constructor(notification: Omit<NotificationItemParams, 'id'>, report?: NotificationItemReport) {
        const id = Date.now().toString(16) + Math.random().toString(16).substr(2);

        this.params = {
            id,
            ...notification,
        };

        if (report) {
            this.report = {
                ...report,
            };
        }
    }

    params: NotificationItemParams;
    report?: NotificationItemReport;
}
