import { ObjectDirective } from 'vue';

export enum TOOLTIP_DIRECTION {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
}

const DEFAULT_POSITION = TOOLTIP_DIRECTION.TOP;
const DEFAULT_DELAY = 300;

let tooltipTimer: NodeJS.Timeout | null = null;

type TooltipElement = HTMLElement & { tooltipHandler: (e: MouseEvent) => void };

type TooltipParams = {
    value: {
        header: string;
        text: string;
        position: TOOLTIP_DIRECTION;
        component?: Vue;
    } | string;
};

const getTooltipComponent = () => (document.getElementById('custom-tooltip-directive') as any)?.__vue__ as {
    left: number;
    top: number;
    header: string;
    text: string;
    visible: boolean;
    position: TOOLTIP_DIRECTION;
    component?: Vue;
    $el: HTMLElement;
    $nextTick: () => Promise<void>;
};

const showTooltip = (params: TooltipParams, e: MouseEvent) => {
    if (tooltipTimer) {
        clearTimeout(tooltipTimer);
    }

    const element = e.target as HTMLElement;
    const component = getTooltipComponent();

    tooltipTimer = setTimeout(async () => {
        if (params.value === '' || params.value === null || params.value === undefined) {
            return;
        }

        if (!component) {
            return;
        }

        const tooltip = component.$el;
        component.position = (typeof params.value === 'string' || !params.value.position)
            ? DEFAULT_POSITION
            : params.value.position;

        if (typeof params.value === 'string') {
            component.header = '';
            component.text = params.value;
            component.component = undefined;
        } else {
            component.header = params.value.header;
            component.text = params.value.text;
            component.component = params.value.component;
        }

        component.visible = true;

        // NOTE: After changing properties of the Vue-component,
        //       we need to wait for the next tick
        await component.$nextTick();

        const bbox = element.getBoundingClientRect();
        const { top, bottom } = bbox;
        const { left, right } = bbox;

        const avgXPosition = left + (element.offsetWidth - tooltip.offsetWidth) / 2;
        const avgYPosition = top + (element.offsetHeight - tooltip.offsetHeight) / 2;

        switch (component.position) {
            case TOOLTIP_DIRECTION.BOTTOM:
                component.top = bottom;
                component.left = avgXPosition;
                break;
            case TOOLTIP_DIRECTION.LEFT:
                component.left = left - tooltip.offsetWidth;
                component.top = avgYPosition;
                break;
            case TOOLTIP_DIRECTION.RIGHT:
                component.left = right;
                component.top = avgYPosition;
                break;
            default:
                component.top = top - tooltip.offsetHeight;
                component.left = avgXPosition;
                break;
        }
    }, DEFAULT_DELAY);
};

const hideTooltip = () => {
    if (tooltipTimer) {
        clearTimeout(tooltipTimer);
    }

    const component = getTooltipComponent();
    if (!component) {
        return;
    }

    component.visible = false;
};

export default {
    inserted: (el: TooltipElement, params: Record<string, any>) => {
        // eslint-disable-next-line no-param-reassign
        el.tooltipHandler = showTooltip.bind(this, params as TooltipParams);
        el.addEventListener('mouseenter', el.tooltipHandler);
        el.addEventListener('mouseleave', hideTooltip);
    },
    update: (el: TooltipElement, params: Record<string, any>) => {
        // Update params on component update (any props change)
        el.removeEventListener('mouseenter', el.tooltipHandler);
        el.removeEventListener('mouseleave', hideTooltip);
        // eslint-disable-next-line no-param-reassign
        el.tooltipHandler = showTooltip.bind(this, params as TooltipParams);
        el.addEventListener('mouseenter', el.tooltipHandler);
        el.addEventListener('mouseleave', hideTooltip);
    },
    unbind: (el: TooltipElement) => {
        hideTooltip();
        el.removeEventListener('mouseenter', el.tooltipHandler);
        el.removeEventListener('mouseleave', hideTooltip);
    },
} as ObjectDirective;
