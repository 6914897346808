import { inject, injectable } from '@/inversify';
import type StoreFacade from '@/modules/common/services/store-facade';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type HelperService from '@/modules/common/services/helper.service';

import ChainMarketsStore from './chain-markets.store';
import ChainService, { ChainServiceS } from '../../chain.service';

export const ChainMarketsServiceS = Symbol.for('ChainMarketsServiceS');

@injectable()
export default class ChainMarketsService {
    @inject(KEY.StoreFacade)
    private storeFacade!: StoreFacade;

    @inject(ChainServiceS)
    private chainService!: ChainService;

    @inject(MarketsApiServiceS)
    private marketsApiService!: MarketsApiService;

    @inject(KEY.DocumentFiltersService)
    private documentFiltersService!: DocumentFiltersService;

    @inject(KEY.HelperService)
    private helperService!: HelperService;

    private readonly storeState: ChainMarketsStore = this.storeFacade.getState('ChainMarketsStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.settings.groupBy,
                this.settings.provider,
                this.documentFiltersService.settings.year,
                this.documentFiltersService.settings.month,
            ],
            this.resetLoading.bind(this),
        );
    }

    get settings() {
        return this.chainService.settings;
    }

    get isLoading() {
        return false
            || !this.data
            || this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService
            .dynamicLoading(
                this.storeState.loading,
                this.loadData.bind(this),
            );

        return this.storeState.clusterList;
    }

    private async resetLoading() {
        this.storeState.loading.reset();
    }

    private async loadData() {
        const settings = {
            ...this.settings,
            ...this.documentFiltersService.settings,
        };

        // this.storeState.clusterList = await this.marketsApiService.getClusterList(settings);

        return true;
    }
}
