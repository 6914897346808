import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import { Expose, Transform } from 'class-transformer';
import { IsString, IsOptional, IsNumber } from 'class-validator';

export default class AllProvidersModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    @IsOptional()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.group_doc_name)
    @IsNumber()
    @IsOptional()
    groupDocName!: string;

    @Expose()
    @Transform((_, plain) => plain.di_light_name)
    @IsNumber()
    @IsOptional()
    diName!: string;

    @Expose()
    @Transform((_, plain) => plain.display_name)
    @IsString()
    @IsOptional()
    label!: string;

    @Expose()
    @Transform((_, plain) => plain.total_score)
    @IsNumber()
    @IsOptional()
    maxScore!: number | null;

    @Expose()
    @Transform((_, plain) => (
        Object
            .values(ScanDisabledProviders)
            .some(value => (plain.provider_name
                ? plain.provider_name.includes(value)
                : false))
    ))
    isScanDisabled!: boolean;
}
