import { container } from '@/inversify.container';

import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import type PaginationMiddleware from '@/router/middlewares/pagination.middleware';
import { KEY } from '@/inversify.keys';

import { CommonRoutes } from '../common.routes';
import { PromotionsDayPopup } from '../hotel/promotions.routes';

export default function PromotionsClusterLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(KEY.PaginationMiddleware)]),

        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/cluster/pages/promotions-cluster.page.vue'),
                children: [
                    PromotionsDayPopup(prefix, 'day-info/:hotelId/:compsetId/:provider/:program/:day'),
                    ...CommonRoutes(prefix),
                ],
            },
        ],
    };
}
