import CompsetMainModel from '@/modules/cluster/models/compset-main.model';
import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    IsOptional, Validate,
} from 'class-validator';
import RatesCheckinDatesValidator from '@/modules/rates/models/validators/checkin-dates.validator';
import { SCAN_DEFAULT_ESTIMATION, SCAN_DEFAULT_ESTIMATION_ONE_DAY, SCAN_STATUS } from '@/modules/rates/constants';
import RatesCheckinDayModel, { RatesCheckinDayInterface } from '@/modules/rates/models/rates-checkin-day.model';
import RatesHotelModel from '@/modules/rates/models/rates-hotel.model';
import { RatesDocumentScanValues } from '@/modules/rates/models/rates-document-scan.model';

// FIXME: Currently this model is used for DiLite (All-Channels) and Regular Rates
//        We need to split it into two separate models since they used different checkin date models
export default class RatesCompsetMainModel extends CompsetMainModel {
    @Expose()
    @Transform((_, plain) => {
        const checkinDates: any = {};

        if (!plain.checkin_dates) {
            return plain.checkin_dates;
        }

        Object.keys(plain.checkin_dates).forEach(day => {
            const isAllModel = plain.provider_name === 'all';

            if (!isAllModel) {
                checkinDates[Number(day)] = plainToClass(
                    RatesCheckinDayModel,
                    plain.checkin_dates[day],
                    { excludeExtraneousValues: true },
                );
                return;
            }

            const checkinDate: Record<string, RatesHotelModel> = {};

            Object.keys(plain.checkin_dates[day]).forEach(provider => {
                if (provider === 'update_date') return;
                if (provider === 'day_statistics') return;

                checkinDate[provider] = plainToClass(
                    RatesHotelModel,
                    plain.checkin_dates[day][provider],
                    { excludeExtraneousValues: true },
                );
            });

            checkinDates[+day] = checkinDate;
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    checkinDates?: {
        [day: number]: RatesCheckinDayInterface | Record<string, RatesHotelModel> | null;
    };

    @Expose()
    @Transform((_, plain) => {
        const isDiLite = plain.provider_name === 'all';
        if (!isDiLite) return {};

        const checkinDates = plain.checkin_dates;
        const demandDates: {
            [day: number]: number | null;
        } = {};

        if (!checkinDates) {
            return checkinDates;
        }

        Object.keys(checkinDates).forEach(day => {
            const cd = checkinDates[Number(day)];
            if (!cd) return;

            const timestamp = Object
                .keys(cd)
                .reduce((acc, providerName) => {
                    const updateDate = cd[providerName].update_date;
                    const updateTimestamp = updateDate
                        ? (new Date(updateDate)).getTime()
                        : null;

                    if (updateTimestamp && updateTimestamp > acc) {
                        return updateTimestamp;
                    }

                    return acc;
                }, 0);

            demandDates[Number(day)] = timestamp || null;
        });

        return demandDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    updateDates?: {
        [day: number]: string | null;
    };

    @Expose()
    @Transform((_, plain) => ({
        status: plain.status || SCAN_STATUS.FINISHED,
        estimation: plain.ui_notify?.estimated_time || SCAN_DEFAULT_ESTIMATION,
        startTime: plain.ui_notify?.started_at || (plain.status === SCAN_STATUS.IN_PROGRESS ? (new Date()).toISOString() : null),
        id: (plain.scan_id && plain.scan_id.length) ? plain.scan_id[plain.scan_id.length - 1] : null,
        estimationOneDay: plain.ui_notify?.estimated_time_one_day_sec || SCAN_DEFAULT_ESTIMATION_ONE_DAY,
        endTime: plain.update_date || null,
        ratio: 0,
    }))
    scan!: Omit<RatesDocumentScanValues, 'documentId'>;;
}
