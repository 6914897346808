import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { AxiosRequestConfig } from 'axios';
import { plainToClass } from 'class-transformer';
import ConfigModel from '@/modules/config/models/config.model';
import type ApiService from '@/modules/common/services/api.service';

@injectable()
export default class ConfigService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    private config: ConfigModel | null = null;

    async init() {
        const response = await this.apiService.get('/app.config.json');

        if (response && response.data) {
            this.config = plainToClass(ConfigModel, <ConfigModel> response.data, { excludeExtraneousValues: true });
            this.apiService.setRequestInterceptor(this.setBaseUrl.bind(this));
        }
    }

    setBaseUrl(req: AxiosRequestConfig) {
        return {
            ...req,
            baseURL: this.apiUrl || '',
        };
    }

    get apiUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_API_URL;
    }

    get fornovaApp(): string {
        if (!this.config) {
            return '';
        }
        return this.config.FORNOVA_APP || '';
    }

    get socketUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_SOCKET_URL;
    }

    get ssoUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_SSO_URL;
    }

    get googleKey(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_GOOGLE_KEY;
    }

    get diUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_DI_URL;
    }

    get diAdminUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_DI_ADMIN_URL;
    }

    get biUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_BI_URL;
    }

    get ecUrl(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_EC_URL;
    }

    get parityTableauName(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_PARITY_TABLEAU_NAME;
    }

    get fleetDensityTableauName(): string | null {
        if (!this.config) {
            return null;
        }
        return this.config.VUE_APP_FLEET_DENSITY_TABLEAU_NAME;
    }

    get appVersion(): string | undefined {
        return process.env.VUE_APP_VERSION;
    }

    get walkmeUrl() {
        return this.config ? this.config.VUE_APP_WALKME_URL || null : null;
    }

    get walkmeUser() {
        return this.config ? this.config.VUE_APP_WALKME_USER || null : null;
    }

    get whatfixUrl() {
        return this.config ? this.config.VUE_APP_WHATFIX_URL || null : null;
    }

    get ciUpgradeUrl() {
        return this.config ? this.config.VUE_APP_CI_UPGRADE_URL || null : null;
    }

    get diUpgradeUrl() {
        return this.config ? this.config.VUE_APP_DI_UPGRADE_URL || null : null;
    }

    get adminUrl() {
        return this.config ? this.config.VUE_APP_ADMIN_URL || null : null;
    }

    get coralogixKey() {
        return this.config ? this.config.VUE_APP_CORALOGIX_API_KEY || null : null;
    }

    get otelServiceName() {
        if (!this.config || !this.config.VUE_APP_OTEL_SERVICE) {
            throw new Error('VUE_APP_OTEL_SERVICE config is not specified');
        }
        return this.config.VUE_APP_OTEL_SERVICE;
    }

    get otelHeaders() {
        if (!this.config || !this.config.VUE_APP_OTEL_HEADERS) {
            throw new Error('VUE_APP_OTEL_HEADERS config is not specified');
        }
        return this.config.VUE_APP_OTEL_HEADERS;
    }

    get otelURL() {
        const origin = /localhost/.test(window.location.origin) ? window.location.host : window.location.origin;
        const url = `${origin}/telemetry`;
        return url;
        // if (!this.config || !this.config.VUE_APP_OTEL_URL) {
        //     throw new Error('VUE_APP_OTEL_HEADERS config is not specified');
        // }
        // return this.config.VUE_APP_OTEL_URL;
    }

    get tencentApiKey() {
        if (!this.config) return null;
        return this.config.VUE_APP_TENCENT_MAP_API_KEY;
    }
}
