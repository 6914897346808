enum ScanDisabledProviders {
    ALL = 'all',
    CTRIP = 'ctrip',
    MOBILE_APP = 'mobile_app',
    CHEAPEST = 'cheapest',
    FLIGGY = 'fliggy',
    MEITUAN = 'meituan',
}

export default ScanDisabledProviders;
