import { injectable } from '@/inversify';
import LoadingModel from '@/modules/common/models/loading.model';
import getSymbolFromCurrency from 'currency-symbol-map';

const PROVIDERS_LOGO_LIB = require.context('@/modules/common/assets/provider-logos', true, /\.png$|\.jpg$|\.svg$/);

@injectable()
export default class HelperService {
    dynamicLoading(loading: LoadingModel, loadFunc: () => Promise<boolean>) {
        if (loading.startDate !== null || loading.finishDate !== null) {
            return;
        }

        loading.start();

        loadFunc()
            .then(status => {
                if (!status) return;
                loading.finish();
            })
            .catch(err => {
                loading.finish();
                throw err;
            });
    }

    currencySymbol(currency: string | null): string | null {
        switch (currency) {
            case 'S$':
            case 'SGD':
                return 'S$';
            case 'HK':
            case 'HK$':
            case 'HKD':
                return 'HK$';
            case 'CA':
            case 'CAD':
                return 'c$';
            case 'CLP':
                return 'CLP';
            case 'CNY':
                return 'CNY';
            case 'JPY':
                return 'JPY';
            case 'MX':
            case 'MXN':
                return 'MXN$';
            case 'AUD':
            case 'AU':
                return 'AUD';
            case 'AED':
                return 'AED';
            case 'SAR':
                return 'SAR';
            default:
                return getSymbolFromCurrency(currency) || currency;
        }
    }

    providerLogo(providerName: string) {
        if (!PROVIDERS_LOGO_LIB) return '';

        const baseName = providerName.trim().toLowerCase().split('_')[0];
        const path = PROVIDERS_LOGO_LIB.keys().find(key => key.startsWith(`./${baseName}`));

        if (path) {
            return PROVIDERS_LOGO_LIB(path);
        }

        return PROVIDERS_LOGO_LIB('./logo-placeholder.svg');
    }

    fill(length: number = 1, symbol: string = '&nbsp;') {
        return symbol.repeat(length);
    }
}
