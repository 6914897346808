import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import Day from '../common/types/day.type';
import RVSStore from './store/rvs-hotel.store';

import type HelperService from '../common/services/helper.service';
import type StoreFacade from '../common/services/store-facade';
import type UserService from '../user/user.service';
import RateValueScoreApiService from './rate-value-score-api.service';
import RateValueScoreService from './rate-value-score.service';

export const RVSOvertimeServiceS = Symbol.for('RVSOvertimeService') as unknown as string;

const CURRENT_MONTH_DOCUMENT_INDEX = 2;

@injectable()
export default class RVSOvertimeService {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.RateValueScoreSerivce) private rateValueScoreService!: RateValueScoreService;
    @inject(KEY.RateValueScoreApiService) private rateValueScoreApiService!: RateValueScoreApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) private userService!: UserService;

    readonly rvsStore: RVSStore = this.storeFacade.getState('RVSStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.userService.currentHotelId,
            this.rvsStore.settings.provider,
        ], () => {
            this.storeState.loading.reset();
        });
    }

    get storeState() {
        return this.rvsStore.overtime;
    }

    get isLoading() {
        return !this.data.length || this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    private async loadData() {
        const { currentHotelId } = this.userService;
        const { provider } = this.rvsStore.settings;

        if (!currentHotelId) return false;

        this.storeState.data = await this.rateValueScoreApiService
            .getOvertimeData(currentHotelId, provider);

        return true;
    }

    getDataset(dayRange: number, averageValue: boolean = false): (number | null)[] {
        const data = [] as (number | null)[];
        const todayMonth = new Date().getMonth();
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - dayRange);

        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);

        for (let i = 0; i < dayRange * 2 + 1; i++) {
            const docIndex = CURRENT_MONTH_DOCUMENT_INDEX + currentDate.getMonth() - todayMonth;
            const doc = this.data[docIndex];
            const day = currentDate.getDate() as Day;
            const value = !averageValue
                ? this.rateValueScoreService.getMainValue(day, doc)
                : this.rateValueScoreService.getCompsetAverageValue(day, doc);

            data.push(value || null);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return data;
    }
}
