import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type HelperService from '@/modules/common/services/helper.service';
import type UserService from '@/modules/user/user.service';
import METRICS_SCORE from '@/modules/score/constants/metrics-score.constant';
// eslint-disable-next-line import/no-unresolved
import type StoreFacade from '@/modules/common/services/store-facade';
import ClusterScoreStore from './store/score.store';
import ClusterScoreApiService, { ClusterScoreApiServiceS } from './score-api.service';
import ClusterScoreStatisticsItemModel from './models/score-statistics-item.model';
import ClusterScoreStatisticsModel from './models/score-statistics.model';

export const ClusterScoresServiceS = Symbol.for('ClusterScoresServiceS');
@injectable()
export default class ClusterScoresService {
    @inject(ClusterScoreApiServiceS) private chainScoreApiService!: ClusterScoreApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) private userService!: UserService;

    readonly storeState: ClusterScoreStore = this.storeFacade.getState('ClusterScoreStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.userService.isUserLoaded,
        ], this.storeState.loading.reset.bind(this.storeState.loading));
    }

    async loadData(): Promise<boolean> {
        const [
            scoreRank,
            scoreStatisticsHistory,
        ] = await Promise.all([
            this.chainScoreApiService.getScoreRank(),
            this.chainScoreApiService.getScoreStatisticsHistory(),
        ]);

        if (scoreRank && scoreStatisticsHistory) {
            this.storeState.scoreRank = scoreRank;
            this.storeState.scoreStatisticsHistory = scoreStatisticsHistory;
        }

        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState;
    }

    get scoreStatisticsHistory(): ClusterScoreStatisticsModel| null {
        if (!this.storeState.scoreStatisticsHistory) {
            return null;
        }
        return this.storeState.scoreStatisticsHistory;
    }

    get scoreRank() {
        return this.storeState.scoreRank;
    }

    get score() {
        if (!this.lastScore) {
            return null;
        }

        return this.lastScore.score;
    }

    get scoreDiff() {
        if (!(this.lastScore && this.lastScore.score) || !(this.previousScore && this.previousScore.score)) {
            return null;
        }
        return this.lastScore.score - this.previousScore.score;
    }

    get lastScore(): ClusterScoreStatisticsItemModel | null {
        if (!this.scoreStatisticsHistory) {
            return null;
        }

        const keys: string[] = Object.keys(this.scoreStatisticsHistory);
        // @ts-ignore
        return this.scoreStatisticsHistory[keys[keys.length - 1]];
    }

    get previousScore() {
        if (!this.scoreStatisticsHistory) {
            return null;
        }

        const keys = Object.keys(this.scoreStatisticsHistory);
        // @ts-ignore
        return this.scoreStatisticsHistory[keys[keys.length - 2]];
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    getDatesInRange(): string[] {
        const currentDay = new Date();
        const startDate = new Date(`${currentDay.getFullYear() - 2}-${currentDay.getMonth() + 1}-${currentDay.getDate()}`);
        const dates : string[] = [];
        for (;startDate <= currentDay; startDate.setDate(startDate.getDate() + 1)) {
            dates.push(`${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`);
        }
        return dates;
    }

    filterDates(array: string[], metric: METRICS_SCORE) {
        let newArray: string[] = [];
        switch (metric) {
            case METRICS_SCORE.MONTH: {
                newArray = array.slice(array.length - 30, array.length);
                return newArray;
            }
            case METRICS_SCORE.MONTH3: {
                newArray = array.slice(array.length - 90, array.length);
                return newArray;
            }
            case METRICS_SCORE.MONTH6: {
                newArray = array.slice(array.length - 180, array.length);
                return newArray;
            }
            case METRICS_SCORE.YTD: {
                const startDate = new Date(new Date().getFullYear(), 0, 1);
                const date = `${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`;
                const startDateIndex = array.findIndex(d => d === date);
                newArray = array.slice(startDateIndex);
                return newArray;
            }
            case METRICS_SCORE.YEAR: {
                newArray = array.slice(array.length - 365, array.length);
                return newArray;
            }
            case METRICS_SCORE.MAX: {
                return array;
            }
            default: {
                newArray = array.slice(array.length - 30, array.length);
                return newArray;
            }
        }
    }
}
