import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { container } from '@/inversify.container';
import { KEY } from '@/inversify.keys';
import type PaginationMiddleware from '@/router/middlewares/pagination.middleware';
import { CommonRoutes } from '../common.routes';

export default function InsightsClusterRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(KEY.PaginationMiddleware)]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/cluster/pages/insights-cluster.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                    {
                        name: `${prefix}.hotels`,
                        path: ':hotelsGroupId',
                        component: () => import('@/modules/cluster/pages/insights-cluster.modal.page.vue'),
                        meta: { modal: true },
                        children: [
                            ...CommonRoutes(`${prefix}.hotels`),
                        ],
                    },
                ],
            },
        ],
    };
}
