import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import { KEY } from '@/inversify.keys';
import { routerData } from '@/router/routerData';
import type HelperService from '@/modules/common/services/helper.service';
import type RatesService from '@/modules/rates/rates.service';

import { RatesDocToTrendsModel, PlainDocument } from './models/rates-doc-to-trends.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import type ProvidersService from '../providers/providers.service';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { calculateDiff } from '../common/utils/calculate-diff';
import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import { PriceHistoryToTrendsModel } from './models/price-history-to-trends.model';
import { PRICE_SHOWN } from '../rates/constants';
import { RatesAnalysisServiceS } from '../rates/rates-analysis.service';
import { RatesAnalysisFiltersServiceS } from '../rates/rates-analysis-filters.service';
import type RatesAnalysisFiltersService from '../rates/rates-analysis-filters.service';
import type {
    CompsetCompareData,
    RateTrendsService,
    TrendsWithRatesDocument,
    TrendFilters,
    TrendsDocument,
    TrendsStatistics,
} from './types';
import type RatesDocumentModel from '../rates/models/rates-document.model';
import type RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import type { HotelRooms } from '../common/interfaces';
import type Day from '../common/types/day.type';
import type CompsetsService from '../compsets/compsets.service';
import type StoreFacade from '../common/services/store-facade';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type RatesAnalysisService from '../rates/rates-analysis.service';
import type HotelAnalysisTrendsStore from './store/hotel-analysis-trends.store';
import type HotelsService from '../hotels/hotels.service';

@injectable()
export default class HotelAnalysisTrendsService implements RateTrendsService, TrendsWithRatesDocument {
    /** New version of price history service. This one only for compare mode hotel level popup */

    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.CompsetsService) private compsetService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    private readonly storeState: HotelAnalysisTrendsStore = this.storeFacade.getState('HotelAnalysisTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.ratesAnalysisService.dataWithoutLoading,
                this.ratesService.dataWithoutLoading,
                this.date,
            ],
            async (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || !this.date || !this.ratesAnalysisService.data.length) {
                    return;
                }

                // For all rate documents on hotel page we use same ratesService, so we need to skip reset for 'all' and 'cheapest' providers
                if (this.ratesService.dataWithoutLoading?.providerName === 'all'
                    || this.ratesService.dataWithoutLoading?.providerName === 'cheapest'
                    || !routerData.router.currentRoute.name?.includes('analysis')
                ) {
                    return;
                }

                // Avoid extra requests if document not loaded yet.
                if (this.ratesService.isLoading
                    || this.compsetService.isLoading
                    || this.ratesAnalysisService.isLoading
                ) {
                    return;
                }

                this.storeState.trendsLoading.reset();
            },
        );
    }

    get compset() {
        return this.compsetService.currentCompset;
    }

    get ratesDocument() {
        return this.storeState.ratesDocument as RatesDocumentModel | null;
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get filters() {
        const { los, pos, competitors } = this.documentFiltersService.settings;
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.ratesService.settings;
        const compName = this.ratesAnalysisFiltersService.filterComparisonName;
        const compValue = this.ratesAnalysisFiltersService.comparisonValues;
        const comparisonFilter = [compName || '', ...compValue.map(v => v.name)];
        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.allProviders[provider].label : '';

        if (los === null
            || !pos
            || !competitors
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || !priceType
            || !comparisonFilter
            || numberOfGuests === null
            || !this.compset
        ) {
            return null;
        }

        return {
            los,
            pos,
            entities: competitors.map(String).concat([String(this.compset.ownerHotelId)]),
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compsetName: this.compset.name,
            compsetType: this.compset.type,
            comparisonFilter,
        } as TrendFilters;
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get trendsLoading() {
        return this.storeState.trendsLoading;
    }

    get ratesLoading() {
        return this.ratesService.loading;
    }

    get statistics() {
        const trends = (this.trendsDocument?.trendData || []) as TrendsDocument['trendData'];
        const stats = trends.map(dayTrend => {
            if (!dayTrend || !this.compset || !trends.length) {
                return null;
            }

            const mainRooms = dayTrend.data[String(this.compset.ownerHotelId)]?.rooms || [];

            const mainRates = mainRooms.map(room => {
                if (!room?.price) {
                    return null;
                }

                return room.price[`${this.localPriceShown}Price` as keyof NonNullable<RatesDocumentItemModel['price']>];
            });

            const numberOfRooms = this.ratesAnalysisFiltersService.comparisonValues.length + 1;
            const compRooms = (new Array(numberOfRooms).fill(null)).map((r, index) => {
                const { competitors } = this.documentFiltersService.settings;
                return competitors.reduce((acc, hotelId) => {
                    const hotelData = dayTrend.data[hotelId];

                    if (!hotelData || !hotelData.rooms) {
                        return acc;
                    }

                    return {
                        ...acc,
                        [hotelId]: hotelData.rooms[index] || null,
                    };
                }, {} as HotelRooms);
            });

            const compRates = compRooms.map(room => this.ratesCommonService.getCompsetPrice(room, this.compset!.type, this.localPriceShown));

            // Diff and assessment is needed only for main price
            const diff = (compRates[0] && mainRates[0]) ? calculateDiff(mainRates[0], compRates[0], 0) : null;
            const assessment = diff !== null
                ? this.ratesCommonService.getCardAssessment(diff / 100, this.compset)
                : null;

            const compset = {
                compRates,
                mainRate: mainRates[0],
                diff,
                type: this.compset.type,
                assessment,
            } as CompsetCompareData;

            return {
                compset,
                occupancy: dayTrend.occupancy,
                demand: dayTrend.demand,
                currency: this.trendsDocument!.currency,
            } as TrendsStatistics;
        });

        return stats;
    }

    init(day: Day) {
        this.localPriceShown = this.documentFiltersService.priceShown;
        this.update(day);
    }

    update(day: Day) {
        const { year, month } = this.documentFiltersService;
        this.date = new Date(year, month, day);
    }

    /** Loads trends data and use ratesDocument as its 1st item */
    private async loadData() {
        if (!this.date) {
            return false;
        }

        this.storeState.ratesDocument = this.ratesService.dataWithoutLoading as RatesDocumentModel | null;

        // Empty doc after the request - out of range
        if (!this.ratesDocument?.id) {
            return !this.ratesService.loading.isLoading();
        }

        this.storeState.trendsDocument = this.generateRatesDocumentIntoTrends();

        if (!this.trendsDocument) {
            throw new Error('Rates document can`t be parsed.');
        }

        const lastUpdate = this.ratesDocument.checkinDates ? this.ratesDocument.checkinDates[this.date.getDate()].updateDate : null;

        // At this moment rates loading is completed, but if no data for the rates document, trends can't be requested.
        if (!lastUpdate) {
            return true;
        }

        const day = this.date.getDate();
        const mainDocId = this.ratesDocument.id;
        const { currency } = this.ratesDocument;

        const mainDocPromise = this.ratesPriceHistoryApiService
            .getRatesPriceHistoryByDay(day as Day, mainDocId, this.ratesService.settings, currency || null);

        const compareDocsPromises = this.ratesAnalysisFiltersService.comparisonValues.map((value, index) => {
            const settings = this.ratesAnalysisService.getSettings(index);
            const docId = this.ratesAnalysisService.data[index]?.id;

            if (!docId || !settings) {
                return Promise.resolve(null);
            }

            return this.ratesPriceHistoryApiService
                .getRatesPriceHistoryByDay(day as Day, docId, settings, currency || null);
        });

        const data = await Promise.all([mainDocPromise, ...compareDocsPromises]);

        const historyTrendDocuments = data.map(d => (plainToClass(
            PriceHistoryToTrendsModel,
            { ...d, lastUpdate },
            { excludeExtraneousValues: true },
        ) as TrendsDocument));

        // Merge all price history documents into one (rooms[0] is a room from main doc, rooms[1..2] are from compared doc)
        const mergedTrendData = historyTrendDocuments[0].trendData.map((mainDoc, trendIndex) => {
            const compareTrends = historyTrendDocuments.slice(1, historyTrendDocuments.length);
            const mergedDoc = mainDoc || {
                data: {},
                demand: null,
                occupancy: null,
                updateDate: null,
            };

            compareTrends.forEach(compDoc => {
                const dayData = compDoc.trendData[trendIndex]?.data;

                if (!dayData) {
                    return;
                }

                Object.keys(dayData).forEach((hotelId, compareDocIndex) => {
                    if (!mergedDoc.data[hotelId] && dayData[hotelId]) {
                        const rooms: (RatesDocumentItemModel | null)[] = new Array(historyTrendDocuments.length).fill(null);
                        [rooms[compareDocIndex]] = dayData[hotelId].rooms;

                        mergedDoc.data[hotelId] = {
                            entityType: 'hotel',
                            rooms,
                        };
                    } else if (dayData[hotelId]) {
                        const rooms: (RatesDocumentItemModel | null)[] = new Array(historyTrendDocuments.length).fill(null);
                        [rooms[0]] = mergedDoc.data[hotelId].rooms;
                        [rooms[compareDocIndex]] = dayData[hotelId].rooms;

                        mergedDoc.data[hotelId] = {
                            ...mergedDoc.data[hotelId],
                            rooms,
                        };
                    }
                });
            });

            return mergedDoc;
        });

        // Set price history data to trends document, except 0 element.
        // 0 element is a group doc.
        this.storeState.trendsDocument = {
            ...this.trendsDocument,
            trendData: [
                this.trendsDocument.trendData[0],
                ...mergedTrendData.slice(1, mergedTrendData.length),
            ],
        };

        return true;
    }

    /** Generates trends document with ratesDocument as `0` element. */
    private generateRatesDocumentIntoTrends() {
        if (!this.ratesDocument || !this.date) {
            return null;
        }

        const compareDocs = this.ratesAnalysisService.data;
        const compareRooms = compareDocs.map(d => {
            if (!d?.checkinDates) {
                return {};
            }

            const { hotels } = d.checkinDates[this.date!.getDate()];

            const hotelIds = Object.keys(hotels);

            const rooms = hotelIds.reduce((acc, id) => {
                const { room } = hotels[Number(id)];

                if (room) {
                    acc[id] = room;
                }

                return acc;
            }, {} as Record<string, RatesDocumentItemModel>);

            return rooms;
        });

        const entities = [...(this.compset?.competitors || []), (this.compset?.ownerHotelId || null)]
            .filter(e => e !== null)
            .map(String);

        const names = entities.reduce((acc, id) => ({
            ...acc,
            [id]: this.hotelsService.hotelNames[id],
        }), {} as Record<string, string>);

        const trendsDocument = plainToClass(
            RatesDocToTrendsModel,
            {
                ...this.ratesDocument,
                extraRooms: compareRooms,
                date: this.date,
                entities,
                names,
            } as PlainDocument,
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        return trendsDocument;
    }
}
