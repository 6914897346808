/* eslint-disable camelcase */

import type Percent from '@/modules/common/types/percent.type';
import { Expose, Transform } from 'class-transformer';
import { objectToCamel } from '@/modules/common/filters/keys-to-camel.filter';
import {
    IsString, IsEnum, IsInt, IsNumber,
} from 'class-validator';

import { FE_ONLY_PROVIDERS, PROMOTION_ALLOWED_PROVIDERS } from '@/modules/providers/constants';
import COMPSET_TYPES from '@/modules/compsets/constants/compset-type.constant';
import THRESHOLD from '@/modules/compsets/constants/treshhold.constant';
import LoadingModel from '@/modules/common/models/loading.model';

import { NON_CAMEL_CASED_PROVIDER_PATTERNS } from '../constants';
import type { CompsetSettings } from '../interfaces';

export default class CompsetModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.name)
    @IsString()
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_name)
    @IsString()
    customerName!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_hotel_fornova_id)
    ownerHotelId!: number;

    @Expose()
    @Transform((_, plain) => !!plain.primary)
    isMain!: boolean;

    @Expose({ name: 'active' })
    @Transform(value => (value === undefined ? true : value))
    isActive!: boolean;

    @Expose()
    @Transform((_, plain) => String(plain.type).toLowerCase())
    @IsEnum(COMPSET_TYPES)
    type!: COMPSET_TYPES;

    @Expose()
    @Transform((_, plain) => {
        const providers = plain.rate_providers || [];
        // As all and cheapest should be enabled for all users, BE won't send it.
        // If it is in compsets request, it is BE bug
        return providers.concat(FE_ONLY_PROVIDERS);
    })
    @IsString({ each: true })
    rateProviders!: string[];

    @Expose()
    @Transform((_, plain) => (plain.market_providers && plain.market_providers.length ? plain.market_providers : plain.providers))
    @IsString({ each: true })
    marketProviders!: string[];

    @Expose()
    @Transform((_, plain) => {
        const marketProviders: string[] = plain.market_providers && plain.market_providers.length ? plain.market_providers : plain.providers;
        return marketProviders.filter(provider => PROMOTION_ALLOWED_PROVIDERS.includes(provider));
    })
    promotionProviders!: string[];

    @Expose()
    @Transform((_, plain) => parseInt(plain.marketId, 10))
    marketId!: number;

    @Expose()
    @Transform((_, plain) => plain.providers_pos)
    providersPos: {name: string, pos: string[]}[] | undefined;

    @Expose()
    @Transform((_, plain) => (plain.los ? plain.los : []).sort((a: number, b: number) => a - b))
    @IsInt({ each: true })
    los!: number[];

    @Expose()
    @IsString({ each: true })
    // Excludes duplicates from server
    @Transform((_, plain) => (plain.pos ? [...new Set(plain.pos)] : []))
    pos!: string[];

    @Expose()
    @Transform((_, plain) => plain.main_pos)
    @IsString()
    mainPos!: string;

    @Expose()
    @Transform((_, plain) => plain.competitors)
    @IsNumber({}, { each: true })
    competitors!: number[];

    @Expose()
    @Transform((_, plain) => (
        plain.max_threshold || plain.max_threshold === 0 ? Number((plain.max_threshold / 100).toFixed(2)) : THRESHOLD.DEFAULT_MAX))
    @IsNumber()
    maxThreshold!: Percent;

    @Expose()
    @Transform((_, plain) => (
        plain.min_threshold || plain.min_threshold === 0 ? Number((plain.min_threshold / 100).toFixed(2)) : THRESHOLD.DEFAULT_MIN))
    @IsNumber()
    minThreshold!: Percent;

    @Expose()
    @Transform(() => new LoadingModel())
    loading!: LoadingModel;

    @Expose()
    @Transform((_, plain) => objectToCamel(plain.settings, NON_CAMEL_CASED_PROVIDER_PATTERNS))
    settings!: CompsetSettings;
}
