import { $enum } from 'ts-enum-util';
import _ from 'lodash';

import { inject } from '@/inversify.inject';
import { injectable } from '@/inversify.injectable';
import { KEY } from '@/inversify.keys';

import { HotelNotifications } from './constants';
import SettingsApiService from './settings-api.service';
import SettingsStore from './settings.store';
import { HotelAlertsAndNotifications } from '../user/models/user-hotel-alerts.model';
import { CompsetReportsSettings } from './models/settings.model';
import type CompsetsService from '../compsets/compsets.service';
import type HelperService from '../common/services/helper.service';
import type StoreFacade from '../common/services/store-facade';
import type UserService from '../user/user.service';
import type { NetCalculationSettings } from '../compsets/interfaces';
import type { HotelNetCalculationFilters } from './types';
import type ProvidersService from '../providers/providers.service';

interface SettingsHotelServicePublicInterface {
    /**
     * Contains a list of compset IDs of the current
     * hotel that are used for generating HTML-reports
     *
     * Use updateCompsetsForReports to update this list
     */
    emailHtmlReportSettings: CompsetReportsSettings[] | null;
}

@injectable()
export class SettingsHotelService implements SettingsHotelServicePublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.SettingsApiService) private settingsApiService!: SettingsApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    private readonly storeState: SettingsStore = this.storeFacade.getState('SettingsStore');

    constructor() {
        const setDefaultNetCalcFilters = () => {
            const [firstCompset] = this.compsetsService.compsets || [];

            if (!firstCompset) {
                return;
            }

            this.hotelNetCalculationFilters = {
                compset: firstCompset.id,
                pos: firstCompset.pos[0],
                provider: this.providersService.allProviders[firstCompset.rateProviders[0]].groupDocName,
            };
        };

        setDefaultNetCalcFilters();

        // Watcher for net calculation page
        this.storeFacade.watch(
            () => this.compsetsService.compsets,
            (n, o) => {
                if (_.isEqual(n, o)) {
                    return;
                }

                setDefaultNetCalcFilters();
                this.storeState.hotelNetCalculations.loading.reset();
            },
        );
    }

    get alertsAndNotifications() {
        this.helperService.dynamicLoading(this.storeState.hotelAlertsAndNotifications.loading, this.loadHotelSettings.bind(this));
        return this.storeState.hotelAlertsAndNotifications.hotels;
    }

    get isAlertsAndNotificationsLoading() {
        return this.storeState.hotelAlertsAndNotifications.loading.isLoading();
    }

    get emailHtmlReportSettings() {
        const { settings, currentHotelId } = this.userService.user || {};
        if (!settings) return null;

        const { emailHtmlReportSettings } = settings;
        if (!emailHtmlReportSettings) return null;

        return emailHtmlReportSettings[currentHotelId!] || null;
    }

    get scoreComparedTo() {
        return this.storeState.scoreComparedTo;
    }

    set scoreComparedTo(value: number) {
        this.storeState.scoreComparedTo = value;
    }

    get hotelNetCalculations() {
        this.helperService.dynamicLoading(
            this.storeState.hotelNetCalculations.loading,
            async () => {
                if (this.compsetsService.loading.isLoading()) {
                    return false;
                }

                if (!this.compsetsService.compsets?.length) {
                    this.storeState.hotelNetCalculations.data = null;
                    return true;
                }

                const data = this.compsetsService.compsets?.reduce((acc, compset) => ({
                    ...acc,
                    [compset.id]: compset.settings.netRateVat,
                }), {} as Record<string, NetCalculationSettings>);

                this.storeState.hotelNetCalculations.data = data;

                return true;
            },
        );

        return this.storeState.hotelNetCalculations.data;
    }

    get hotelNetCalculationFilters() {
        return this.storeState.hotelNetCalculations.filters;
    }

    get hotelNetCalculationLoading() {
        return this.storeState.hotelNetCalculations.loading;
    }

    set hotelNetCalculationFilters(filters: Partial<HotelNetCalculationFilters>) {
        this.storeState.hotelNetCalculations.filters = {
            ...this.storeState.hotelNetCalculations.filters,
            ...filters,
        };
    }

    private async loadHotelSettings() {
        const { user } = this.userService;

        if (!user) {
            return false;
        }

        await this.compsetsService.loading.whenLoadingFinished();

        const { currentHotelId } = user;
        if (currentHotelId === null) {
            return false;
        }

        const res = await this.settingsApiService.getHotelSettings(currentHotelId);

        if (res.hotels === null) {
            return false;
        }

        $enum(HotelNotifications).forEach(value => {
            if (res.hotels![currentHotelId][value]) return;

            res.hotels![currentHotelId][value] = {};
        });

        this.storeState.hotelAlertsAndNotifications = {
            ...this.storeState.hotelAlertsAndNotifications,
            hotels: res.hotels,
        };

        return true;
    }

    saveAlertsAndNotifications(fornovaId: number, newSettings: HotelAlertsAndNotifications[0]) {
        return this.settingsApiService.updateHotelSettings({ [fornovaId]: newSettings });
    }

    async saveCompsetReportSettings(newSettings: CompsetReportsSettings[]) {
        const { user } = this.userService;

        if (!user) {
            return;
        }

        if (!user.settings.emailHtmlReportSettings) {
            user.settings.emailHtmlReportSettings = {};
        }

        await this.settingsApiService.updateSettings({
            emailHtmlReportSettings: {
                [user.currentHotelId!]: newSettings,
            },
        });
    }
}
