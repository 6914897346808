import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import { KEY } from '@/inversify.keys';
import type HelperService from '@/modules/common/services/helper.service';
import type RatesService from '@/modules/rates/rates.service';

import { AllChannelsToTrendsModel, PlainDocument } from './models/all-channels-to-trends.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import type ProvidersService from '../providers/providers.service';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { calculateDiff } from '../common/utils/calculate-diff';
import RatesPriceHistoryApiService, { RatesPriceHistoryApiServiceS } from './rates-price-history-api.service';
import { PriceHistoryToTrendsModel } from './models/price-history-to-trends.model';
import RatesDocumentAllModel from '../rates/models/rates-document-all.model';
import { ASSESSMENT_TYPES } from '../common/constants';
import PRICE from '../common/modules/rates/constants/price.enum';
import { PRICE_SHOWN } from '../rates/constants';
import type {
    CompsetCompareData,
    RateTrendsService,
    TrendFilters,
    TrendsDocument,
    TrendsStatistics,
    TrendsWithAllChannelsDocument,
} from './types';
import type RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import type Day from '../common/types/day.type';
import type CompsetsService from '../compsets/compsets.service';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type StoreFacade from '../common/services/store-facade';
import type HotelAllChannelsTrendsStore from './store/hotel-all-channels-trends.store';

@injectable()
export default class HotelAllChannelsTrendsService implements RateTrendsService, TrendsWithAllChannelsDocument {
    /** New version of price history service. This one only for rates hotel level popup */

    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.CompsetsService) private compsetService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesPriceHistoryApiServiceS) private ratesPriceHistoryApiService!: RatesPriceHistoryApiService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;

    private readonly storeState: HotelAllChannelsTrendsStore = this.storeFacade.getState('HotelAllChannelsTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.providersService.allProviders,
                this.ratesDocument,
                this.date,
            ],
            async (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || !this.date) {
                    return;
                }

                // For all rate documents on hotel page we use same ratesService, so we need to skip reset for non 'all' providers
                if (this.ratesDocument?.providerName !== 'all') {
                    return;
                }

                // Avoid extra requests if document not loaded yet.
                if (this.ratesService.isLoading || this.compsetService.isLoading || this.providersService.allProvidersLoading.isLoading()) {
                    return;
                }

                this.storeState.trendsLoading.reset();
            },
        );
    }

    get compset() {
        return this.compsetService.currentCompset;
    }

    get ratesDocument() {
        return this.ratesService.dataWithoutLoading as RatesDocumentAllModel | null;
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get filters() {
        const { los, pos } = this.documentFiltersService.settings;
        const { rateProviders } = this.compset || {};
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.ratesService.settings;
        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.allProviders[provider].label : '';
        const entities = rateProviders?.filter(p => p !== 'all' && p !== 'cheapest');

        if (los === null
            || !pos
            || !entities
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || numberOfGuests === null
            || !priceType
            || !this.compset
        ) {
            return null;
        }

        return {
            los,
            pos,
            entities,
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compsetName: this.compset.name,
            compsetType: this.compset.type,
        } as TrendFilters;
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get trendsLoading() {
        return this.storeState.trendsLoading;
    }

    get ratesLoading() {
        return this.ratesService.loading;
    }

    get statistics() {
        const trends = (this.trendsDocument?.trendData || []) as TrendsDocument['trendData'];
        const stats = trends.map(dayTrend => {
            if (!dayTrend || !this.compset || !trends.length || !this.date) {
                return null;
            }

            let sum = 0;
            let numberValidRooms = 0;
            let highest: number | null = null;
            let lowest: number | null = null;
            Object.keys(dayTrend.data).forEach(entity => {
                if (!dayTrend.data[entity].rooms[0]?.price) {
                    return;
                }

                const price = dayTrend.data[entity].rooms[0]?.price
                    ? dayTrend.data[entity].rooms[0]!.price![`${this.localPriceShown}Price` as keyof NonNullable<RatesDocumentItemModel['price']>]
                    : null;

                if (price === null || price === PRICE.SOLD_OUT || price === PRICE.NA) {
                    return;
                }

                if (!highest || price > highest) {
                    highest = price;
                }

                if (!lowest || price < lowest) {
                    lowest = price;
                }

                numberValidRooms += 1;
                sum += price;
            });

            const average = (sum && numberValidRooms) ? Math.round(sum / numberValidRooms) : 0;

            let diff: number | null = null;
            let assessment: ASSESSMENT_TYPES | null = null;

            if (highest && average) {
                diff = calculateDiff(highest, average) as number;
                assessment = this.ratesCommonService.getCardAssessment(diff, this.compset);
            }

            const compset = {
                compRates: [average],
                diff,
                type: 'average',
                assessment,
            } as CompsetCompareData;

            const dayStat = {
                compset: average ? compset : null,
                currency: this.trendsDocument!.currency,
            } as TrendsStatistics;

            if (highest && lowest) {
                dayStat.highest = highest;
                dayStat.lowest = lowest;
            }

            return dayStat;
        });

        return stats;
    }

    init(day: Day) {
        this.localPriceShown = this.documentFiltersService.priceShown;
        this.update(day);
    }

    update(day: Day) {
        const { year, month } = this.documentFiltersService;
        this.date = new Date(year, month, day);
    }

    /** Loads trends data and use ratesDocument as its 1st item */
    private async loadData() {
        // If no doc, but rates loading is in progress - false.
        if (!this.ratesDocument?.id || !this.date || !this.compset) {
            return false;
        }

        this.storeState.trendsDocument = this.generateRatesDocumentIntoTrends();

        if (!this.trendsDocument) {
            return false;
        }

        const lastUpdate = this.ratesDocument.updateDates ? this.ratesDocument.updateDates[this.date.getDate()] : null;

        // At this moment rates loading is completed, but if no data for the rates document, trends can't be requested.
        if (!lastUpdate) {
            return true;
        }

        const day = this.date.getDate();
        const docId = this.ratesDocument.id;
        const { currency } = this.ratesDocument;

        const priceHistoryData = await this.ratesPriceHistoryApiService
            .getRatesPriceHistoryByDay(day as Day, docId, this.ratesService.settings, currency || null);

        // Mapping for providers like ctrip_api, in all channels BE don't send _api, so it needs to be mapped
        const groupDocToProviderNameMap = this.providersService.getGroupDocNameToProviderMap(this.compset.rateProviders);

        const historyTrendsDocument = plainToClass(
            PriceHistoryToTrendsModel,
            { ...priceHistoryData, lastUpdate, providersMap: groupDocToProviderNameMap },
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        // Set price history data to trends document, except 0 element.
        // 0 element is a group doc.
        this.storeState.trendsDocument = {
            ...this.trendsDocument,
            trendData: [
                this.trendsDocument.trendData[0],
                ...historyTrendsDocument.trendData.slice(1, historyTrendsDocument.trendData.length),
            ],
        };

        return true;
    }

    /** Generates trends document with ratesDocument as `0` element. */
    private generateRatesDocumentIntoTrends() {
        if (!this.ratesDocument || !this.date || !this.providersService.allProviders || !this.compset) {
            return null;
        }

        const providers = this.compset.rateProviders;

        // Mapping for providers like ctrip_api, in all channels BE don't send _api, so it needs to be mapped
        const groupDocToProviderNameMap = this.providersService.getGroupDocNameToProviderMap(this.compset.rateProviders);

        const names = providers.reduce((acc, name) => ({
            ...acc,
            [name]: this.providersService.allProviders[name]?.label || name,
        }), {} as Record<string, string>);

        const trendsDocument = plainToClass(
            AllChannelsToTrendsModel,
            {
                ...this.ratesDocument,
                date: this.date,
                names,
                providersMap: groupDocToProviderNameMap,
            } as PlainDocument,
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        return trendsDocument;
    }
}
