import { container } from '@/inversify';
import { KEY } from '@/inversify.keys';

import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type MarketsHistoryService from '@/modules/common/modules/markets-history/markets-history.service';
import type { RateTrendsService, TrendsWithRatesDocument } from '@/modules/price-history/types';

import { otelBaseComponentLoading } from '../_utils';

/**
 * Add logs to all rates day popup Vue components
 */
export function OtelRateDayPopupLoading(ratesTrendsServiceKey: symbol) {
    return (constructor: Function) => {
        const rateTrendsService = container.get<RateTrendsService & TrendsWithRatesDocument>(ratesTrendsServiceKey);
        const { filters, trendsLoading, ratesLoading } = rateTrendsService;

        const settings = {
            ...filters,
        } as Record<string, any>;

        otelBaseComponentLoading(constructor, settings, [trendsLoading, ratesLoading]);
    };
}

/**
 * Add logs to all markets day popup Vue components
 */
export function OtelMarketDayPopupLoading(constructor: Function) {
    const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
    const marketsHistoryService = container.get<MarketsHistoryService>(KEY.MarketsHistoryService);

    const settings = {
        ...marketsHistoryService.marketSettings,
        ...documentFiltersService.settings,
    } as Record<string, any>;

    const { loading } = marketsHistoryService;
    otelBaseComponentLoading(constructor, settings, [loading]);
}
