/* eslint-disable camelcase */
import { Expose, Transform } from 'class-transformer';

export class DefaultFiltersPayloadModel {
    @Expose({ name: 'numberOfGuests' })
    number_of_guest?: number;

    @Expose()
    price?: string;

    @Expose()
    los?: number;

    @Expose({ name: 'mealType' })
    @Transform(value => {
        if (!value) return undefined;
        return (value === 'any' ? [] : [value]);
    })
    meal_type?: string[];

    @Expose({ name: 'mapProvider' })
    map_provider?: string;

    @Expose({ name: 'targetedInsights' })
    targeted_insights?: Record<string, boolean>;

    cleanup() {
        const keys = Object.keys(this) as Array<keyof DefaultFiltersPayloadModel>;

        keys.forEach(key => {
            const isValidProp = this[key] !== undefined && this[key] !== null;
            if (isValidProp) return;

            delete this[key];
        });

        return this;
    }
}
