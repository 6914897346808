import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import { KEY } from '@/inversify.keys';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type HelperService from '@/modules/common/services/helper.service';
import type RatesService from '@/modules/rates/rates.service';

import type {
    CompsetCompareData,
    RateTrendsService,
    TrendFilters,
    TrendsDocument,
    TrendsStatistics,
    TrendsWithRatesDocument,
} from './types';
import { RatesDocToTrendsModel, PlainDocument } from './models/rates-doc-to-trends.model';
import { PRICE_SHOWN } from '../rates/constants';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import RatesDocumentModel from '../rates/models/rates-document.model';
import type ProvidersService from '../providers/providers.service';
import RatesCommonService, { RatesCommonServiceS } from '../common/modules/rates/rates-common.service';
import { HotelRooms } from '../common/interfaces';
import { calculateDiff } from '../common/utils/calculate-diff';
import RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import type CompsetsService from '../compsets/compsets.service';
import type Day from '../common/types/day.type';
import type HotelCheapestTrendsStore from './store/hotel-cheapest-trends.store';
import type HotelsService from '../hotels/hotels.service';

@injectable()
export default class HotelCheapestTrendsService implements RateTrendsService, TrendsWithRatesDocument {
    /** New version of price history service. This one only for rates hotel level cheapest popup */

    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.CompsetsService) private compsetService!: CompsetsService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    private readonly storeState: HotelCheapestTrendsStore = this.storeFacade.getState('HotelCheapestTrendsStore');

    constructor() {
        // Dependencies to reset whole document
        this.storeFacade.watch(
            () => [
                this.ratesDocument,
                this.date,
            ],
            async (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o) || !this.date) {
                    return;
                }

                // For all rate documents on hotel page we use same ratesService, so we need to skip reset for 'all' and 'cheapest' providers
                if (this.ratesDocument?.providerName !== 'cheapest') {
                    return;
                }

                // Avoid extra requests if document not loaded yet.
                if (this.ratesService.isLoading || this.compsetService.isLoading) {
                    return;
                }

                this.storeState.trendsLoading.reset();
            },
        );
    }

    get compset() {
        return this.compsetService.currentCompset;
    }

    get ratesDocument() {
        return this.ratesService.dataWithoutLoading as RatesDocumentModel | null;
    }

    get trendsDocument() {
        this.helperService.dynamicLoading(this.storeState.trendsLoading, this.loadData.bind(this));
        return this.storeState.trendsDocument;
    }

    get filters() {
        const { los, pos, competitors } = this.documentFiltersService.settings;
        const {
            provider, roomTypeId, mealTypeId, numberOfGuests, priceType,
        } = this.ratesService.settings;
        const mealType = this.mealTypesService.getMealType(mealTypeId);
        const roomType = this.roomTypesService.getRoomType(roomTypeId);
        const providerLabel = provider ? this.providersService.allProviders[provider].label : '';

        if (los === null
            || !pos
            || !competitors
            || !providerLabel
            || !roomType?.displayName
            || !mealType?.displayName
            || numberOfGuests === null
            || !priceType
            || !this.compset
        ) {
            return null;
        }

        return {
            los,
            pos,
            entities: competitors.map(String).concat([String(this.compset.ownerHotelId)]),
            provider: providerLabel,
            roomType: roomType.displayName,
            numberOfGuests,
            priceType,
            mealType: mealType.displayName,
            compsetName: this.compset.name,
            compsetType: this.compset.type,
        } as TrendFilters;
    }

    get date() {
        return this.storeState.date;
    }

    set date(d: Date | null) {
        this.storeState.date = d;
    }

    get localPriceShown() {
        return this.storeState.localPriceShown;
    }

    set localPriceShown(p: PRICE_SHOWN) {
        this.storeState.localPriceShown = p;
    }

    get trendsLoading() {
        // Cheapest popup doesn't have trends. For logs only group doc loading is needed
        return this.ratesService.loading;
    }

    get ratesLoading() {
        return this.ratesService.loading;
    }

    get statistics() {
        const trends = (this.trendsDocument?.trendData || []) as TrendsDocument['trendData'];
        const stats = trends.map(dayTrend => {
            if (!dayTrend || !this.compset || !trends.length) {
                return null;
            }

            const { competitors } = this.documentFiltersService.settings;
            const compRooms = competitors.reduce((acc, hotelId) => {
                const hotelData = dayTrend.data[hotelId];

                if (!hotelData || !hotelData.rooms) {
                    return acc;
                }

                return {
                    ...acc,
                    [hotelId]: hotelData.rooms[0] || null,
                };
            }, {} as HotelRooms);

            const mainRooms = dayTrend.data[String(this.compset.ownerHotelId)]?.rooms;
            const mainRate = (mainRooms && mainRooms[0]?.price)
                ? mainRooms[0].price[`${this.localPriceShown}Price` as keyof RatesDocumentItemModel['price']]
                : null;

            const compRate = this.ratesCommonService.getCompsetPrice(compRooms, this.compset.type, this.localPriceShown);
            const diff = (compRate && mainRate) ? calculateDiff(mainRate, compRate, 0) : null;
            const assessment = diff !== null
                ? this.ratesCommonService.getCardAssessment(diff / 100, this.compset)
                : null;

            const compset = {
                compRates: [compRate],
                mainRate,
                diff,
                type: this.compset.type,
                assessment,
            } as CompsetCompareData;

            return {
                compset,
                occupancy: dayTrend.occupancy,
                demand: dayTrend.demand,
                currency: this.trendsDocument!.currency,
            } as TrendsStatistics;
        });

        return stats;
    }

    init(day: Day) {
        this.localPriceShown = this.documentFiltersService.priceShown;
        this.update(day);
    }

    update(day: Day) {
        const { year, month } = this.documentFiltersService;
        this.date = new Date(year, month, day);
    }

    /** Cheapest channel don't have trends, so insert data from rates doc into [0] element and return */
    private async loadData() {
        // If no doc, but rates loading is in progress - false.
        if (!this.ratesDocument?.id || !this.date) {
            return false;
        }

        this.storeState.trendsDocument = this.generateRatesDocumentIntoTrends();

        return true;
    }

    /** Generates trends document with ratesDocument as `0` element. */
    private generateRatesDocumentIntoTrends() {
        if (!this.ratesDocument || !this.date || !this.compset) {
            return null;
        }

        const { competitors, ownerHotelId } = this.compset!;
        const names = [...competitors, ownerHotelId].reduce((acc, id) => ({
            ...acc,
            [id]: this.hotelsService.hotelNames[id],
        }), {} as Record<string, string>);

        // Mapping for providers like ctrip_api, in cheapest channel BE don't send _api, so it needs to be mapped
        const providersMap = this.providersService.getGroupDocNameToProviderMap(this.compset.rateProviders);

        const trendsDocument = plainToClass(
            RatesDocToTrendsModel,
            {
                ...this.ratesDocument,
                date: this.date,
                names,
                providersMap,
            } as PlainDocument,
            { excludeExtraneousValues: true },
        ) as TrendsDocument;

        return trendsDocument;
    }
}
