import { Route, RouteConfig } from 'vue-router';
import { CommonRoutes } from '../common.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/ranking/pages/ranking-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export function RankingHistory(prefix: string) {
    return {
        name: `${prefix}.ranking-history-popup`,
        path: 'ranking-history/:provider',
        props: (route: Route) => ({ provider: route.params.provider }),
        component: () => import('@/modules/common/modules/ranking-history/pages/ranking-history.modal.vue'),
        meta: { modal: true },
    };
}

export const DownloadExcelModal = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    component: () => import('@/modules/ranking/pages/download-excel.page.vue'),
    meta: { modal: true },
});

export default function RankingHotelLevelRoutes(basePath: string, prefix: string): RouteConfig {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
            clusterId: Number(r.params.clusterId),
        }),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/ranking/pages/ranking.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                    RankingHistory(prefix),
                    DownloadExcelModal(prefix),
                    ScheduledReports(prefix),
                ],
            },
        ],
    };
}
