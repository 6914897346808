import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type HelperService from '@/modules/common/services/helper.service';
import type StoreFacade from '@/modules/common/services/store-facade';

import HotelCatalogApiService, { HotelCatalogApiServiceS } from './hotel-catalog-api.service';
import HotelCatalogStore from './store/hotel-catalog.store';
import type HotelModel from '../../models/hotel.model';
import type HotelsService from '../../hotels.service';

export const HotelCatalogServiceS = Symbol.for('HotelCatalogServiceS');
@injectable()
export default class HotelCatalogService {
    @inject(HotelCatalogApiServiceS) private hotelCatalogApiService!: HotelCatalogApiService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;

    readonly storeState: HotelCatalogStore = this.storeFacade.getState('HotelCatalogStore');
    query: string | null = null;

    catalogWatcherCallbacks: ((cb: HotelModel[]) => void)[] = [];

    constructor() {
        this.storeFacade
            .watch(() => this.storeState.hotels, this.dispatchCatalogWathers.bind(this));
    }

    dispatchCatalogWathers(newCatalog: HotelModel[]) {
        this.catalogWatcherCallbacks
            .forEach(callback => {
                callback(newCatalog || []);
            });
    }

    async loadData(): Promise<boolean> {
        this.storeState.hotels = null;

        if (!this.query) {
            return true;
        }

        let hotelCatalogDocuments = [];

        if (this.isUrl(this.query)) {
            hotelCatalogDocuments = await this.hotelCatalogApiService
                .getHotelCatalogByUrl(this.query);
        } else {
            hotelCatalogDocuments = await this.hotelCatalogApiService
                .getHotelCatalog(this.query);
        }

        if (hotelCatalogDocuments) {
            this.storeState.hotels = hotelCatalogDocuments;
        }

        const { hotelNames, hotelGeoLocations } = hotelCatalogDocuments.reduce((acc, hotel) => ({
            hotelNames: {
                ...acc.hotelNames,
                [hotel.id]: hotel.name,
            },
            hotelGeoLocations: {
                ...acc.hotelGeoLocations,
                [hotel.id]: hotel.geoLocation,
            },
        }), {} as { hotelNames: Record<string, string>, hotelGeoLocations: Record<string, { lat: number, lng: number }>, });

        this.hotelsService.addHotelNames(hotelNames);
        this.hotelsService.addHotelGeoLocations(hotelGeoLocations);

        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.hotels;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    update(query: string) {
        this.query = query;
        this.storeState.loading.reset();
    }

    /**
     * Subscribe to catalog update
     * Returns function to unsubscribe
     */
    onCatalogChanged(callback: (newCatalog: HotelModel[]) => void) {
        this.catalogWatcherCallbacks.push(callback);

        return () => {
            this.catalogWatcherCallbacks = this.catalogWatcherCallbacks
                .filter(cb => cb !== callback);
        };
    }

    clearResults() {
        this.storeState.hotels = [];
    }

    isUrl(query: string) {
        const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
            + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
            + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
            + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
            + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
            + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(query);
    }
}
