/* eslint-disable camelcase */

import { Expose, plainToClass, Transform } from 'class-transformer';
import RatesHotelModel from '@/modules/rates/models/rates-hotel.model';
import type Day from '@/modules/common/types/day.type';

export default class RatesPriceHistoryFilterAllModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data) {
            return plain.trend_data;
        }

        Object.keys(plain.trend_data).forEach(date => {
            trendData[date] = {};
            // CORD-1651 TODO: Remove it when will be ready fix on the backend
            const wrongProviders = ['expedia_mobile_app_mobile_app', 'booking_mobile_app_mobile_app', 'booking_basic_mobile_app_mobile_app'];

            Object.keys(plain.trend_data[date]).forEach(provider => {
                const { rooms } = plain.trend_data[date][provider];

                if (rooms && !wrongProviders.includes(provider)) {
                    trendData[date][provider] = plainToClass(RatesHotelModel, plain.trend_data[date][provider], { excludeExtraneousValues: true });
                }
            });
        });

        return trendData;
    })

    trendData!: {
        [date: string]: Record<string, RatesHotelModel> | null;
    };
}
