import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CompsetModel from '@/modules/compsets/models/compset.model';
import type StoreFacade from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';

@injectable()
export default class ClusterCompsetsService implements Stateable {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    getCompsetById(compsetId: string) : CompsetModel | null {
        const { clusterHotels } = this.storeState;

        const clusterHotel = clusterHotels
            ? clusterHotels
                .find(hotel => hotel.compsets?.some(comp => comp.id === compsetId))
            : null;

        return clusterHotel
            ? clusterHotel.compsets
                .find(comp => comp.id === compsetId) || null
            : null;
    }

    getCompetitors(compsetId: string): number[] {
        const compset = this.getCompsetById(compsetId);
        return compset ? compset.competitors : [];
    }

    getMarketId(compsetId: string) {
        const compset = this.getCompsetById(compsetId);
        if (!compset) {
            return null;
        }

        return compset.marketId;
    }

    get compsets(): CompsetModel[] | null {
        if (this.storeState.clusterHotels) {
            return this.storeState.clusterHotels
                .map(hotel => hotel.compsets)
                .flat();
        }

        return null;
    }
}
