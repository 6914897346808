import _ from 'lodash';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ProvidersStore from '@/modules/providers/store/providers.store';
import type ProvidersApiService from '@/modules/providers/providers-api.service';
import type HelperService from '@/modules/common/services/helper.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type UserService from '@/modules/user/user.service';

import { PROMOTION_ALLOWED_PROVIDERS } from './constants';
import type { ProvidersServicePublicInterface } from './types';
import type { Item } from '../common/interfaces';

@injectable()
export default class ProvidersService implements ProvidersServicePublicInterface {
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.ProvidersApiService) private providersApiService!: ProvidersApiService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) private userService!: UserService;

    private readonly storeState: ProvidersStore = this.storeFacade.getState('ProvidersStore');

    get allProviders() {
        this.helperService.dynamicLoading(this.storeState.allProvidersLoading, this.loadAllProviders.bind(this));
        return this.storeState.allProviders;
    }

    get allProvidersLoading() {
        return this.storeState.allProvidersLoading;
    }

    get userProviders() {
        this.helperService.dynamicLoading(this.storeState.userProvidersLoading, this.loadUserProviders.bind(this));

        return {
            rateProviders: this.storeState.userRateProviders,
            marketProviders: this.storeState.userMarketProviders,
            promotionProviders: this.storeState.userPromotionProviders,
        };
    }

    get groupedUserProviderNames() {
        this.helperService.dynamicLoading(this.storeState.userProvidersLoading, this.loadUserProviders.bind(this));

        return {
            rate: this.storeState.rateGroupedProviderNames,
            market: this.storeState.marketGroupedProviderNames,
            promotion: this.storeState.promotionGroupedProviderNames,
        };
    }

    get userProvidersLoading() {
        return this.storeState.userProvidersLoading;
    }

    toItemsList(providerList: string[], useGroupNameAsValue: boolean = false) {
        const addedProviderValues = {} as Record<string, boolean>;

        const itemsList = providerList
            .reduce((acc, provider) => {
                const value = useGroupNameAsValue ? (this.allProviders[provider]?.groupDocName || provider) : provider;

                if (addedProviderValues[value]) {
                    return acc;
                }

                addedProviderValues[value] = true;

                return [...acc, {
                    value,
                    name: this.allProviders[provider]?.label || provider,
                    disabled: false,
                }];
            }, [] as Item<string>[]);

        return itemsList;
    }

    getGroupDocNameToProviderMap = _.memoize(
        (providers: string[]) => (
            providers.reduce((acc, name) => ({
                ...acc,
                [this.allProviders[name].groupDocName]: name,
            }), {} as Record<string, string>)
        ),
        (...args) => args[0].join(''),
    );

    filterOutProvidersWithSameGroupDocName(providers: string[]) {
        // When map is created, all providers with same group doc name are merged
        const providersMap = this.getGroupDocNameToProviderMap(providers);
        return Object.keys(providersMap).map(provider => providersMap[provider]);
    }

    private async loadAllProviders(): Promise<boolean> {
        this.storeState.allProviders = await this.providersApiService.getAllProviders();
        return true;
    }

    private async loadUserProviders() {
        const { levelName, chainId } = this.userService;

        if (!chainId) {
            return true;
        }

        const dictionary = await this.providersApiService.getUserProviders(chainId, levelName);

        if (!dictionary) {
            return true;
        }

        const allUserRateProviders = dictionary.rates.filter(provider => this.allProviders[provider]);
        const allUserMarketProviders = dictionary.markets.filter(provider => this.allProviders[provider]);
        const allUserPromotionProviders = allUserMarketProviders.filter(p => PROMOTION_ALLOWED_PROVIDERS.includes(p));

        this.storeState.rateGroupedProviderNames = this.mapGroupedProviderNames(allUserRateProviders);
        this.storeState.marketGroupedProviderNames = this.mapGroupedProviderNames(allUserMarketProviders);
        this.storeState.promotionGroupedProviderNames = this.mapGroupedProviderNames(allUserPromotionProviders);

        this.storeState.userRateProviders = this.filterOutProvidersWithSameGroupDocName(allUserRateProviders);
        this.storeState.userMarketProviders = this.filterOutProvidersWithSameGroupDocName(allUserMarketProviders);
        this.storeState.userPromotionProviders = this.filterOutProvidersWithSameGroupDocName(allUserPromotionProviders);

        return true;
    }

    private mapGroupedProviderNames(allProviderNames: string[]) {
        const groupedProvidersMap = {} as Record<string, string[]>;

        allProviderNames.forEach(providerName => {
            if (!groupedProvidersMap[this.allProviders[providerName].groupDocName]) {
                groupedProvidersMap[this.allProviders[providerName].groupDocName] = [providerName];
                return;
            }

            groupedProvidersMap[this.allProviders[providerName].groupDocName].push(providerName);
        });

        return groupedProvidersMap;
    }
}
