import { Expose, Transform } from 'class-transformer';
import type RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import type Day from '@/modules/common/types/day.type';
import type RatesHotelModel from '@/modules/rates/models/rates-hotel.model';

import { NUMBER_OF_CHART_DAYS } from '../constants';
import type { TrendsDocument, TrendEntityData } from '../types';

/** Transform PlainDocument into trends initial document.
 * Only for hotel level rates price history.
 * @field trendData: list of trend items, 0 element is initial rates document
 * @field date: requested checkin date
 * @field documentId: id of initial rates doucment
 * @field names: key-value pairs of hotel names
 * @field exchangeRate: currency excange rate
 * @field providerName: name of initial rate document's provider
 * @field currency: document's currency
 */

export type PlainDocument = RatesDocumentAllModel & { date: Date, names: Record<string, string>, providersMap?: Record<string, string> };

export class AllChannelsToTrendsModel implements TrendsDocument {
    @Expose()
    @Transform((_, plain: PlainDocument) => {
        const { checkinDates, updateDates, providersMap } = plain;

        if (!checkinDates || !checkinDates[plain.date.getDate() as Day]) {
            return Array(NUMBER_OF_CHART_DAYS).fill(null);
        }

        const checkinDate = checkinDates[plain.date.getDate() as Day] as Record<string, RatesHotelModel>;
        const providers = Object.keys(checkinDate || {}) as unknown as number[];

        const data = providers.reduce((acc, provider) => {
            if (!checkinDate[provider]) {
                return acc;
            }

            const { screenshot, losRestriction, link } = checkinDate[provider];
            const {
                updateDate, rooms, pax, taskId,
            } = checkinDate[provider];

            const roomTypeId = (rooms && Object.keys(rooms).length) ? Number(Object.keys(rooms)[0]) : null;
            const room = roomTypeId !== null ? rooms[roomTypeId][0] : null;
            const resultRooms = room ? [room] : [];

            return {
                ...acc,
                [providersMap ? providersMap[provider] : provider]: {
                    rooms: resultRooms,
                    entityType: 'provider',
                    updateDate,
                    link,
                    losRestriction,
                    screenshot,
                    pax,
                    taskId,
                } as TrendEntityData,
            };
        }, {} as { [hotelId: string]: TrendEntityData });

        const lastUpdateTimestamp = updateDates ? updateDates[plain.date.getDate()] : null;
        const updateDate = lastUpdateTimestamp ? new Date(lastUpdateTimestamp) : null;

        return [{
            data,
            demand: checkinDate.demand,
            occupancy: checkinDate.occupancy,
            updateDate,
        }].concat(Array(NUMBER_OF_CHART_DAYS - 1).fill(null));
    })
    trendData!: TrendsDocument['trendData'];

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.date)
    date!: Date;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.id)
    documentId!: number;

    @Expose({ name: 'names' })
    names!: Record<string, string>;

    @Expose()
    @Transform((_, plain: PlainDocument) => 1)
    exchangeRate!: number;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.providerName)
    providerName!: string;

    @Expose()
    @Transform((_, plain: PlainDocument) => plain.currency)
    currency?: string;
}
