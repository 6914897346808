import { Route } from 'vue-router';
import { container } from '@/inversify.container';

import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { CommonRoutes } from '../common.routes';

export const PromotionsDayPopup = (prefix: string, path: string = 'day-info/:day') => ({
    path,
    name: `${prefix}.popup`,
    meta: { modal: true },
    component: () => import('@/modules/promotions/pages/promotions-popup.page.vue'),
    children: [
        {
            path: 'history',
            name: `${prefix}.popup.history`,
            component: () => import('@/modules/promotions/pages/promotions-history.page.vue'),
            meta: { modal: true },
        },
    ],
});

export default function PromotionsHotelLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        props: (r: Route) => ({
            hotelId: +r.params.hotelId,
        }),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                ],
            },
            {
                path: 'graph',
                name: `${prefix}.graph`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonRoutes(`${prefix}.graph`),
                ],
            },
            {
                path: 'table',
                name: `${prefix}.table`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    PromotionsDayPopup(`${prefix}.table`),
                    ...CommonRoutes(`${prefix}.table`),
                ],
            },
            {
                path: 'analysis-table',
                name: `${prefix}.analysis.table`,
                component: () => import('@/modules/promotions/pages/index.page.vue'),
                children: [
                    ...CommonRoutes(`${prefix}.analysis.table`),
                ],
            },
        ],
    };
}
