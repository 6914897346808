import { Route } from 'vue-router';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type UserService from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const ViewAsHotelGuardS = Symbol.for('ViewAsHotelGuardS');
@injectable()
export default class ViewAsHotelGuard implements IGuard {
    @inject(KEY.UserService) private userService!: UserService;

    async canActivate(to: Route, from: Route) {
        return this.userService.isViewAsHotel || false;
    }
}
