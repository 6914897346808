import { Expose, Transform, plainToClass } from 'class-transformer';
import { Validate, IsNumber, IsOptional } from 'class-validator';
import _ from 'lodash';

import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import RatesCheckinDatesValidator from '@/modules/rates/models/validators/checkin-dates.validator';
import Day from '@/modules/common/types/day.type';

import { SCAN_DEFAULT_ESTIMATION } from '../constants';
import { RatesDocumentScanValues } from './rates-document-scan.model';
import RatesHotelModel from './rates-hotel.model';

export default class RatesDocumentAllModel {
    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain._id)
    @IsNumber()
    id!: number;

    @Expose({ name: 'compset_id' })
    compsetId!: string;

    @Expose()
    @Transform((__, plain) => {
        // Deep clone, to avoid 'plain' object modify
        const checkinDates = JSON.parse(JSON.stringify(plain.checkin_dates));

        if (!checkinDates) {
            return checkinDates;
        }

        // CORD-1651 TODO: Remove it when will be ready fix on the backend
        const wrongProviders = ['expedia_mobile_app_mobile_app', 'booking_mobile_app_mobile_app', 'booking_basic_mobile_app_mobile_app'];

        Object.keys(checkinDates).forEach(day => {
            const checkinDay = checkinDates[Number(day)];

            delete checkinDay.day_statistics;
            delete checkinDay.update_date;

            if (_.isEmpty(checkinDay)) {
                delete checkinDates[Number(day)];
                return;
            }

            Object.keys(checkinDay).forEach(provider => {
                if (wrongProviders.includes(provider)) {
                    delete checkinDates[+day][provider];
                    return;
                }

                checkinDates[+day][provider] = plainToClass(RatesHotelModel, checkinDay[provider], { excludeExtraneousValues: true });
            });
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    checkinDates?: Record<Day, { [providerName: string]: RatesHotelModel } | null> | null;

    @Expose()
    @Transform((_, plain) => {
        const checkinDates = plain.checkin_dates;
        const demandDates: {
            [day: number]: number | null;
        } = {};

        if (!checkinDates) {
            return checkinDates;
        }

        Object.keys(checkinDates).forEach(day => {
            const dayDemand = checkinDates[Number(day)]
                && checkinDates[Number(day)].day_statistics
                ? checkinDates[Number(day)].day_statistics.demand
                : null;
            demandDates[Number(day)] = dayDemand;
        });

        return demandDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    demand?: {
        [day: number]: number | null;
    };

    @Expose()
    @Transform((_, plain) => {
        const checkinDates = plain.checkin_dates;
        const demandDates: {
            [day: number]: number | null;
        } = {};

        if (!checkinDates) {
            return checkinDates;
        }

        Object.keys(checkinDates).forEach(day => {
            const cd = checkinDates[Number(day)];
            if (!cd) return;

            const timestamp = Object
                .keys(cd)
                .reduce((acc, providerName) => {
                    const updateDate = cd[providerName].update_date;
                    const updateTimestamp = updateDate
                        ? (new Date(updateDate)).getTime()
                        : null;

                    if (updateTimestamp && updateTimestamp > acc) {
                        return updateTimestamp;
                    }

                    return acc;
                }, 0);

            demandDates[Number(day)] = timestamp || null;
        });

        return demandDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    updateDates?: {
        [day: number]: string | null;
    };

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    updateDate!: Date | null;

    @Expose()
    @Transform((_, plain) => plain.currency)
    currency?: string;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => plain.hotelsName)
    hotelNames!: { [hotelId: number]: string };

    @Expose()
    @Transform((_, plain) => ({
        id: (plain.scan_id && plain.scan_id.length) ? plain.scan_id[plain.scan_id.length - 1] : null,
        status: plain.status || SCAN_STATUS.FINISHED,
        // * 15 because scan can be started for any amount of days 1-30, 15 is middle value
        estimation: plain.ui_notify?.estimated_time ? (plain.ui_notify.estimated_time_one_day_sec * 15) / 1000 : SCAN_DEFAULT_ESTIMATION,
        startTime: plain.ui_notify?.started_at || (plain.status === SCAN_STATUS.IN_PROGRESS ? (new Date()).toISOString() : null),
        endTime: plain.update_date || null,
        ratio: 0,
    }))
    scan!: Omit<RatesDocumentScanValues, 'documentId'>;

    @Expose()
    @Transform(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    numberOfGuestsOptions!: number[];
}
