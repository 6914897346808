import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import UnauthorizedException from '@/modules/common/modules/exception-handler/exceptions/unauthorized.exception';
import { AuthServiceS } from '@/modules/auth/auth.service';
import { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';

import type OpenTelemetryService from '@/modules/open-telemetry/open-telemetry.service';
import type AuthService from '@/modules/auth/auth.service';
import type ErrorException from '@/modules/common/modules/exception-handler/exceptions/error.exception';
import type StoreFacade from '@/modules/common/services/store-facade';
import type NotificationService from '../../../notifications/notification.service';
import GatewayTimeoutException from './exceptions/gateway-timeout.exception';
import BadGatewayException from './exceptions/bad-gateway.exception';
import InternalServerErrorException from './exceptions/internal-service-error.exception';

export const ExceptionHandlerS = Symbol.for('ExceptionHandlerS');
@injectable()
export default class ExceptionHandler {
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(KEY.NotificationService) private notificationService!: NotificationService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    constructor() {
        this.storeFacade.subscribeAction(action => {
            this.catch(action.payload);
        });
    }

    catch(exception: ErrorException) {
        this.otelService.setErrorToActiveSpans(exception, true);

        switch (exception.constructor) {
            case UnauthorizedException:
                this.authService.logout(exception);
                break;
            case BadGatewayException:
            case GatewayTimeoutException:
            case InternalServerErrorException:
                this.notificationService.error({ error: exception });
                break;
            default:
                throw exception;
        }
    }
}
