
import { Vue, Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { AxiosRequestConfig } from 'axios';
import NotificationsContainer from '@/modules/notifications/components/notifications-container.vue';
import type ApiService from '@/modules/common/services/api.service';
import type UserService from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import TranslationsService, { TranslationsServiceS } from '@/modules/translations/translations.service';
import CustomTooltipDirective from '@/modules/common/components/ui-kit/custom-tooltip-directive.vue';
import { routerData } from './router/routerData';
import { LOGTYPE } from './modules/open-telemetry/constants';
import TranslationsModel from './modules/translations/translations.model';
import { USER_LEVELS } from './modules/user/constants';

@Component({
    components: {
        NotificationsContainer,
        CustomTooltipDirective,
    },
})
export default class App extends Vue {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;
    @inject(TranslationsServiceS) private translationsService!: TranslationsService;

    /**
     * Here we will connect services with vue features
     */
    created() {
        this.apiService.setRequestInterceptor(config => {
            this.$Progress.start();
            return config;
        });
        this.apiService.setResponseInterceptor(response => {
            this.$Progress.finish();
            return response;
        });

        // Set fornova-id to header for all hotel requests, undefined is sent for cluster level
        this.apiService.setRequestInterceptor((req: AxiosRequestConfig) => ({
            ...req,
            headers: {
                ...req.headers,
                'fornova-id': this.userService.viewAs === USER_LEVELS.HOTEL
                    ? this.userService.currentHotelId
                    : undefined,
            },
        }));

        this.openTelemetryService.init(routerData.router);

        Promise.all([this.translationsService.loadData(), this.userService.storeState.loading.whenLoadingFinished()])
            .then(() => {
                const { availableLocales } = this.translationsService;
                availableLocales.forEach(locale => {
                    this.$i18n.setLocaleMessage(locale, this.translationsService.getTranslations(locale));
                });

                const locale = this.userService.user!.settings.lang as keyof TranslationsModel;
                this.translationsService.setLocale(locale);

                if (this.userService.isCarUser) {
                    return;
                }

                // OTEL have to be inited with user info, here we awaits sso response
                this.$router.beforeEach((to, from, next) => {
                    this.$gtag.event('page_view', {
                        page_path: to.path,
                        chainId: this.userService.chainId,
                        userId: this.userService.id,
                    });

                    if (from.name) {
                        this.openTelemetryService.endSpan({ name: from.name, prefix: LOGTYPE.VISITPAGE }, { sendLogs: true });
                    }

                    // Avoid sending visit-page log on settings page because all tabs have same name in router
                    if (to.name !== 'settings') {
                        this.openTelemetryService.startSpan({ name: to.name!, prefix: LOGTYPE.VISITPAGE });
                    }
                    next();
                });
            });
    }
}
