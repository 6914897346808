import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import PROVIDERS_REGEXP from '@/modules/alerts/constants/providers-regexp.const';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import AlertsStore from '@/modules/alerts/store/alerts-store';
import type UserService from '@/modules/user/user.service';
import type StoreFacade from '@/modules/common/services/store-facade';

import { USER_LEVELS } from '../user/constants';
import type HelperService from '../common/services/helper.service';

@injectable()
export default class AlertsService {
    @inject(AlertsApiServiceS) private alertsApiService!: AlertsApiService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;

    private readonly storeState: AlertsStore = this.storeFacade.getState('AlertsStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.userService.currentHotelId,
                this.userService.viewAs,
            ],
            this.resetService.bind(this),
        );
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts.concat().sort((a, b) => b.date.getTime() - a.date.getTime());
    }

    get loading() {
        return this.storeState.loading;
    }

    async loadData() {
        this.storeState.alerts = [];

        switch (this.userService.viewAs) {
            case USER_LEVELS.CHAIN:
            case USER_LEVELS.CLUSTER:
                this.storeState.alerts = await this.alertsApiService.getClusterAlerts() || [];
                return true;
            case USER_LEVELS.HOTEL:
                this.storeState.alerts = await this.alertsApiService.getHotelAlerts() || [];
                return true;
            default:
                return true;
        }
    }

    async setAlertReadState(id: string, isRead: boolean) {
        const alert = this.alerts.find(a => a.id === id);

        if (!alert || alert.read === isRead) {
            return;
        }

        alert.read = isRead;

        if (alert.read) {
            await this.alertsApiService.markAlertAsRead(id);
        } else {
            await this.alertsApiService.markAlertAsUnread(id);
        }
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    async deleteAlert(id: string) {
        this.storeState.alerts = this.storeState.alerts.filter(a => a.id !== id);
        await this.alertsApiService.deleteAlert(id);
    }

    getProviderName(message: string) {
        const result = message.match(PROVIDERS_REGEXP);
        return result ? result[0] : null;
    }

    private resetService(
        [hotelId, viewAs]: [number, USER_LEVELS],
        [hotelIdOld, viewAsOld]: [number, USER_LEVELS],
    ) {
        if (
            hotelId === hotelIdOld
            && viewAs === viewAsOld
        ) {
            return;
        }

        this.storeState.loading.reset();
    }
}
