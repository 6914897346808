import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import CarFilterModel from '@/modules/cars/models/cars-filters.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';

export const FleetFilterApiServiceS = Symbol.for('FleetFilterApiServiceS');
@injectable()
export default class FleetFilterApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getCarFilters(): Promise<CarFilterModel | null> {
        const { data } = await this.apiService.get('car/supported/car-filters');

        const carFiltersDocument = plainToClass(CarFilterModel, <CarFilterModel> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(carFiltersDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return carFiltersDocument;
    }
}
