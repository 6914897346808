import { Expose, Transform } from 'class-transformer';

export interface HotelAlertsAndNotifications {
    [hotelId: number]: {
        [key: string]: {
            active?: boolean;
            fornovaIds?: number[];
            options?: any[];
            conditionsValue?: number;
        }
    }
}

export default class UserHotelAlertsModel {
    @Expose()
    @Transform((_, plain) => (Object.keys(plain.hotels).length
        ? plain.hotels
        : null))
    hotels!: HotelAlertsAndNotifications | null;
}
