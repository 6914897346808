import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import ScoreModel from './models/score.model';
import ScoreHistoryModel from './models/score.history.model';

export const ScoreApiServiceS = Symbol.for('ScoreApiServiceS');
@injectable()
export default class ScoreApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getScore(): Promise<ScoreModel> {
        const { data } = await this.apiService.get('users/score');

        const scoreDocument = plainToClass(ScoreModel, <ScoreModel> data, { excludeExtraneousValues: true });

        // TODO validate

        return scoreDocument;
    }

    async getUsersScoreHistory() {
        const { data } = await this.apiService.get('users/score/history');

        const userScoreHistory = plainToClass(
            ScoreHistoryModel,
<ScoreHistoryModel> { scoreHistory: data },
{ excludeExtraneousValues: true },
        );

        return userScoreHistory;
    }

    async getUsersMarketScoreHistory() {
        const { data } = await this.apiService.get('users/market-score/history');

        const userMarketScoreHistory = plainToClass(
            ScoreHistoryModel,
<ScoreHistoryModel> { scoreHistory: data },
{ excludeExtraneousValues: true },
        );

        return userMarketScoreHistory;
    }

    async getUsersChainScoreHistory() {
        const { data } = await this.apiService.get('users/chain-score/history');

        const userChainScoreHistory = plainToClass(
            ScoreHistoryModel,
<ScoreHistoryModel> { scoreHistory: data },
{ excludeExtraneousValues: true },
        );

        return userChainScoreHistory;
    }
}
