import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import { RateTrendsStore, TrendsDocument } from '../types';

@Module
export default class HotelAllChannelsTrendsStore extends VuexModule implements RateTrendsStore {
    trendsDocument: TrendsDocument | null = null;
    trendsLoading: LoadingModel = new LoadingModel();
    date: Date | null = null;
    trendIndex: number = 0;
    localPriceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
}
