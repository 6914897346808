import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import SippModel from '@/modules/cars/modules/sipp/models/sipp.model';
import SippSettingsModel from '@/modules/cars/modules/sipp/models/sipp-settings.model';
import SippPageSettingsModel from '@/modules/cars/modules/sipp/models/sipp-page-settings.model';
import SippDiffModel from '@/modules/cars/modules/sipp/models/sipp-diff.model';
import SippLogItemModel from '@/modules/cars/modules/sipp/models/sipp-log-item.model';
import {
    TRANSMISSION_ANY, CAR_CLASS_ANY, POS_ANY, PROVIDER_ANY, PROVIDER_NONE, VEHICLE_TYPE_ANY, COUNTRIES_ANY, FUEL_TYPE_ANY, BRAND_ANY, BRAND_BLANK,
} from '@/modules/cars/constants/car-filter-types.constant';

export const SippApiServiceS = Symbol.for('SippApiServiceS');
@injectable()
export default class SippApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private emptyField = 'blank';
    private emptyFieldCodes = [
        'fn_sipp_code',
        'normalized_car_category',
        'customer_sipp_code',
    ];

    async getSippData(pageSettings: SippPageSettingsModel, settings: SippSettingsModel): Promise<SippModel> {
        const url = '/car/get-sipp-map';
        const providerName = settings.provider === PROVIDER_ANY ? undefined : settings.provider;
        const brandName = settings.carBrand === BRAND_ANY ? undefined : settings.carBrand;
        const filterData: any = {
            country_code: settings.pos === POS_ANY ? undefined : settings.pos,
            car_category: settings.carClasses === CAR_CLASS_ANY ? undefined : settings.carClasses,
            car_description: settings.carName,
            transmission: settings.transmission === TRANSMISSION_ANY ? undefined : settings.transmission,
            fn_sipp_code: settings.standardCode,
            normalized_car_category: settings.categoryName === CAR_CLASS_ANY ? undefined : settings.categoryName,
            customer_sipp_code: settings.customCode,
            provider_name: providerName === PROVIDER_NONE ? { $exists: false } : providerName,
            vehicle_type: settings.vehicleType === VEHICLE_TYPE_ANY ? undefined : settings.vehicleType,
            country_name: settings.countryName === COUNTRIES_ANY ? undefined : settings.countryName,
            fuel_type: settings.fuelType === FUEL_TYPE_ANY ? undefined : settings.fuelType,
        };
        if (brandName) {
            filterData.brand_name = brandName === BRAND_BLANK ? { $exists: false } : settings.carBrand;
        }
        const filterArray = Object.entries(filterData).filter(item => item[1]);
        const filter: any = {};
        filterArray.forEach(item => {
            // eslint-disable-next-line prefer-destructuring
            filter[item[0]] = item[1];
        });
        this.emptyFieldCodes.forEach(item => {
            if (filter[item] && filter[item].toLowerCase() === this.emptyField) {
                filter[item] = '';
            }
        });

        if (settings.vehicleType === '-') {
            filter.vehicle_type = '';
        }

        if (settings.providerName && !filterData.provider_name) {
            filter.provider_name = settings.providerName;
        }

        if (settings.posCodes && !filterData.country_code) {
            filter.country_code = settings.posCodes;
        }

        const body = {
            filter,
            page_limit: pageSettings.pageLimit,
            page: pageSettings.page,
            sort: { car_description: 1 },
        };
        const { data } = await this.apiService.post(url, body);

        return plainToClass(SippModel, <SippModel> data, { excludeExtraneousValues: true });
    }

    async getSIPPChanges(versionId: number | null): Promise<SippModel> {
        const url = `/car/revert-sipp-map-preview/${versionId}`;
        const { data } = await this.apiService.get(url);

        return plainToClass(SippModel, <SippModel> data, { excludeExtraneousValues: true });
    }

    async updateSippCode(sippChanges: SippDiffModel[]) {
        const url = '/car/update-sipp-map';
        const body = {
            changes: sippChanges,
        };
        const { data } = await this.apiService.post(url, body);
    }

    async sippHistoryLog() {
        const url = '/car/logs-sipp-map';

        const { data } = await this.apiService.get(url);

        return plainToClass(SippLogItemModel, <SippLogItemModel[]> data, { excludeExtraneousValues: true });
    }

    async revertSippCode(historyVersion: number) {
        const url = `/car/revert-sipp-map/${historyVersion}`;
        const { data } = await this.apiService.put(url);
    }

    async downloadData() {
        const url = '/car/get-all-sippcodes-in-csv';
        const { data } = await this.apiService.get(
            url,
            undefined,
            { responseType: 'blob' },
        );
        return data as Blob;
    }
}
