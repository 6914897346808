import { container } from '@/inversify.container';
import { KEY } from '@/inversify.keys';
import use from '@/router/use';

import { CommonRoutes } from './common.routes';
import ViewAsMiddleware, { ViewAsMiddlewareS } from '../middlewares/view-as.middleware';
import type AdminGuard from '../guards/admin.guard';
import type ReadonlyGuard from '../guards/readonly.guard';

export default function SettingsPageRoutes(prefix: string) {
    const basePathName = `${prefix}.settings`;
    const generalPathName = `${basePathName}.general`;
    const hotelPathName = `${basePathName}.hotel`;

    return {
        path: 'settings',
        name: basePathName,
        redirect: { name: `${basePathName}.general` },
        beforeEnter: use([container.get<ReadonlyGuard>(KEY.ReadonlyGuard), container.get<ViewAsMiddleware>(ViewAsMiddlewareS)]),
        component: () => import('@/modules/settings/pages/settings.page.vue'),
        children: [{
            path: 'general',
            name: generalPathName,
            redirect: { name: `${generalPathName}.scan-properties` },
            components: {
                tabs: () => import('@/modules/settings/components/tabs-general.vue'),
                default: () => import('@/modules/common/pages/transition.page.vue'),
            },
            children: [
                {
                    path: 'scan-properties',
                    name: `${generalPathName}.scan-properties`,
                    component: () => import('@/modules/settings/pages/scan-properties.page.vue'),
                },
                {
                    path: 'intradays',
                    name: `${generalPathName}.intradays`,
                    component: () => import('@/modules/settings/pages/intradays.page.vue'),
                },
                {
                    path: 'alerts-notifications',
                    name: `${generalPathName}.alerts-notifications`,
                    component: () => import('@/modules/settings/pages/alerts-notifications.page.vue'),
                },
                {
                    path: 'email-reports',
                    name: `${generalPathName}.email-reports`,
                    component: () => import('@/modules/settings/pages/email-reports-general.page.vue'),
                },
                {
                    path: 'color-palette',
                    name: `${generalPathName}.color-palette`,
                    component: () => import('@/modules/settings/pages/color-palette.page.vue'),
                },
                {
                    path: 'default-filters',
                    name: `${generalPathName}.default-filters`,
                    component: () => import('@/modules/settings/pages/default-filters.page.vue'),
                },
            ],
        }, {
            path: 'hotel/:hotelId',
            name: hotelPathName,
            redirect: { name: `${hotelPathName}.room-type-mapping` },
            components: {
                tabs: () => import('@/modules/settings/components/tabs-hotel.vue'),
                default: () => import('@/modules/common/pages/transition.page.vue'),
            },
            children: [
                {
                    path: 'room-type-mapping',
                    name: `${hotelPathName}.room-type-mapping`,
                    component: () => import('@/modules/settings/pages/room-type-mapping.page.vue'),
                    children: [
                        {
                            name: `${hotelPathName}.room-type-mapping.download-excel-popup`,
                            path: 'download-excel',
                            component: () => import('@/modules/settings/components/download-mapping.popup.vue'),
                            meta: { modal: true },
                        },
                        ...CommonRoutes(`${hotelPathName}.room-type-mapping`),
                    ],
                },
                {
                    path: 'alerts-notifications',
                    name: `${hotelPathName}.alerts-notifications`,
                    component: () => import('@/modules/settings/pages/alerts-notifications.page.vue'),
                    children: [...CommonRoutes(`${hotelPathName}.alerts-notifications`)],
                },
                {
                    path: 'compset-settings',
                    name: `${hotelPathName}.compset-settings`,
                    component: () => import('@/modules/settings/pages/compset-settings.page.vue'),
                    children: [...CommonRoutes(`${hotelPathName}.compset-settings`)],
                },
                {
                    path: 'email-reports',
                    name: `${hotelPathName}.email-reports`,
                    component: () => import('@/modules/settings/pages/email-reports-hotel.page.vue'),
                    children: [...CommonRoutes(`${hotelPathName}.email-reports`)],
                },
                {
                    path: 'scheduled-reports',
                    name: `${hotelPathName}.scheduled-reports`,
                    component: () => import('@/modules/settings/pages/scheduled-reports.page.vue'),
                    children: [
                        ...CommonRoutes(`${hotelPathName}.scheduled-reports`),
                        {
                            name: `${hotelPathName}.scheduled-reports.rate-report-edit`,
                            path: 'rate-report-edit/:reportId',
                            component: () => import('@/modules/rates/pages/rates-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.ratescomparemode-report-edit`,
                            path: 'ratescomparemode-report-edit/:reportId',
                            component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.guestreviews-report-edit`,
                            path: 'guestreviews-report-edit/:reportId',
                            component: () => import('@/modules/ranking/pages/ranking-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.market-report-edit`,
                            path: 'market-report-edit/:reportId',
                            component: () => import('@/modules/markets/pages/markets-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.dilight-report-edit`,
                            path: 'dilight-report-edit/:reportId',
                            component: () => import('@/modules/di-lite/pages/di-lite-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.cluster-rate-report-edit`,
                            path: 'cluster-rate-report-edit/:reportId',
                            component: () => import('@/modules/cluster/pages/rates-cluster-report.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.cluster-guestreviews-report-edit`,
                            path: 'cluster-guestreviews-report-edit/:reportId',
                            component: () => import('@/modules/cluster/pages/ranking-cluster-reports.modal.page.vue'),
                            meta: { modal: true },
                        },
                        {
                            name: `${hotelPathName}.scheduled-reports.cluster-market-report-edit`,
                            path: 'cluster-market-report-edit/:reportId',
                            component: () => import('@/modules/cluster/pages/markets-cluster-reports.modal.page.vue'),
                            meta: { modal: true },
                        },
                    ],
                },
                {
                    path: 'rvs-weight',
                    name: `${hotelPathName}.rvs-weight`,
                    component: () => import('@/modules/settings/pages/rvs-weight.page.vue'),
                },
                {
                    path: 'net-calculation',
                    name: `${hotelPathName}.net-calculation`,
                    component: () => import('@/modules/settings/pages/net-calculation.page.vue'),
                    beforeEnter: use([container.get<AdminGuard>(KEY.AdminGuard)]),
                },
            ],
        }],
    };
}
