import { Expose } from 'class-transformer';

export class SubscriptionsSettingsModel {
    @Expose({ name: 'html-report' })
    htmlReports!: boolean;

    @Expose({ name: 'scheduled-excel' })
    scheduledReports!: boolean;

    @Expose({ name: 'ondemand-report' })
    ondemandReports!: boolean;
}
