import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { IGuard } from '@/router/interfaces/guard.interface';
import type UserService from '@/modules/user/user.service';

export const HotelGuardS = Symbol.for('HotelGuardS');
@injectable()
export default class ReadonlyGuard implements IGuard {
    @inject(KEY.UserService) private userService!: UserService;

    async canActivate() {
        if (!this.userService.isUserLoaded) {
            await this.userService.loading.whenLoadingFinished();
        }

        return !this.userService.isReadonly;
    }
}
