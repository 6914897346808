import moment from 'moment';
import { Expose, Transform } from 'class-transformer';
import RatesHotelModel from '@/modules/rates/models/rates-hotel.model';
import { TrendsDocument, TrendEntityData, TrendEntityType } from '../types';
import { NUMBER_OF_CHART_DAYS } from '../constants';
import RatesPriceHistoryFilterAllModel from './rates-price-history-filter-all.model';
import RatesPriceHistoryModel from './rates-price-history.model';

/** Transform PlainDocument into trends initial document.
 * For price history documemts.
 * @field trendData: list of trend items, 0 element is initial rates document
 * @field date: requested checkin date
 * @field documentId: id of initial rates doucment
 * @field names: key-value pairs of hotel names
 * @field exchangeRate: currency excange rate
 * @field providerName: name of initial rate document's provider
 * @field currency: document's currency
 */

type PlainDocument = (RatesPriceHistoryFilterAllModel | RatesPriceHistoryModel) & { lastUpdate: string | number, providersMap?: Record<string, string> };

export class PriceHistoryToTrendsModel implements Partial<TrendsDocument> {
    /** Converts price history to TrendsDocument. Only trendData field is supposed to be used here.
     * Other fields should be taken from the main document
     */

    @Expose()
    @Transform((_, plain: PlainDocument) => {
        const { trendData, lastUpdate, providersMap } = plain;

        const trends = [] as TrendsDocument['trendData'];

        // If trend keys are from the future, it is probably because of follow the sun feature.
        // In this case we get day diff between today and last key in trends.
        let followTheSunDaysOffset = 0;
        const trendRawDates = Object.keys(trendData);
        const trendDatedsSorted = trendRawDates.map(item => moment.utc(item, 'DD-MM-YYYY')).sort((a, b) => b.valueOf() - a.valueOf());

        if (trendDatedsSorted.length) {
            const lastTrendsKeyInUtc = trendDatedsSorted[0];
            const todayInUtc = (moment.utc()).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
            followTheSunDaysOffset = lastTrendsKeyInUtc.diff(todayInUtc, 'days');

            if (followTheSunDaysOffset < 0) {
                followTheSunDaysOffset = 0;
            }
        }

        for (let i = 0; i < NUMBER_OF_CHART_DAYS; i += 1) {
            const lastUpdateDate = new Date(lastUpdate);
            lastUpdateDate.setDate(lastUpdateDate.getDate() - i);
            // Substract followTheSunDaysOffset from trend keys.
            const trendKey = moment.utc(lastUpdateDate, 'DD-MM-YYYY').subtract(followTheSunDaysOffset, 'days').format('DD-MM-YYYY');

            const dayTrendData = trendData[trendKey];

            if (!dayTrendData) {
                trends.push(null);
            } else {
                const ratesDayTrendData = dayTrendData;

                let mappedTrendData: Record<string, RatesHotelModel>;
                let entityType: TrendEntityType;

                const trendDataItem = {
                    data: {},
                    updateDate: lastUpdateDate.toUTCString(),
                } as TrendsDocument['trendData'][0];

                if (ratesDayTrendData.hotels && !(ratesDayTrendData.hotels instanceof RatesHotelModel)) {
                    mappedTrendData = Object.entries(ratesDayTrendData.hotels).reduce((acc, [hotelId, hotelData]) => ({
                        ...acc,
                        [hotelId]: hotelData,
                    }), {} as Record<string, RatesHotelModel>);

                    trendDataItem!.demand = ratesDayTrendData.demand as number | null;
                    trendDataItem!.occupancy = ratesDayTrendData.occupancy as number | null;
                    entityType = 'hotel';
                } else {
                    mappedTrendData = ratesDayTrendData as Record<string, RatesHotelModel>;
                    entityType = 'provider';
                }

                const entityIds = Object.keys(mappedTrendData);

                trendDataItem!.data = entityIds.reduce((acc, entityId) => {
                    if (!mappedTrendData[entityId]) {
                        return acc;
                    }

                    const { screenshot, losRestriction, link } = mappedTrendData[entityId];
                    const { updateDate, rooms, pax } = mappedTrendData[entityId];

                    // ID have to be string, because google provider use words as keys
                    const roomTypeId = (rooms && Object.keys(rooms).length) ? Object.keys(rooms)[0] : null;
                    const room = roomTypeId !== null ? rooms[roomTypeId][0] : null;

                    return {
                        ...acc,
                        [providersMap ? providersMap[entityId] : entityId]: {
                            rooms: room ? [room] : [],
                            entityType,
                            updateDate,
                            link,
                            losRestriction,
                            screenshot,
                            pax,
                        } as TrendEntityData,
                    };
                }, {} as { [entityId: string]: TrendEntityData });

                trends.push(trendDataItem);
            }
        }

        return trends;
    })
    trendData!: TrendsDocument['trendData'];
}
