import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import HotelModel from '@/modules/hotels/models/hotel.model';
import type ApiService from '@/modules/common/services/api.service';

@injectable()
export default class HotelsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getMyHotels(): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.get('users/fornova-ids');

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }

    async getHotelsById(hotelIds: number[]): Promise<HotelModel[] | null> {
        const { data } = await this.apiService.post('supported/hotel-names', {
            fornovaIds: hotelIds,
        });

        if (!data) {
            return null;
        }

        return plainToClass(HotelModel, <HotelModel[]> data, { excludeExtraneousValues: true });
    }
}
