import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type ConfigService from '@/modules/config/config.service';

declare global {
    interface Window {
        WalkMeInsightsAPI: {
            startPlaybackRecording: () => any,
        },
        WalkMePlayerAPI: {
            toggleMenu: () => any
        },
        _walkmeConfig: {
            smartLoad: boolean;
        },
        onWalkMeInsightsAPIReady: () => any
    }
}

export const WalkmeServiceS = Symbol.for('WalkmeServiceS');
@injectable()
export default class WalkmeService {
    @inject(KEY.ConfigService) private configService!: ConfigService;

    private isInited: boolean = false;
    private isPlaybackStarted: boolean = false;

    init() {
        if (this.isInited === true) return;

        const { walkmeUrl, walkmeUser } = this.configService;

        const scriptElement = this.generateScript(walkmeUrl, walkmeUser);
        if (scriptElement === null) {
            // eslint-disable-next-line no-console
            console.error('walkmeUrl or walkmeUser is missing');
            return;
        }

        this.insertScript(scriptElement);
        window.onWalkMeInsightsAPIReady = this.startRecord;
        this.isInited = true;
    }

    startRecord() {
        if (this.isPlaybackStarted) return;
        window.WalkMeInsightsAPI.startPlaybackRecording();
        this.isPlaybackStarted = true;
    }

    generateScript(walkmeUrl: string | null, walkmeUser: string | null) {
        if (walkmeUrl === null || walkmeUser === null) {
            return null;
        }

        const walkmeScript = document.createElement('script');
        walkmeScript.async = true;
        walkmeScript.type = 'text/javascript';
        walkmeScript.src = `https://cdn.walkme.com/users/${walkmeUser}${walkmeUrl}`;

        return walkmeScript;
    }

    insertScript(element: HTMLScriptElement) {
        const [firstScriptElement] = document.getElementsByTagName('script');

        if (!firstScriptElement || !firstScriptElement.parentNode) {
            // eslint-disable-next-line no-console
            console.error('Failed to insert Walkme script');
            return;
        }

        firstScriptElement.parentNode.insertBefore(element, firstScriptElement);
        // eslint-disable-next-line no-underscore-dangle
        window._walkmeConfig = {
            smartLoad: true,
        };
    }
}
