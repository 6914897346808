
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import Notification from './notification.vue';
import type NotificationService from '../notification.service';

@Component({
    components: {
        Notification,
    },
})
export default class NotificationsContainer extends Vue {
    @inject(KEY.NotificationService) notificationService!: NotificationService;

    get notifications() {
        return this.notificationService.activeNotifications;
    }
}
