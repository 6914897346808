import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import type ClusterService from '@/modules/cluster/cluster.service';
import { KEY } from '@/inversify.keys';

@injectable()
export default class PaginationMiddleware implements IMiddleware {
    @inject(KEY.ClusterService) private clusterService!: ClusterService;

    async canActivate(to: Route) {
        this.clusterService.skip = 0;
        return true;
    }
}
