import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '../common/models/loading.model';
import type { HotelAlertsAndNotifications } from '../user/models/user-hotel-alerts.model';
import type RVSWeightSettingsModel from './models/rvs-weights-settings.model';
import type { NetCalculationSettings } from '../compsets/interfaces';
import type { HotelNetCalculationFilters } from './types';

@Module
export default class SettingsStore extends VuexModule {
    rvsWeight = {
        data: null as RVSWeightSettingsModel | null,
        loading: new LoadingModel(),
    };

    hotelAlertsAndNotifications = {
        loading: new LoadingModel(),
        hotels: {} as HotelAlertsAndNotifications,
    };

    scanProperties = {
        loading: new LoadingModel(),
    };

    hotelNetCalculations = {
        loading: new LoadingModel(),
        data: null as Record<string, NetCalculationSettings> | null,
        filters: {
            compset: null,
            provider: null,
            pos: null,
        } as HotelNetCalculationFilters,
    };

    scoreComparedTo = 1;
}
