import { container } from '@/inversify';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { CommonRoutes } from '../common.routes';

export default function RvsClusterLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS)]),
        children: [
            {
                name: `${prefix}`,
                path: '',
                redirect: '/',
                component: () => import('@/modules/rvs/pages/rvs-cluster.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                ],
            },
        ],
    };
}
