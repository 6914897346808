import axios, {
    AxiosError,
    AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';

import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import { injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { container } from '@/inversify.container';

import UnauthorizedException from '@/modules/common/modules/exception-handler/exceptions/unauthorized.exception';
import ForbiddenException from '@/modules/common/modules/exception-handler/exceptions/forbidden.exception';
import GatewayTimeoutException from '@/modules/common/modules/exception-handler/exceptions/gateway-timeout.exception';
import PreconditionRequestException from '@/modules/common/modules/exception-handler/exceptions/precondition-request.exception';
import ExceptionHandler from '../modules/exception-handler/exception-handler.service';
import ErrorException from '../modules/exception-handler/exceptions/error.exception';
import BadGatewayException from '../modules/exception-handler/exceptions/bad-gateway.exception';
import InternalServerErrorException from '../modules/exception-handler/exceptions/internal-service-error.exception';

interface Params {
    [param: string]: any;
}

@injectable()
export default class ApiService {
    readonly client!: AxiosInstance;
    readonly retryLimit: number = 3;

    constructor() {
        this.client = axios.create();
    }

    setRequestInterceptor(cb: (config: AxiosRequestConfig) => any) {
        this.client.interceptors.request.use(cb);
    }

    setResponseInterceptor(cb: (response: AxiosResponse) => any) {
        this.client.interceptors.response.use(cb);
    }

    async get(path: string, params: Params = {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.get(path, { ...config, params }));
    }

    async post(path: string, data?: {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.post(path, data, config));
    }

    async put(path: string, data?: {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.put(path, data, config));
    }

    async delete(path: string, params: Params = {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.delete(path, { ...config, params }));
    }

    generateQueryByArray(queryName: string, arr: any[]) {
        return arr.map((item, index) => `${queryName}[${index}]=${item}`).join('&');
    }

    private async errorIdentifier(cb: () => Promise<AxiosResponse>, retry = 0): Promise<AxiosResponse> {
        try {
            return await cb();
        } catch (error) {
            const axiosError = error as AxiosError;

            if (!axiosError.response) {
                throw axiosError;
            }

            const { status, data }: { status: number, data: any } = axiosError.response;
            let exception: ErrorException;

            switch (status) {
                case 400:
                    exception = new BadRequestException(data && data.message ? data.message : axiosError.message);
                    break;

                case 401:
                    exception = new UnauthorizedException(axiosError.message);
                    break;

                case 403:
                    exception = new ForbiddenException(axiosError.message);
                    break;

                case 412:
                    exception = new PreconditionRequestException(data && data.message ? data.message : axiosError.message);
                    break;

                case 500:
                    exception = new InternalServerErrorException(data && data.message ? data.message : axiosError.message);
                    break;

                case 502:
                    if (retry < this.retryLimit) {
                        return this.errorIdentifier(cb, retry + 1);
                    }
                    exception = new BadGatewayException(data && data.message ? data.message : axiosError.message);
                    break;

                case 504:
                    exception = new GatewayTimeoutException(axiosError.message);
                    break;

                default:
                    exception = axiosError;
                    break;
            }

            const handler = container.get<ExceptionHandler>(KEY.ExceptionHandler);
            handler.catch(exception);

            return {} as AxiosResponse;
        }
    }
}
