import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';

export const CarsAlertsApiServiceS = Symbol.for('CarsAlertsApiServiceS');
@injectable()
export default class CarsAlertsApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;
    private url = 'cr-insight';

    async getCarAlerts(): Promise<CarAlertModel[] | null> {
        const res = await this.apiService.get(`${this.url}/get-alert-message-by-user`);

        if (!res || !res.data || !res.data) {
            return null;
        }

        const alertsDocument = plainToClass(CarAlertModel, <CarAlertModel[]> res.data, { excludeExtraneousValues: true });

        return alertsDocument;
    }

    async markAlertAsRead(id: string) {
        const { data } = await this.apiService.post(`${this.url}/mark-as-read`, { ids: [String(id)] });

        return data;
    }

    async markAlertsAsRead(ids: string[]) {
        const { data } = await this.apiService.post(`${this.url}/mark-as-read`, { ids: ids.map(String) });

        return data;
    }
}
