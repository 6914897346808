import { container } from '@/inversify.container';
import { Route } from 'vue-router';
import use from '../../use';

import HotelGuard, { HotelGuardS } from '../../guards/hotel.guard';

import { CommonRoutes } from '../common.routes';
import RatesHotelLevelRoutes from './rates.routes';
import MarketsHotelLevelRoutes from './markets.routes';
import RankingHotelLevelRoutes, { RankingHistory } from './ranking.routes';
import DeepAnalysisHotelLevelRoutes from './deep-analysis.routes';
import DiLiteHotelLevelRoutes from './di-lite.routes';
import PromotionsHotelLevelRoutes from './promotions.routes';
import EventsManagerRoutes from '../event-manager.routes';
import InsightRoutes from './insights.routes';
import RVSHotelLevelRoutes from './rvs.routes';
import SettingsPageRoutes from '../settings.routes';

export default function HotelBranch() {
    return {
        path: 'hotel/:hotelId',
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (route: Route) => ({
            hotelId: +route.params.hotelId,
        }),
        beforeEnter: use([
            container.get<HotelGuard>(HotelGuardS),
        ]),
        children: [
            {
                name: 'hotel.home',
                path: '',
                component: () => import('@/modules/home/pages/home.page.vue'),
                children: [
                    ...CommonRoutes('hotel.home'),
                    RankingHistory('hotel.home'),
                ],
            },
            RatesHotelLevelRoutes('rates', 'hotel.rates'),
            MarketsHotelLevelRoutes('markets', 'hotel.markets'),
            RankingHotelLevelRoutes('ranking', 'hotel.ranking'),
            DeepAnalysisHotelLevelRoutes('compset-benchmark', 'hotel.compset-benchmark'),
            DiLiteHotelLevelRoutes('di-lite', 'hotel.di-lite'),
            PromotionsHotelLevelRoutes('promotions', 'hotel.promotions'),
            EventsManagerRoutes('events-manager', 'hotel.events-manager'),
            InsightRoutes('hotel'),
            RVSHotelLevelRoutes('rvs', 'hotel.rvs'),
            SettingsPageRoutes('hotel'),
        ],
    };
}
