import {
    Module, VuexModule,
} from 'vuex-module-decorators';

import LoadingModel from '@/modules/common/models/loading.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';
import type RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import type RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';

import { RateTrendsStore, TrendsDocument } from '../types';

@Module
export default class HotelAnalysisTrendsStore extends VuexModule implements RateTrendsStore {
    ratesDocument: RatesDocumentModel | RatesDocumentAllModel | null = null;
    trendsDocument: TrendsDocument | null = null;
    trendsLoading: LoadingModel = new LoadingModel();
    date: Date | null = null;
    trendIndex: number = 0;
    localPriceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
}
