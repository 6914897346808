import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { plainToClass } from 'class-transformer';
import type ApiService from '@/modules/common/services/api.service';
import type Day from '@/modules/common/types/day.type';
import MarketsHistoryModel from './models/markets-history.model';

export const MarketsHistoryApiServiceS = Symbol.for('MarketsHistoryApiServiceS');
@injectable()
export default class MarketsHistoryApiService {
    @inject(KEY.ApiService) private apiService!: ApiService;

    async getMarketsHistoryByDay(day: Day, documentId: number) {
        const { data } = await this.apiService.get(`/market/single-date-trend/${day}/${documentId}`);

        if (!data) {
            return null;
        }

        const historyDocument = plainToClass(MarketsHistoryModel, <MarketsHistoryModel> data, { excludeExtraneousValues: true });

        return historyDocument;
    }
}
