import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import type HelperService from '@/modules/common/services/helper.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type StoreFacade from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import PAGES from '../common/constants/pages.constant';
import ClusterInsightsModel from './models/cluster-insights.model';
import type InsightFiltersService from '../document-filters/insight-filters.service';

export const ClusterInsightsServiceS = Symbol.for('ClusterInsightsServiceS');
@injectable()
export default class ClusterInsightsService implements Stateable {
    @inject(KEY.StoreFacade) storeFacade!: StoreFacade;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.provider,
            this.insightFiltersService.settings,

        ], (n, o) => {
            if (JSON.stringify(o) === JSON.stringify(n)) return;
            this.clusterService.resetLoading();
        });
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.INSIGHTS),
        );
        return this.storeState.clusterHotels as ClusterInsightsModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.INSIGHTS),
        );
        return this.storeState.totalCount as number;
    }
}
