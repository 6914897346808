import { Route } from 'vue-router';
import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import type { IGuard } from '@/router/interfaces/guard.interface';
import type UserService from '@/modules/user/user.service';

@injectable()
export default class AdminGuard implements IGuard {
    @inject(KEY.UserService) private userService!: UserService;

    async canActivate(to: Route) {
        if (!this.userService.isUserLoaded) {
            await this.userService.loading.whenLoadingFinished();
        }

        return this.userService.isAdmin;
    }
}
