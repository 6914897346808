import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import FEATURES from '@/modules/common/constants/features.constant';
import type UserService from '@/modules/user/user.service';

export const UserNavigationServiceS = Symbol.for('UserNavigationServiceS');
@injectable()
export default class UserNavigationService {
    @inject(KEY.UserService) private userService!: UserService;

    get routes() {
        const { currentHotelId, viewAs } = this.userService;

        let startRoute = String(viewAs);

        if (viewAs === 'hotel') {
            startRoute += `/${currentHotelId}`;
        }

        return {
            [FEATURES.HOME]: `/${startRoute}`,
            [FEATURES.RATE]: `/${startRoute}/rates`,
            [FEATURES.MARKET]: `/${startRoute}/markets`,
            [FEATURES.GUEST_REVIEW]: `/${startRoute}/ranking`,
            [FEATURES.EVENTS]: `/${startRoute}/events-manager`,
            [FEATURES.DEEP_COMPSET]: `/${startRoute}/compset-benchmark`,
            [FEATURES.LITE_DI]: `/${startRoute}/di-lite`,
            [FEATURES.PROMOTION_DETECTION]: `/${startRoute}/promotions`,
            [FEATURES.RVS]: `/${startRoute}/rvs`,
            [FEATURES.INSIGHTS]: `/${startRoute}/insights`,
        };
    }

    get firstEnabledRoute() {
        const { enabledFeatures, isViewAsCluster } = this.userService;
        const { isViewAsChain } = this.userService;
        const isNotHotelLevel = isViewAsChain || isViewAsCluster;

        if (enabledFeatures) {
            const firstEnabledFeature = Object
                .keys(this.routes)
                .reduce((acc: string[], item: string) => {
                    // NOTE: Home page disabled for chain and cluster
                    //       levels due bug in there.
                    if (item === FEATURES.HOME && isNotHotelLevel) {
                        return acc;
                    }

                    return [...acc, item];
                }, [])
                .find(key => enabledFeatures[key] === true);

            return firstEnabledFeature
                ? this.routes[firstEnabledFeature as FEATURES]
                : '';
        }
        return '';
    }

    get urlChainClusterParam() {
        // NOTE: Here will chain level soon
        return 'cluster';
    }

    checkIsRouteAllowed(route: string) {
        const { enabledFeatures, isViewAsChain, isViewAsCluster } = this.userService;

        if (this.userService.isCarUser) return true;

        if (enabledFeatures) {
            const routeFeature = Object
                .keys(this.routes)
                .find(feature => {
                    if (feature === FEATURES.HOME) {
                        return route.endsWith(this.routes[feature as FEATURES]);
                    }

                    if (isViewAsChain || isViewAsCluster) {
                        return route.includes(this.routes[feature as FEATURES].replace('/1', ''));
                    }

                    return route.includes(this.routes[feature as FEATURES]);
                });

            if (!routeFeature) return false;

            return enabledFeatures[routeFeature as FEATURES];
        }

        return false;
    }
}
