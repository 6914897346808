import { inject, injectable } from '@/inversify';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type HelperService from '@/modules/common/services/helper.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';

import ChainRatesStore from './chain-rates.store';
import ChainService, { ChainServiceS } from '../../chain.service';

export const ChainRatesServiceS = Symbol.for('ChainRatesServiceS');

@injectable()
export default class ChainRatesService {
    @inject(RatesApiServiceS)
    private ratesApiService!: RatesApiService;

    @inject(KEY.HelperService)
    private helperService!: HelperService;

    @inject(KEY.StoreFacade)
    private storeFacade!: StoreFacade;

    @inject(ChainServiceS)
    private chainService!: ChainService;

    @inject(KEY.DocumentFiltersService)
    private documentFilterService!: DocumentFiltersService;

    private readonly storeState: ChainRatesStore = this.storeFacade.getState('ChainRatesStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.chainService.settings.groupBy,
                this.chainService.settings.provider,
                this.documentFilterService.settings.year,
                this.documentFilterService.settings.month,
            ],

            this.resetLoading.bind(this),
        );
    }

    get settings() {
        return this.chainService.settings;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.clusterList;
    }

    get group() {
        return this.settings.groupBy;
    }

    get isLoading() {
        return false
            || !this.data
            || this.storeState.loading.isLoading();
    }

    private async loadData() {
        const settings = {
            ...this.settings,
            ...this.documentFilterService.settings,
        };

        this.storeState.clusterList = await this.ratesApiService.getClusterList(settings);

        return true;
    }

    private resetLoading() {
        this.storeState.loading.reset();
    }
}
