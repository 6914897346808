export const settingsDisabledEmails = [
    'dos@crowneplazabeihai.com',
    'dos@crowneplazabeihai.com',
    'rsvn@holidayinnbaoji.com',
    'elora.gu@ihg.com',
    'holly.yu@ihg.com',
    'revenue@cphizz.com',
    'revenue@cphizz.com',
    'jingwei.zhang@ihg.com',
    'reservation@holidayinnht.com',
    'eunice.zhu@vocojincheng.com',
    'holden.tian@ihg.com',
    'apple.qu2@ihg.com',
    'shadow.hu@ihg.com',
    'athena.li@ihg.com',
    'xiaorong.wang@ihg.com',
    'apple.qu2@ihg.com',
    'ting.feng1@ihg.com',
    'michelle.chen2@ihg.com',
    '262897446@qq.com',
    'longxinwang@sunwalk.cn',
    'sissy.xu@ihg.com',
    'cherry.bi@ihg.com',
    'yoyo.zhai@ihg.com',
    'qianyao.li@ihg.com',
    'kira.zheng@ihg.com',
    'sara.yu@ihg.com',
    'qianyao.li@ihg.com',
    '1160618904@qq.com',
    'sara.yu@ihg.com',
    '614658103@qq.com',
    'tom19940513@163.com',
    'min.zhang@ihg.com',
    'cissy.chen1@ihg.com',
    'zhang.katherine@ihg.com',
    'evelyn.li1@ihg.com',
    'summer.qin@ihg.com',
    'cindy.wu5@ihg.com',
    'teng.jane@ihg.com',
    'susan.xu1@ihg.com',
    'lucy.liu4@ihg.com',
    'mike.liu@ihg.com',
    'lyne.lin1@ihg.com',
    'tracy.tan@gz63.cn',
    'jessie.yan@cphohhot.com',
    'anny.chen@thesandalwood.com.cn',
    'jerry.li4@ihg.com',
    'fiona.wang2@ihg.com',
    'lisa.ma@ihg.com',
    'ethan.liu@ihg.com',
    'lillian.wang4@ihg.com',
    'candy.liu4@ihg.com',
    'jeanne.tang@ihg.com',
    'sunny.sun@icqdh.com',
    'blair.liu1@ihg.com',
    'jiangjoyy@ihg.onmicrosoft.com',
    'judy.wang6@ihg.com',
    'blair.liu1@ihg.com',
    'izzy.jin1@ihg.com',
    'eva.wang3@ihg.com',
    '342395447@qq.com',
    'christy.liu1@ihg.com',
    'carrie.sha@icbanna.com',
    'carina.zheng@ihg.com',
    'alison.xia@ihg.com',
    'vivian.zheng1@ihg.com',
    'lily.zuo@ihg-ccecg.com',
    'koksoon.wong@ihg.com',
    '284956239@qq.com',
    'lydia.yang@ihg.com',
    'bella.fang@icxiangyang.com',
    'wendy.wu5@ihg.com',
    'wendy.wu5@ihg.com',
    'carrie.shen3@ihg.com',
    'oliver.liu@cpkunshan.com',
    'rena.ding@hihiexguiyang.com',
    'rena.ding@hihiexguiyang.com',
    'wendy.li2@ihg.com',
    'wendy.li2@ihg.com',
    'serena.qin@ihg.com',
    'serena.qin@ihg.com',
    'isabel.yang@indigolugulake.com',
    'sunny.sun6@ihg.com',
    'jessie.zheng@iclhasa.com',
    'peter.pan@lyinnexpress.com',
    'stella.zhang@himudanjiang.com',
    '13943434703@163.com',
    'cara.ai@ihg.com',
    'penny.zhang@ihg.com',
    'lily.xiang@ihg.com',
    'janus@dzsjgroup.com',
    'ivy.guo@holidayinnjn.com',
    'fiona.dai1@ihg.com',
    'ivy.guo@holidayinnjn.com',
    'echo.chen@voconanjing.com',
    'elora.gu@ihg.com',
    'teme.jing@ihg.com',
    'lisa.qiu@holidayinnxwh.com',
    'rebecca.liu@ihg.com',
    'rebecca.liu@ihg.com',
    'jodie.zheng@holidayinnnanjing.com',
    'betty.li@cpnanning.com',
    'judy.zhu@holidayinn-ny.com',
    'yuki.qin@ihg.com',
    'cynthia.cong@cplido.com',
    'xujingjing@newchaoyang.com',
    'xujingjing@newchaoyang.com',
    'amy.mao@ihg.com',
    'lucy.hsieh@ihg.com',
    'yeoman.zhang@ihg.com',
    'candy.ma@cpnanxinya.com',
    'maggen.jiang@ihg.com',
    'elaine.li@hualuxekunshan.com',
    'linda.ymz@ihg.com',
    'fiona.qiao@holidayinnnjr.cn',
    'davy.zhou@ihg.com',
    'yeoman.zhang@ihg.com',
    'nana.tang@ihg.com',
    'tracy.fu@cp-anting.com',
    'karen.gong@holidayinnsongjiang.com',
    'anita.miao1@ihg.com',
    'beverly.xue@ihg.com',
    'barrybai@shgpn.com',
    'karen.gong@holidayinnsongjiang.com',
    'brenda.peng@ihg.com',
    'ivy.pu@ihg.com',
    'shirley.jiang@ihg.com',
    'wendy.huang@ihg.com',
    'yoyo.geng@hiequjiang.com',
    'dandan_chen@ruichengroup.com',
    'brenda.peng@ihg.com',
    'lindaliang.xia@ihg.com',
    'wendy.li@icshijiazhuang.com',
    'jessiema@cpsuz.com',
    'carrie.liu@holidayinntaihu.com',
    'carrie.liu@holidayinntaihu.com',
    'weiwei.liu@ihg.com',
    '782828053@qq.com',
    'maggie.han2@ihg.com',
    'maggie.han2@ihg.com',
    'nancy.xu@cpsuzhoudongtaihu.com',
    'nancy.xu@cpsuzhoudongtaihu.com',
    'susan.chen1@ihg.com',
    '1092006025@qq.com',
    'joyce.kong1@ihg.com',
    'michelle.zhou@ihg.com',
    'alin.wu@holidayinnshenzhennanshan.com',
    'vicky.zhang@ihg.com',
    'sasa.li1@ihg.com',
    'joe.jiao@cphaiquanwan.com',
    'kai.zhou1@ihg.com',
    'kai.zhou1@ihg.com',
    'vitino.lv@ihg.com',
    'carina.zheng@ihg.com',
    'jane.zhao@ihg.com',
    'revm@cpjinan.com',
    'peter.huang@ihg.com',
    'cathy.zhao@cptjbh.com',
    'stella.zhang2@ihg.com',
    'joan.wangyang@ihg.com',
    'nancy.xu@cptjmjn.com',
    'tracy.wu1@ihg.com',
    'amy.ren@tywzjr.com',
    '13605422273@163.com',
    'jennifer.gao1@ihg.com',
    'erin.li@cpwhov.com',
    'vivi.gu@ihg.com',
    'vivi.gu@ihg.com',
    'revenue@vocowuhan.com',
    'snow.bai@cpwxcc.com',
    'jack.wang8@ihg.com',
    'niki.hua@ihg.com',
    'jack.wang8@ihg.com',
    'teddy.xiong@hualuxewuxi.com',
    'lyn.yin@ihg.com',
    'celine.wu@ihg.com',
    'reservations@aahmco.cn',
    'jane.fu@ihg.com',
    'helen.luo@icxiamen.com',
    'antony.zhang@ihg.com',
    'helen.liao1@ihg.com',
    'helen.liao1@ihg.com',
    'gm@hiexyc.com',
    'yimin.wang@ihg.com',
    'andrea.zhang@crowneplazayantai.com',
    'janice.he@ihg.com',
    'yan.li1@ihg.com',
    'isabella.li4@ihg.com',
    'alva.jin@crowneplazazj.com',
    'cynthia.wu@ihg.com',
    'sam.sun5@ihg.com',
    'sam.sun5@ihg.com',
    'katriya.li@ihg.com',
    'heather.bai1@ihg.com',
    'heather.bai1@ihg.com',
    'kelly.jia@ihg.com',
    'william.luo3@ihg.com',
    'wangziyu96@126.com',
    'vivian.feng3@ihg.com',
    'catherine.zhang5@ihg.com',
    'kelvin.liu4@ihg.com',
    'yanshu.zhai@ihg.com',
    'yanshu.zhai@ihg.com',
    'jingwei.zhang@ihg.com',
    '1824160722@qq.com',
    '1771291461@qq.com',
    'linda.sun@ihg.com',
    '2753605202@qq.com',
    'samuel.lee@ihg.com',
    'jeffrey.huang1@ihg.com',
    '632834168@qq.com',
    'xin.zhang2@ihg.com',
    'rosally@yeah.net',
    'yoyo.ma@hojocsairport.com',
    'zhaohaijuan@sunwalk.cn',
    'sabrina.he1@ihg.com',
    'roy.wang@ihg.com',
    'vicky.diao@cpctuch.com',
    'jessie.liu@ihg.com',
    'max.zhao1@ihg.com',
    'stone.cao@ihg.com',
    'flora.xie@ic-sanchalake.com',
    'li.xia@ihg.com',
    'roger.gao@cptianfu.com',
    'tom19940513@163.com',
    'cris.liu@ihg.com',
    'coco.xu1@ihg.com',
    'sierra.jiang@hiqdcc.com',
    'steven.cui1@ihg.com',
    'sharon.wang3@ihg.com',
    'mike.zhang3@ihg.com',
    'julia.wang3@ihg.com',
    'jack.wang@ihg.com',
    '1318102545@qq.com',
    'yvonne.tong@ihg.com',
    'laura.feng@ihg.com',
    'elena.pang@cphohhot.com',
    'alex.liu@thesandalwood.cn',
    'ichefei.res@intercontinentalhefei.com',
    'sammi.du@ihg.com',
    'sarah.feng@ihg.com',
    'brian.zheng@ihg.com',
    '598990629@qq.com',
    'joyce.qiu@crowneplazahzaux.com',
    'eric.kee@ihg.com',
    '1063639077@qq.com',
    'blair.liu1@ihg.com',
    '243677485@qq.com',
    'susanzheng.xu@ihg.com',
    '645685034@qq.com',
    'eva.wang@cphuizhou.com',
    'ricky.tan@crowneplazataizhou.com',
    'irwen.wang1@ihg.com',
    'carlson.li1@ihg.com',
    '973590124@qq.com',
    'rsvn@icquanzhou.com',
    'von.go@ihg.com',
    '240186355@qq.com',
    'robbert.manussen@ihg.com',
    'jason.hu3@ihg.com',
    'reise.li@ihg.com',
    '2296535445@qq.com',
    'lucy.yan@ihg.com',
    'ken.yi1@ihg.com',
    'carrie.shen3@ihg.com',
    'sarah.ding@ihg.com',
    'jessiejiang1210@163.com',
    'jessiejiang1210@163.com',
    'jinming.zhang1@ihg.com',
    'alvin.zhu@ihg.com',
    'sunday.wang@ihg.com',
    'sunday.wang@ihg.com',
    'richie.yang@ihg.com',
    'jennifer.shi2@ihg.com',
    'totti.zhao@ihg.com',
    'niky.yu@lyinnexpress.com',
    'eva.li@himudanjiang.com',
    'david.zhang3@ihg.com',
    'steve.ding@ihg.com',
    'carnival.xu@intercontinentalningbo.com',
    'david.zhu3@ihg.com',
    'ally@dzsjgroup.com',
    'wendy.xu2@ihg.com',
    'res@holidayinnxg.com',
    'wendy.xu2@ihg.com',
    '320710018@qq.com',
    'lisa.qiu@holidayinnxwh.com',
    'connie.li1@ihg.com',
    'rebecca.liu@ihg.com',
    'wilson.zhou2@ihg.com',
    'jeffery.xu@cpnanning.com',
    'duan.vane@ihg.com',
    'allen.li2@ihg.com',
    'fo@cplido.com',
    'grace.lan3@ihg.com',
    'grace.lan3@ihg.com',
    'amy.li@cpsunpalace.cn',
    'eric.swanson@ihg.com',
    '1023454729@qq.com',
    'tony.geerts@ihg.com',
    'douglas.yue@ihg.com',
    '297180710@qq.com',
    'evali@holidayinnnjr.cn',
    'suki.yu1@ihg.com',
    '1023454729@qq.com',
    'ivan.tan4@ihg.com',
    'cathy.yang2@ihg.com',
    'carol.xie1@ihg.com',
    'anita.miao1@ihg.com',
    'jessie.yang@crowneplaza-jinxiu.com',
    'carol.xie1@ihg.com',
    '1332389813@qq.com',
    'rsvn@holidayinnxian.com',
    'jerry.cao3@ihg.com',
    'jessie.zheng@icshaanxi.com',
    'jerry.wu1@ihg.com',
    'dandan_chen@ruichengroup.com',
    '1332389813@qq.com',
    'leslie.cao@ihg.com',
    '354252342@qq.com',
    '1067874654@qq.com',
    'jenny.zhao@holidayinntaihu.com',
    'ethan.kuai@ihg.com',
    'victor.wang@ihg.com',
    'lance.zhang@hi-sanyabay.com',
    'lily.zhang6@ihg.com',
    'hansen.yang@cpsuzhoudongtaihu.com',
    'hansen.yang@cpsuzhoudongtaihu.com',
    'david.zhouqing@ihg.com',
    'li.xiaofang@hualuxesuzhou.com',
    'michelle.zhou@ihg.com',
    'johnson.liu6@ihg.com',
    'tim.hooton@ihg.com',
    'elain.wu@hotelindigoshenzhen.com',
    'michelle.zhang4@ihg.com',
    '524493642@qq.com',
    'kai.zhou1@ihg.com',
    'tao.shi@ihg.com',
    'reservations@qteven.com',
    'jack.cui2@ihg.com',
    'sandy.dong1@ihg.com',
    'peter.huang@ihg.com',
    'ley.li@cptjbh.com',
    'ted.chen1@ihg.com',
    'lisa.yang4@ihg.com',
    'echo.chen@cptjmjn.com',
    '1625858848@qq.com',
    'zhaojieying@tywzjr.com',
    '13605422273@163.com',
    '1417397833@qq.com',
    'jimson.hao@ihg.com',
    'fuat.koroglu@ihg.com',
    'fuat.koroglu@ihg.com',
    'gm@vocowuhan.com',
    'adrien.zhu1@ihg.com',
    'jack.wang8@ihg.com',
    'reservation@intercontinentalwuxi.com',
    'jack.wang8@ihg.com',
    'eva.li2@ihg.com',
    'kelvin.zhou@ihg.com',
    'zoe.zou1@ihg.com',
    'yumi0020@163.com',
    'reservations@crowneplazaxm.com',
    'troy.fu1@ihg.com',
    'beck.chen@ihg.com',
    '1258019778@qq.com',
    'gm@hiexyc.com',
    'gm@hiex-horgos.com',
    'reservation@crowneplazayantai.com',
    'david.feng2@ihg.com',
    'ellengao.icfs@polyhotels.com',
    'sales@hi-shunde.com',
    'rsvn@crowneplazazj.com',
    'iriny.chen@crowneplazazhangzhou.com',
    'john.wang4@ihg.com',
    'john.wang4@ihg.com',
    'qi.li1@ihg.com',
    'jin.ma@ihg.com',
    'jimmy.wang4@ihg.com',
];
