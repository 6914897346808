export enum CARS_METHODS {
    getCars = 'getCars',
    getFleetAvailability = 'getFleetAvailability',
}

export enum COMPSETS_METHODS {
    getCompsetsReq = 'getCompsetsReq',
}

export enum HOTELS_METHODS {
    getHotelNames = 'getHotelNames',
}

export enum EVENTS_METHODS {
    getEventsByMonth = 'getEventsByMonth',
    loadChainEvents = 'loadChainEvents',
    loadMonthEvents = 'loadMonthEvents',
    getHolidayEventsByMonth = 'getHolidayEventsByMonth',
    getMyEventsByMonth = 'getMyEventsByMonth',
    getChainEventsByMonth = 'getChainEventsByMonth',
    populateMonthEventsByDays = 'populateMonthEventsByDays',
    hasDateHolidayEvents = 'hasDateHolidayEvents',
    hasDateOtherEvents = 'hasDateOtherEvents',
    loadHotelEvent = 'loadHotelEvent',
}

export enum ALERT_METHODS {
    getAlerts = 'getAlerts',
}

export enum MODULES {
    CARS,
    COMPSETS,
    HOTELS,
    EVENTS,
    ALERTS,
}

export const MODULE_METHODS = {
    [MODULES.CARS]: CARS_METHODS,
    [MODULES.COMPSETS]: COMPSETS_METHODS,
    [MODULES.HOTELS]: HOTELS_METHODS,
    [MODULES.EVENTS]: EVENTS_METHODS,
    [MODULES.ALERTS]: ALERT_METHODS,
};
