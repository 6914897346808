var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
        'custom-tooltip-directive': true,
        ['custom-tooltip-directive--' + _vm.position]: true,
        'custom-tooltip-directive--visible': _vm.visible,
    },style:({
        left: `${_vm.left}px`,
        top: `${_vm.top}px`,
    }),attrs:{"id":"custom-tooltip-directive"}},[_c('div',{ref:"tooltip",staticClass:"tooltip"},[(_vm.header)?_c('header',{attrs:{"id":"custom-tooltip-directive-header"}},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),_c('section',{attrs:{"id":"custom-tooltip-directive-body"}},[_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.component)?[_c(_vm.component,{tag:"component"})]:_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }