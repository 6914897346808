import { SettingsModel } from '@/modules/settings/models/settings.model';
import { Expose, Transform, Type } from 'class-transformer';
import { IsNumber, IsString, IsOptional } from 'class-validator';

import { settingsDisabledEmails } from '../constants';
import USER_LEVELS from '../constants/user-levels.constant';
import USER_ROLES from '../constants/user-roles.constant';

export default class UserModel {
    @Expose()
    @Transform((_, plain) => plain.fornova_user_id)
    @IsString()
    id!: string;

    @Expose()
    @IsString()
    firstName!: string;

    @Expose()
    @IsString()
    lastName!: string;

    @Expose()
    @IsString()
    email!: string;

    @Expose()
    @IsString()
    chainName!: string;

    @Expose()
    @IsString()
    chainId!: string;

    @Expose()
    @IsString()
    @IsOptional()
    chainNumber?: string;

    @Expose()
    @IsString()
    // [TODO] remove transform as chain level will be activated
    @Transform((_, plain) => (plain.level === 'chain' ? 'cluster' : plain.level))
    level!: USER_LEVELS;

    @Expose()
    @IsString()
    @Transform((_, plain) => {
        if (plain.level === USER_LEVELS.ONBOARDING || plain.level === USER_LEVELS.CAR) {
            return plain.level;
        }

        const { pathname } = window.location;
        switch (true) {
            case /chain/.test(pathname):
                return USER_LEVELS.CHAIN;
            case /cluster/.test(pathname):
                return USER_LEVELS.CLUSTER;
            case /hotel/.test(pathname):
                return USER_LEVELS.HOTEL;
            default:
                // [TODO] replace with plain.level as chain level will be activated
                return plain.level === 'chain' ? 'cluster' : plain.level;
        }
    })
    viewAs!: USER_LEVELS;

    @Expose()
    @IsString()
    @Transform((_, plain) => plain.levelName)
    levelName!: string;

    @Expose()
    @Transform((_, plain) => (plain.fornovaIds as number[]).map(hotelId => Number(hotelId)))
    @IsNumber({}, { each: true })
    hotelIds!: number[];

    @Expose()
    @Transform(() => null)
    currentHotelId!: number | null;

    @Expose()
    @IsString()
    token!: string;

    @Expose()
    @IsString()
    @IsOptional()
    role?: USER_ROLES;

    @Expose()
    isTestUser?: boolean;

    @Expose({ name: 'settings' })
    @Type(() => SettingsModel)
    settings!: SettingsModel;

    @Expose()
    @Transform((_, plain) => {
        const enabledFeatures: {
            [name: string]: boolean
        } = {};

        if (plain.enabled_features) {
            Object.keys(plain.enabled_features).forEach(feature => {
                enabledFeatures[feature] = plain.enabled_features[feature];
            });
        }

        return enabledFeatures;
    })
    enabledFeatures!: {
        [name: string]: boolean
    };

    @Expose()
    @Transform((_, plain) => plain.countries)
    countries: string[] = [];

    @Expose()
    @Transform((_, plain) => ((plain.locations || []) as { [key: string]: string}[]).map(item => item.fn_location_id))
    locations!: string[];

    @Expose()
    @Transform((_, plain) => !!plain.userOptions && !!plain.userOptions.useWalkme)
    isWalkmeEnabled!: boolean;

    @Expose()
    @Transform((_, plain) => (
        plain.applications ? {
            CI: !!plain.applications.FornovaCI,
            DI: !!plain.applications.FornovaDI,
        } : {
            CI: false,
            DI: false,
        }
    ))
    applications!: {
        CI: boolean,
        DI: boolean,
    };

    @Expose()
    @Transform((_, plain) => plain.demo_id || null)
    demoId!: string | null;

    @Expose()
    @Transform((_, plain) => !!plain.is_superadmin_token)
    isSuperadmin!: boolean;

    @Expose()
    @Transform((_, plain) => settingsDisabledEmails.includes(plain.email)) // plain.userOptions?.readOnly || false)
    isReadonly!: boolean;

    @Expose()
    @Type(() => Date)
    lastLogin!: Date;

    @Expose()
    @Transform((_, plain) => !!plain.is_first_login)
    isFirstLogin!: boolean;
}
